import { Flex, Icon, Text } from "@chakra-ui/react";
import { RiPlayFill } from "react-icons/ri";

type Props = {
  onClick: () => void;
};

export function ButtonReply({ onClick }: Props) {
  return (
    <Flex
      style={{
        height: "49px",
        cursor: "pointer",
        backgroundColor: "#0577BE",
        borderRadius: 10,
        alignItems: "center",
        justifyContent: "center",
      }}
      onClick={onClick}
      width={["100%", "100%", "134px"]}
    >
      <Flex>
        <Text
          style={{
            color: "#fff",
            fontSize: 16,
            fontFamily: "Kanit",
            fontWeight: 500,
          }}
        >
          Responder
        </Text>
      </Flex>
      <Flex style={{ marginTop: 2 }}>
        <Icon as={RiPlayFill} color="#fff" />
      </Flex>
    </Flex>
  );
}
