import { Flex, Icon, Text } from "@chakra-ui/react";
import { useEffect, useState } from "react";
import { RiLightbulbFlashFill } from "react-icons/ri";
import { ModalTip } from "./modalTip";
import { AnswerTips } from "../../../../dtos/simulatorQuestionDTO";

type Props = {
  onClick: () => void;
  answerTips: AnswerTips[];
};

export function ButtonTip({ onClick, answerTips }: Props) {
  const [tipsData, setTipsData] = useState<AnswerTips[]>([]);
  const [isOpenModal, setIsOpenModal] = useState(false);
  const [info, setInfo] = useState("");
  const [title, setTitle] = useState("");

  function getTip(isOpenModal: boolean) {
    const tip = tipsData.find((item) => item.is_open === false);
    if (tip) {
      tip.is_open = true;
      setInfo(tip.title);
      setTitle(`${tip.position}/${tipsData.length}`);
      if (isOpenModal) {
        setIsOpenModal(true);
      }
      const tips = tipsData.map((tp) => (tp.id !== tip.id ? tp : tip));
      setTipsData(tips);
    } else {
      alert("Você já usou todas as dicas");
    }
  }

  useEffect(() => {
    const tips = answerTips
      .map((item) => {
        return {
          ...item,
          is_open: false,
        };
      })
      .sort((a, b) => a.position - b.position);

    setTipsData(tips);
  }, [answerTips]);

  return (
    <>
      <ModalTip
        info={info}
        title={title}
        is_open={isOpenModal}
        onClose={() => {
          setIsOpenModal(false);
        }}
        onCloseSend={() => {
          setIsOpenModal(false);
        }}
        onNexPage={() => {
          getTip(false);
        }}
      />

      <Flex
        style={{
          height: "49px",
          cursor: "pointer",
          backgroundColor: "#03A679",
          borderRadius: 10,
          alignItems: "center",
          justifyContent: "center",
        }}
        width={["100%", "100%", "151px"]}
        onClick={() => {
          onClick();
          getTip(true);
        }}
      >
        <Flex>
          <Text
            style={{
              color: "#fff",
              fontSize: 16,
              fontFamily: "Kanit",
              fontWeight: 500,
            }}
          >
            Dicas
          </Text>
        </Flex>
        <Flex ml={3} style={{ gap: 1 }}>
          {tipsData.map((item, index) => (
            <Icon
              key={index}
              as={RiLightbulbFlashFill}
              color={item.is_open ? "#004733" : "#FFE500"}
            />
          ))}
        </Flex>
      </Flex>
    </>
  );
}
