import { Navigate, Route, Routes } from "react-router-dom";
import { RecoverPassword } from "../pages/auth/recoverPassword";
import { Login } from "../pages/auth/login";

import { AuthRoute } from "./auth-route";

import { UpdatePassword } from "../pages/auth/updatePassword";
import { RegisterAccount } from "../pages/auth/registerAccount";
import { RegisterAccountSuccess } from "../pages/auth/registerAccount/success";

import { NotFound } from "../pages/extra/notFound";
import { Home } from "../pages/home";
import { RegistrationQuestions } from "../pages/questions/registration";
import { CreateManualQuestion } from "../pages/questions/forms/manualQuestion/create";
import { CreateManyQuestion } from "../pages/questions/forms/manyQuestion/create";
import { CreateSimulated } from "../pages/simulated";
import { Simulators } from "../pages/simulators";
import { SimulatorQuestion } from "../pages/simulators/questions";
import { DuplicationSimulator } from "../pages/duplicationSimulator";
import { CreateIaQuestion } from "../pages/questions/forms/iaQuestions/create";
import { ListQuestion } from "../pages/questions/list";
import { EditQuestion } from "../pages/questions/edit";
import { ListCategories } from "../pages/extra/categories/list";
import { CreateCategories } from "../pages/extra/categories/create";
import { ListRanking } from "../pages/extra/ranking";
import { Dash } from "../pages/extra/dash";
import { PaymentCallback } from "../pages/extra/payment/callback";

export function AppRouter() {
  return (
    <Routes>
      <Route path="/" element={<Navigate to="/home" replace />} />
      <Route path="/login" element={<Login />} />
      <Route path="/recuperacao-senha" element={<RecoverPassword />} />
      <Route path="/atualizar-senha/:email" element={<UpdatePassword />} />
      <Route path="/cadastro" element={<RegisterAccount />} />
      <Route path="/cadastro/sucesso" element={<RegisterAccountSuccess />} />
      <Route path="/pagamento" element={<PaymentCallback />} />
      <Route
        path="/home"
        element={
          <AuthRoute path="/home" title="Início">
            <Home />
          </AuthRoute>
        }
      />

      <Route
        path="/dash"
        element={
          <AuthRoute path="/dash" subPath="Dashboard" title="Início">
            <Dash />
          </AuthRoute>
        }
      />

      <Route
        path="/lista-de-questoes"
        element={
          <AuthRoute
            path="/lista-de-questoes"
            title="Questões"
            subTitle="Lista de questões"
          >
            <ListQuestion />
          </AuthRoute>
        }
      />

      <Route
        path="/questao/update/:id"
        element={
          <AuthRoute
            path="/lista-de-questoes"
            title="Questões"
            subTitle="Lista de questões"
            subPath="/lista-de-questoes"
          >
            <EditQuestion />
          </AuthRoute>
        }
      />

      <Route
        path="/registrar-questoes"
        element={
          <AuthRoute
            path="/home"
            subPath="/registrar-questoes"
            title="Início"
            subTitle="Cadastrar Questões"
          >
            <RegistrationQuestions />
          </AuthRoute>
        }
      />
      <Route
        path="/registrar-questoes/ia"
        element={
          <AuthRoute
            path="/home"
            subPath="/registrar-questoes"
            title="Início"
            subTitle="Cadastrar Questões"
          >
            <CreateIaQuestion />
          </AuthRoute>
        }
      />
      <Route
        path="/registrar-questoes/cadastro-manual"
        element={
          <AuthRoute
            path="/home"
            subPath="/registrar-questoes"
            title="Início"
            subTitle="Cadastrar Questões"
          >
            <CreateManualQuestion />
          </AuthRoute>
        }
      />

      <Route
        path="/registrar-questoes/cadastro-em-massa"
        element={
          <AuthRoute
            path="/home"
            subPath="/registrar-questoes"
            title="Início"
            subTitle="Cadastrar Questões"
          >
            <CreateManyQuestion />
          </AuthRoute>
        }
      />

      <Route
        path="/simulador/criar"
        element={
          <AuthRoute path="/home" title="Início">
            <CreateSimulated />
          </AuthRoute>
        }
      />

      <Route
        path="/simuladores"
        element={
          <AuthRoute path="/home" title="Início">
            <Simulators />
          </AuthRoute>
        }
      />

      <Route
        path="/simuladores/questao/:id"
        element={
          <AuthRoute path="/home" title="Início">
            <SimulatorQuestion />
          </AuthRoute>
        }
      />

      <Route
        path="/simulador/novo/:id"
        element={
          <AuthRoute path="/home" title="Início">
            <DuplicationSimulator />
          </AuthRoute>
        }
      />

      <Route
        path="/configuracoes"
        element={
          <AuthRoute path="/configuracoes" title="Configurações">
            <ListCategories />
          </AuthRoute>
        }
      />

      <Route
        path="/nova/configuracoes"
        element={
          <AuthRoute
            path="/configuracoes"
            subPath="configuracoes"
            subTitle="nova configuração"
            title="Início"
          >
            <CreateCategories />
          </AuthRoute>
        }
      />

      <Route
        path="/ranking"
        element={
          <AuthRoute path="/ranking" subPath="Ranking" title="Início">
            <ListRanking />
          </AuthRoute>
        }
      />

      <Route path="*" element={<NotFound />} />
    </Routes>
  );
}
