import { useEffect, useState } from "react";
import { api } from "../../../service/api";
import { SelectTypeDTO } from "../../../dtos/SelectTypeDTO";

export function useListDicipline() {
  const [loadingDicipline, setLoadingDicipline] = useState(false);
  const [error, setError] = useState<boolean | null>(null);
  const [dataListDicipline, setDataListDicipline] = useState<SelectTypeDTO[]>(
    []
  );

  const fetchData = async (diciplineSelected?: string) => {
    try {
      setLoadingDicipline(true);
      const path = `simulator/list/filter/discipline?disciplines=${diciplineSelected}`;
      const response = await api.get(path);

      const data: SelectTypeDTO[] = [];

      response.data.forEach((element: any) => {
        data.push({
          label: element.discipline,
          value: element.discipline,
        });
      });

      setDataListDicipline(data);
    } catch (error) {
      setError(true);
    } finally {
      setLoadingDicipline(false);
    }
  };

  useEffect(() => {
    fetchData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return {
    dataListDicipline,
    loadingDicipline,
    error,
    refetchDicipline: fetchData,
  };
}
