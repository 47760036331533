import {
  FormControl,
  FormLabel,
  FormErrorMessage,
  PinInput,
  PinInputField,
  HStack,
} from "@chakra-ui/react";
import { forwardRef, ForwardRefRenderFunction } from "react";
import { FieldError } from "react-hook-form";

interface Props {
  name: string;
  label?: string;
  onChange?: (value: any) => void;
  onComplete?: (value: any) => void;
  error?: FieldError;
}

const InputBase: ForwardRefRenderFunction<HTMLInputElement, Props> = (
  { name, label, error, onChange, onComplete },
  ref
) => {
  return (
    <FormControl isInvalid={!!error}>
      {label && (
        <FormLabel color="gray.50" htmlFor={name}>
          {label}
        </FormLabel>
      )}

      <HStack alignContent="space-between">
        <PinInput
          onChange={onChange}
          onComplete={onComplete}
          size="lg"
          focusBorderColor="gray.100"
          variant="filled"
          isInvalid={!!error}
        >
          <PinInputField
            name={name}
            borderColor="gray.400"
            color="white"
            _placeholder={{ color: "gray.50" }}
            borderWidth={1}
            bgColor="transparent"
            _hover={{
              bgColor: "transparent",
            }}
          />
          <PinInputField
            name={name}
            borderColor="gray.400"
            color="white"
            _placeholder={{ color: "gray.50" }}
            borderWidth={1}
            bgColor="transparent"
            _hover={{
              bgColor: "transparent",
            }}
          />
          <PinInputField
            name={name}
            borderColor="gray.400"
            color="white"
            _placeholder={{ color: "gray.50" }}
            borderWidth={1}
            bgColor="transparent"
            _hover={{
              bgColor: "transparent",
            }}
          />
          <PinInputField
            name={name}
            borderColor="gray.400"
            color="white"
            _placeholder={{ color: "gray.50" }}
            borderWidth={1}
            bgColor="transparent"
            _hover={{
              bgColor: "transparent",
            }}
          />
          <PinInputField
            name={name}
            borderColor="gray.400"
            color="white"
            _placeholder={{ color: "gray.50" }}
            borderWidth={1}
            bgColor="transparent"
            _hover={{
              bgColor: "transparent",
            }}
          />
          <PinInputField
            name={name}
            borderColor="gray.400"
            color="white"
            _placeholder={{ color: "gray.50" }}
            borderWidth={1}
            bgColor="transparent"
            _hover={{
              bgColor: "transparent",
            }}
          />
        </PinInput>
      </HStack>
      {!!error && <FormErrorMessage>{error.message}</FormErrorMessage>}
    </FormControl>
  );
};

export const InputPin = forwardRef(InputBase);
