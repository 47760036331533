import { useEffect, useState } from "react";
import { SimulatorsDTO } from "../../../dtos/SimulatorsDTO";
import { api } from "../../../service/api";

export function useListSimulator() {
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState<boolean | null>(null);
  const [dataList, setDataList] = useState<SimulatorsDTO[]>([]);

  const fetchData = async () => {
    try {
      setLoading(true);
      const response = await api.get("/simulators/list");
      setDataList(response.data);
    } catch (error) {
      setError(true);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return {
    dataList,
    loading,
    error,
    refetch: fetchData,
  };
}
