import { useEffect, useState } from "react";
import { api } from "../../../../service/api";
import { CategoriesQuestionDTO } from "../../../../dtos/CategoriesQuestionDTO";

export function useListAllCategories() {
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState<boolean | null>(null);
  const [dataList, setDataList] = useState<CategoriesQuestionDTO[]>([]);

  const fetchData = async () => {
    try {
      setLoading(true);
      const response = await api.get("category/question/list");
      setDataList(response.data);
    } catch (error) {
      setError(true);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return {
    dataList,
    loading,
    error,
    refetch: fetchData,
  };
}
