import { Flex, Icon, IconButton } from "@chakra-ui/react";
import { QuestionInfo } from "../questionInfo";
import { ButtonCancel } from "../buttonCancel";
import { useEffect, useState } from "react";
import { ButtonOptions } from "../buttonOptions";
import { RiStarLine } from "react-icons/ri";
import { api } from "../../../../service/api";

type Props = {
  id: string;
  timerQuestion: number;
  questionActual: string;
  questionCurrent: string;
  onCancel: () => void;
  onOpen: (type: "detail" | "performance") => void;
  progress: "not_started" | "started" | "finished";
  favorite: boolean;
  simulatorQuestionId: string;
  onUpdate: () => void;
};

export function HeaderQuestions({
  id,
  timerQuestion,
  questionActual,
  questionCurrent,
  onCancel,
  onOpen,
  progress,
  favorite,
  simulatorQuestionId,
  onUpdate,
}: Props) {
  const [timerOut, setTimerOut] = useState("00:00");

  const [favoriteValue, setFavoriteValue] = useState(
    favorite ? simulatorQuestionId : ""
  );

  function timerFullQuestion() {
    var duration: number = timerQuestion ?? 0;

    let timer: number = duration;
    let minutes;
    let seconds;

    setInterval(function () {
      minutes = parseInt((timer / 60).toString(), 10);
      seconds = parseInt((timer % 60).toString(), 10);
      minutes = minutes < 10 ? "0" + minutes : minutes;
      seconds = seconds < 10 ? "0" + seconds : seconds;
      let textContent = minutes + ":" + seconds;

      if (textContent !== "NaN:NaN") {
        setTimerOut(textContent);
      }
      if (--timer < 0) {
        timer = duration;
      }
    }, 1000);
  }

  async function favoriteSimulator(
    idValue: string,
    status: "active" | "disabled"
  ) {
    try {
      await api.post("simulator/favorite", {
        Simulator_question_id: idValue,
        favorite: status,
      });
    } catch (error) {
    } finally {
      onUpdate();
    }
  }

  useEffect(() => {
    timerFullQuestion();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [timerQuestion]);

  return (
    <Flex width={["100%", "100%", "auto"]}>
      <Flex
        style={{ gap: 20 }}
        width={["100%", "100%", "auto"]}
        flexDirection={["column", "column", "row"]}
      >
        <QuestionInfo title="Tempo nesta questão:" info={timerOut} />
        <QuestionInfo
          title="Questões respondidas:"
          info={`/${questionCurrent.toString()}`}
          actualInfo={`${questionActual.toString()}`}
        />
        <ButtonCancel onCancel={onCancel} />

        <IconButton
          colorScheme="teal"
          aria-label="RiStarLine"
          variant={favoriteValue === simulatorQuestionId ? "solid" : "outline"}
          height="44px"
          size="lg"
          icon={
            <Icon
              fontSize="16px"
              as={RiStarLine}
              color={
                favoriteValue === simulatorQuestionId ? "white" : "#319795"
              }
            />
          }
          onClick={() => {
            if (favoriteValue === simulatorQuestionId) {
              favoriteSimulator(simulatorQuestionId, "disabled");
              setFavoriteValue("");
            } else {
              favoriteSimulator(simulatorQuestionId, "active");
              setFavoriteValue(simulatorQuestionId);
            }
          }}
        />

        {progress === "finished" && <ButtonOptions id={id} onOpen={onOpen} />}
      </Flex>
    </Flex>
  );
}
