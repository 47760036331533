import { useState } from "react";
import { SelectTypeDTO } from "../../../dtos/SelectTypeDTO";
import { IDisciplineData } from "..";
import { mapDiciplineObject } from "../../../utils/mapDiciplineObject";
import { api } from "../../../service/api";

interface IYear {
  diciplineSelected: IDisciplineData[];
  jury: string;
  modality: string;
}

export function useListYear() {
  const [loadingYear, setLoadingYear] = useState(false);
  const [error, setError] = useState<boolean | null>(null);
  const [dataLisYear, setDataLisYear] = useState<SelectTypeDTO[]>([]);

  const fetchData = async ({ diciplineSelected, jury, modality }: IYear) => {
    try {
      const filterDicipline = mapDiciplineObject(diciplineSelected);
      setDataLisYear([]);
      setLoadingYear(true);
      const path = `/simulator/list/filter/year?disciplines_subjects=${filterDicipline}&jury=${jury}&modality=${modality}`;
      const response = await api.get(path);

      const data: SelectTypeDTO[] = [];

      response.data.forEach((element: any) => {
        data.push({
          label: element.year,
          value: element.year,
        });
      });

      setDataLisYear(data);
    } catch (error) {
      setError(true);
    } finally {
      setLoadingYear(false);
    }
  };

  return {
    dataLisYear,
    loadingYear,
    error,
    refetchYear: fetchData,
  };
}
