import { Flex, Icon, Text } from "@chakra-ui/react";
import { RiCheckboxCircleFill, RiCloseCircleLine } from "react-icons/ri";

type Props = {
  isSelected: boolean;
  isBlock?: boolean;
  type?: "error" | "success" | "default";
  title: string;
  onClick: () => void;
};

export function SelectTrueOrFalse({
  title,
  type = "default",
  isSelected,
  onClick,
  isBlock,
}: Props) {
  return (
    <Flex
      style={{
        alignItems: "center",
        justifyContent: "center",
        marginBottom: 10,
        cursor: !isBlock ? "pointer" : "not-allowed",
      }}
      onClick={!isBlock ? onClick : undefined}
    >
      <Flex
        style={{
          width: 20,
          height: 20,
          borderRadius: 10,
          borderWidth: 1,
          borderColor:
            type === "error"
              ? "#FF0000"
              : type === "success"
              ? "#03A679"
              : "#0577BE",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        {isSelected && (
          <Flex
            style={{
              width: 12,
              height: 12,
              borderRadius: 6,
              backgroundColor:
                type === "error"
                  ? "#FF0000"
                  : type === "success"
                  ? "#03A679"
                  : "#0577BE",
            }}
          />
        )}
      </Flex>

      <Text
        style={{
          marginLeft: 8,
          color:
            type === "error"
              ? "#FF0000"
              : type === "success"
              ? "#03A679"
              : "#5F5F5F",
          fontSize: 16,
          fontFamily: "Kanit",
          fontWeight: 300,
        }}
      >
        {title}
      </Text>
      {(type === "error" || type === "success") && (
        <Flex ml={1}>
          <Icon
            fontSize="16px"
            as={type === "success" ? RiCheckboxCircleFill : RiCloseCircleLine}
            color={type === "success" ? "#03A679" : "#FF7C7C"}
          />
        </Flex>
      )}
    </Flex>
  );
}
