import { useState } from "react";
import { api } from "../../../service/api";
import { CommetDTO } from "../../../dtos/CommetDTO";

export function useListComment() {
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState<boolean | null>(null);
  const [dataList, setDataList] = useState<CommetDTO[]>([]);

  const fetchData = async (id: string) => {
    try {
      setLoading(true);
      const response = await api.get(`/comment/list/${id}`);
      setDataList(response.data.data);
    } catch (error) {
      setError(true);
    } finally {
      setLoading(false);
    }
  };

  return {
    dataList,
    loading,
    error,
    refetch: fetchData,
  };
}
