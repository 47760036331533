import { Box, Flex, Text } from "@chakra-ui/react";

type Props = {
  title: string;
};
export function Title({ title }: Props) {
  return (
    <Flex style={{ alignItems: "center" }}>
      <Box
        style={{
          height: 4,
          backgroundColor: "#0577BE",
          borderRadius: 10,
        }}
        width={["20px", "20px", "40px"]}
      />
      <Text
        style={{
          color: "#343434",
          fontWeight: 600,
          fontFamily: "Kanit",
          marginLeft: 13,
        }}
        fontSize={["16px", "16px", "24px"]}
      >
        {title}
      </Text>
    </Flex>
  );
}
