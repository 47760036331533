import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";

import { SubmitHandler, useForm, Controller } from "react-hook-form";

import { Box, Button, Flex, Icon, useToast } from "@chakra-ui/react";
import { Title } from "../../../../components/Title";
import { api } from "../../../../service/api";
import { InputSelectBox } from "../../../../components/form/inputSelectBox";
import { InputBox } from "../../../../components/form/inputBox";
import { RiSaveLine } from "react-icons/ri";
import { useListCategories } from "../hooks/useListCategories";
import { useEffect, useState } from "react";
import { SelectTypeDTO } from "../../../../dtos/SelectTypeDTO";

type createFormData = {
  type: string;
  name: string;
  sub_category?: string;
};

const createFormSchema = yup.object().shape({
  type: yup.string().required("Campo obrigatório."),
  name: yup.string().required("Campo obrigatório."),
  sub_category: yup.string(),
});

export function CreateCategories() {
  const toast = useToast();

  const {
    register,
    control,
    handleSubmit,
    watch,
    formState: { errors, isSubmitting },
  } = useForm({ resolver: yupResolver(createFormSchema) });

  const [disciplineType, setDisciplineType] = useState<SelectTypeDTO[]>([]);

  const { loading, refetch } = useListCategories();

  const type = watch("type");

  const handleCreate: SubmitHandler<createFormData> = async (value) => {
    try {
      const data = {
        ...value,
      };

      await api.post("category/question/create", data);

      toast({
        position: "top-right",
        title: "Questão",
        description: "Questão cadastrado com sucesso.",
        status: "success",
        duration: 9000,
        isClosable: true,
      });

      window.location.reload();
    } catch (error) {
      toast({
        position: "top-right",
        title: "Questão",
        description: "Não foi possível cadastrar o questão.",
        status: "error",
        duration: 9000,
        isClosable: true,
      });
    }
  };

  async function getDicipline() {
    const dataDiscipline = await refetch("discipline");

    if (dataDiscipline) {
      const newSelectTypeDTO: SelectTypeDTO[] = [];

      for (let index = 0; index < dataDiscipline.length; index++) {
        const element = dataDiscipline[index];
        newSelectTypeDTO.push({
          label: element.name,
          value: element.name,
        });
      }
      setDisciplineType(newSelectTypeDTO);
    }
  }

  useEffect(() => {
    getDicipline();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Flex w="100%" flexDirection="column" marginBottom="100px">
      <Flex style={{ marginTop: 48, flexDirection: "column" }}>
        <Title title="Nova configuração" />

        <Flex
          as="form"
          onSubmit={handleSubmit(handleCreate)}
          style={{
            flexDirection: "column",
          }}
          marginTop={["20px", "20px", "60px"]}
          width={["100%", "100%", "675px"]}
        >
          <Box style={{ marginTop: 25 }}>
            <Controller
              control={control}
              name="type"
              render={({ field: { onChange, value } }) => (
                <InputSelectBox
                  width="100%"
                  label="Tipo"
                  name="type"
                  options={[
                    {
                      label: "Disciplina",
                      value: "discipline",
                    },
                    {
                      label: "Assunto",
                      value: "subject",
                    },
                    {
                      label: "Banca",
                      value: "jury",
                    },
                  ]}
                  onChange={(value) => {
                    onChange(value.value);
                  }}
                  placeholder="Tipo"
                  error={errors.type}
                />
              )}
            />
          </Box>

          {type === "subject" && (
            <Box style={{ marginTop: 25 }}>
              <Controller
                control={control}
                name="sub_category"
                render={({ field: { onChange, value } }) => (
                  <InputSelectBox
                    width="100%"
                    label="Sub Categoria"
                    name="sub_category"
                    isLoading={loading}
                    options={disciplineType}
                    onChange={(value) => {
                      onChange(value.value);
                    }}
                    placeholder="Sub Categoria"
                    error={errors.sub_category}
                  />
                )}
              />
            </Box>
          )}

          <Box style={{ marginTop: 25 }}>
            <InputBox
              label="Nome"
              placeholder="Digite o nome"
              {...register("name")}
              error={errors.name}
              autoComplete="off"
            />
          </Box>

          <Flex
            style={{
              marginTop: 25,
              marginBottom: 80,
              alignItems: "flex-end",
              justifyContent: "flex-end",
              width: "100%",
              flexDirection: "column",
            }}
          >
            <Button
              type="submit"
              bg="green.500"
              color="white"
              isLoading={isSubmitting}
              style={{
                borderRadius: 53,
                height: 44,
                fontSize: 16,
                fontWeight: 600,
                marginBottom: 50,
              }}
              width={["100%", "100%", "193px"]}
              _hover={{
                bg: "green.500",
              }}
              mt="6"
              size="lg"
              rightIcon={<Icon fontSize="20px" as={RiSaveLine} color="#fff" />}
            >
              Salvar categoria
            </Button>
          </Flex>
        </Flex>
      </Flex>
    </Flex>
  );
}
