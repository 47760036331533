import { IDisciplineData } from "../pages/simulated";

interface IDisciplinesSubject {
  discipline: string;
  subjects: string;
}

export function mapDiciplineObject(diciplineSelected: IDisciplineData[]) {
  const disciplinesSubjects: IDisciplinesSubject[] = [];

  diciplineSelected.forEach((values) => {
    disciplinesSubjects.push({
      discipline: values.discipline,
      subjects: values.subject.join("|"),
    });
  });

  return JSON.stringify(disciplinesSubjects);
}
