import {
  Flex,
  FormControl,
  FormErrorMessage,
  FormLabel,
  Text,
} from "@chakra-ui/react";
import { Select, SelectInstance } from "chakra-react-select";
import { ForwardRefRenderFunction, forwardRef } from "react";
import { FieldError } from "react-hook-form";

interface IOptions {
  label: string;
  value?: string;
  icon?: any;
}

type Props = {
  name: string;
  options: IOptions[];
  placeholder: string;
  label?: string;
  subLabel?: string;
  error?: FieldError;
  isLoading?: boolean;
  onChange?: (value: any) => void;
  isDisabled?: boolean;
  defaultValue?: IOptions;
  width: string;
  value?: object;
  isMult?: boolean;
};

const InputSelectBoxBase: ForwardRefRenderFunction<SelectInstance, Props> = (
  {
    options,
    placeholder,
    label,
    subLabel,
    error,
    name,
    isLoading = false,
    onChange,
    isDisabled = false,
    defaultValue,
    width,
    value,
    isMult = false,
    ...rest
  },
  ref
) => {
  return (
    <FormControl isInvalid={!!error}>
      {label && (
        <Flex>
          <FormLabel
            style={{
              color: "#0577BE",
              fontSize: 14,
              fontWeight: 500,
              fontFamily: "Kanit",
            }}
            htmlFor={name}
          >
            {label}
          </FormLabel>
          {subLabel && (
            <Text
              style={{
                color: "#818181",
                fontSize: 14,
                fontWeight: 500,
                fontFamily: "Kanit",
                marginLeft: -7,
              }}
            >
              {subLabel}
            </Text>
          )}
        </Flex>
      )}
      <Select
        {...rest}
        ref={ref}
        isMulti={isMult}
        name={name}
        size="lg"
        value={value}
        onChange={onChange}
        isLoading={isLoading}
        loadingMessage={() => "Carregando..."}
        noOptionsMessage={() => "Sem opções"}
        focusBorderColor="#818181"
        selectedOptionStyle="check"
        placeholder={placeholder}
        options={options}
        isDisabled={isDisabled}
        defaultValue={defaultValue}
        useBasicStyles
        chakraStyles={{
          control: (provided) => ({
            ...provided,
            borderColor: "#E8E8E8",
            borderWidth: 1,
            width: width,
            bg: "transparent",
            borderRadius: 10,
            fontSize: 16,
            fontWeight: 400,
            fontFamily: "Kanit",
            color: "#818181",
          }),
          downChevron: (provided) => ({
            ...provided,
            color: "#0577BE",
            marginRight: 3,
            fontSize: 30,
          }),
          placeholder: (provided) => ({
            ...provided,
            color: "#ccc",
            fontSize: 16,
            fontWeight: 400,
            fontFamily: "Kanit",
          }),
          option: (provided) => ({
            ...provided,
            color: "#818181",
            fontSize: 16,
            fontWeight: 400,
            fontFamily: "Kanit",
          }),
        }}
      />
      {!!error && <FormErrorMessage>{error.message}</FormErrorMessage>}
    </FormControl>
  );
};

export const InputSelectBox = forwardRef(InputSelectBoxBase);
