import { Flex, Text, useToast } from "@chakra-ui/react";
import { useState } from "react";
import { ButtonReply } from "../../components/buttonReply";
import { ButtonTip } from "../../components/buttonTip";
import { BoxResult } from "../../components/boxResult";
import { Pagination } from "../../components/pagination";
import { SelectMultipleChoice } from "../../components/selectMultipleChoice";
import {
  Alternatives,
  simulatorQuestionDTO,
} from "../../../../dtos/simulatorQuestionDTO";
import { api } from "../../../../service/api";
import { AllLoading } from "../../../../components/allLoading";

type Props = {
  simulatorQuestion: simulatorQuestionDTO;
  onUpdate: (reply: "success" | "error" | "no_reply") => void;
};

export function QuestionMultipleChoice({ simulatorQuestion, onUpdate }: Props) {
  const toast = useToast();
  const [isSelectedOption, setIsSelectedOption] = useState("");
  const [loading, setLoading] = useState(false);

  function formatNumber(quantity: number | null) {
    if (quantity && quantity > 0) {
      let newQuantity = quantity > 9 ? quantity : `0${quantity}`;
      return newQuantity;
    } else {
      return 0;
    }
  }

  const handleCreate = async () => {
    try {
      const data = {
        id: simulatorQuestion.simulatorQuestion.id,
        reply: isSelectedOption,
        page_question: simulatorQuestion.paginations.current,
      };
      setLoading(true);
      const response = await api.post("/simulators/reply", data);

      const resultReply = response.data.reply as
        | "success"
        | "error"
        | "no_reply";

      toast({
        position: "top-right",
        title: "Simulador",
        description: "Resposta enviada com sucesso.",
        status: "success",
        duration: 9000,
        isClosable: true,
      });

      onUpdate(resultReply);
    } catch (error) {
      toast({
        position: "top-right",
        title: "Simulador",
        description: "Não foi enviar a resposta.",
        status: "error",
        duration: 9000,
        isClosable: true,
      });
    } finally {
      setLoading(false);
    }
  };

  function isType(item: Alternatives): "success" | "error" | "default" {
    if (
      simulatorQuestion.simulatorQuestion.reply !== "no_reply" &&
      item.is_correct === 1 &&
      item.title === simulatorQuestion.simulatorQuestion.reply_type
    ) {
      return "success";
    } else if (
      simulatorQuestion.simulatorQuestion.reply !== "no_reply" &&
      item.is_correct === 0 &&
      item.title === simulatorQuestion.simulatorQuestion.reply_type
    ) {
      return "error";
    } else {
      if (
        simulatorQuestion.simulatorQuestion.reply !== "no_reply" &&
        item.is_correct === 1
      ) {
        return "success";
      } else {
        return "default";
      }
    }
  }

  return (
    <Flex style={{ flexDirection: "column" }}>
      {loading && <AllLoading />}
      <Flex style={{ flexDirection: "row" }}>
        <Text
          style={{
            fontSize: 16,
            fontWeight: 600,
            color: "#0577BE",
            fontFamily: "Kanit",
          }}
        >
          {formatNumber(simulatorQuestion.simulatorQuestion.numer_question)}
        </Text>

        <Text
          style={{
            fontSize: 16,
            fontWeight: 300,
            fontFamily: "Kanit",
            marginLeft: 16,
          }}
          width={["100%", "100%", "1071px"]}
        >
          {simulatorQuestion.simulatorQuestion.question.enunciation}
        </Text>
      </Flex>

      <Flex
        style={{
          marginTop: 25,
          flexDirection: "column",
          alignItems: "flex-start",
          justifyContent: "flex-start",
          marginLeft: 30,
        }}
      >
        {simulatorQuestion.simulatorQuestion.question.alternatives?.map(
          (item, index) => {
            return (
              <SelectMultipleChoice
                key={index}
                title={item.title}
                description={item.description}
                isSelected={
                  (isSelectedOption !== "" &&
                    isSelectedOption === item.title) ||
                  simulatorQuestion.simulatorQuestion.reply_type === item.title
                }
                isBlock={
                  simulatorQuestion.simulatorQuestion.reply !== "no_reply"
                }
                type={isType(item)}
                onClick={() => {
                  setIsSelectedOption(item.title);
                }}
              />
            );
          }
        )}

        <Flex
          style={{
            marginTop: 30,
            alignItems: "center",
            justifyContent: "space-between",
          }}
          width={["100%", "100%", "1250px"]}
          flexDirection={["column", "column", "row"]}
        >
          <Flex
            style={{ gap: 20 }}
            width={["100%", "100%", "auto"]}
            flexDirection={["column", "column", "row"]}
          >
            <ButtonReply
              onClick={isSelectedOption !== "" ? handleCreate : () => {}}
            />

            {simulatorQuestion.simulatorQuestion.question.answer_tips.length >
              0 && (
              <ButtonTip
                answerTips={
                  simulatorQuestion.simulatorQuestion.question.answer_tips
                }
                onClick={() => {}}
              />
            )}

            {simulatorQuestion.simulatorQuestion.reply !== "no_reply" && (
              <BoxResult
                content_type={
                  simulatorQuestion.simulatorQuestion.question.content_type
                }
                correct_alternative={
                  simulatorQuestion.simulatorQuestion.correct_alternative
                }
                type={simulatorQuestion.simulatorQuestion.reply}
              />
            )}
          </Flex>

          <Flex marginTop={["20px", "20px", "0px"]}>
            <Pagination
              next={simulatorQuestion.paginations.next}
              previous={simulatorQuestion.paginations.previous}
              total={simulatorQuestion.paginations.total}
              current={simulatorQuestion.paginations.current}
            />
          </Flex>
        </Flex>
      </Flex>
    </Flex>
  );
}
