import { useEffect, useState } from "react";
import { useSearchParams } from "react-router-dom";
import { simulatorQuestionDTO } from "../../../dtos/simulatorQuestionDTO";
import { api } from "../../../service/api";

export function useShowSimulator(id: string) {
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState<boolean | null>(null);
  const [data, setData] = useState<simulatorQuestionDTO>(
    {} as simulatorQuestionDTO
  );

  const [searchParams] = useSearchParams();

  const pagePuestion = searchParams.get("page_question") ?? "";

  const fetchData = async () => {
    try {
      setLoading(true);
      const response = await api.get(
        `simulators/show/${id}?page_question=${pagePuestion}`
      );
      setData(response.data);
    } catch (error) {
      setError(true);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pagePuestion, id]);

  return {
    data,
    loading,
    error,
    refetch: fetchData,
  };
}
