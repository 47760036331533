import { useState } from "react";
import { SelectTypeDTO } from "../../../dtos/SelectTypeDTO";
import { IDisciplineData } from "..";
import { mapDiciplineObject } from "../../../utils/mapDiciplineObject";
import { api } from "../../../service/api";

export function useListJury() {
  const [loadingJury, setLoadingJury] = useState(false);
  const [error, setError] = useState<boolean | null>(null);
  const [dataLisJury, setDataLisJury] = useState<SelectTypeDTO[]>([]);

  const fetchData = async (diciplineSelected: IDisciplineData[]) => {
    try {
      const filterDicipline = mapDiciplineObject(diciplineSelected);
      setDataLisJury([]);
      setLoadingJury(true);
      const path = `/simulator/list/filter/jury?disciplines_subjects=${filterDicipline}`;
      const response = await api.get(path);

      const data: SelectTypeDTO[] = [];

      response.data.forEach((element: any) => {
        data.push({
          label: element.jury,
          value: element.jury,
        });
      });

      setDataLisJury(data);
    } catch (error) {
      setError(true);
    } finally {
      setLoadingJury(false);
    }
  };

  return {
    dataLisJury,
    loadingJury,
    error,
    refetchJury: fetchData,
  };
}
