import { Flex, Icon, Text } from "@chakra-ui/react";
import { RiUser3Fill } from "react-icons/ri";
import { formatedDateTimerUs } from "../../../../utils/utils";

type Props = {
  date: string;
  userName: string;
  description: string;
};

export function ListItem({ date, userName, description }: Props) {
  return (
    <Flex
      style={{
        flexDirection: "column",
        marginBottom: 20,
      }}
    >
      <Text
        style={{
          color: "#024C7A",
          fontSize: 12,
          fontFamily: "Kanit",
          fontWeight: 600,
        }}
      >
        {formatedDateTimerUs(date)}
      </Text>
      <Flex>
        <Flex
          style={{
            width: 27,
            height: 27,
            borderRadius: 13.5,
            borderWidth: 1,
            borderColor: "#03A679",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <Icon fontSize="10px" as={RiUser3Fill} color="#D9D9D9" />
        </Flex>
        <Text
          style={{
            color: "#03A679",
            fontSize: 16,
            fontFamily: "Kanit",
            fontWeight: 400,
            marginLeft: 5,
          }}
        >
          Aluno
        </Text>
        <Text
          style={{
            color: "#232323",
            fontSize: 16,
            fontFamily: "Kanit",
            fontWeight: 600,
            marginLeft: 5,
          }}
        >
          {userName}
        </Text>
      </Flex>

      <Flex style={{ marginTop: 10 }}>
        <Text
          style={{
            color: "#000000",
            fontSize: 16,
            fontFamily: "Kanit",
            fontWeight: 300,
          }}
        >
          {description}
        </Text>
      </Flex>
    </Flex>
  );
}
