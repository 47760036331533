import {
  Button,
  Flex,
  Icon,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalHeader,
  ModalOverlay,
  Text,
  useToast,
} from "@chakra-ui/react";
import { QuestionsSimulatorInfoDTO } from "../../../../dtos/QuestionsSimulatorInfoDTO";
import { BoxContentDiciplineAndSubject } from "./components/boxContentDiciplineAndSubject";
import { useEffect, useState } from "react";
import { RiSaveFill } from "react-icons/ri";
import { api } from "../../../../service/api";
import { URL_WEB } from "../../../../utils/utils";

type Props = {
  data: QuestionsSimulatorInfoDTO[];
  quantityQuestions: number;
  content_type?: string;
  title: string;
  is_open: boolean;
  onClose: () => void;
  onCloseSend: () => void;
};
export function SimulatorResultModal({
  data,
  quantityQuestions,
  content_type,
  title,
  is_open,
  onClose,
  onCloseSend,
  ...rest
}: Props) {
  const [dataValues, setDataValues] = useState<QuestionsSimulatorInfoDTO[]>([]);
  const [totalQuestions, setTotalQuestions] = useState(0);
  const [loading, setLoading] = useState(false);
  const [isCreate, setIsCreate] = useState(false);
  const [isCopy, setIsCopy] = useState(false);

  const [idSimulator, setIdSimulator] = useState("");
  const toast = useToast();

  async function CreateSimulator() {
    try {
      const data = {
        name: title,
        filter: dataValues,
        content_type,
      };
      setLoading(true);
      const response = await api.post("/simulator/create", data);
      toast({
        position: "top-right",
        title: "Simulador",
        description: "Simulador criado com sucesso.",
        status: "success",
        duration: 9000,
        isClosable: true,
      });
      setIdSimulator(response.data.id as string);
      setIsCreate(true);
    } catch (error) {
      toast({
        position: "top-right",
        title: "Simulador",
        description: "Não foi criar a simulador.",
        status: "error",
        duration: 9000,
        isClosable: true,
      });
    } finally {
      setLoading(false);
    }
  }

  useEffect(() => {
    setDataValues(data);
  }, [data]);

  useEffect(() => {
    let quantity = 0;
    dataValues.forEach((item) => {
      item.subjects.forEach((values) => {
        quantity = quantity + values.quantity;
      });
    });

    setTotalQuestions(quantity);
  }, [dataValues]);

  return (
    <>
      <Modal
        motionPreset="slideInBottom"
        isOpen={is_open}
        onClose={
          isCreate
            ? () => {
                alert("Selecione uma das opções");
              }
            : onClose
        }
        isCentered
        closeOnOverlayClick={false}
        {...rest}
        size="6xl"
      >
        <ModalOverlay />
        <ModalContent display="flex" flexDir="column">
          <ModalHeader
            textAlign="left"
            style={{
              color: "#03A679",
              fontSize: 16,
              fontFamily: "Kanit",
              fontWeight: 600,
            }}
          >
            Criar Simulado
          </ModalHeader>
          <ModalCloseButton />
          {isCreate && (
            <ModalBody pb={10}>
              <Flex
                style={{
                  alignItems: "center",
                  justifyContent: "center",
                  gap: 10,
                }}
                flexDirection={["column", "column", "row"]}
              >
                <Button
                  type="submit"
                  bg="green.500"
                  color="white"
                  style={{
                    borderRadius: 53,
                    height: 44,
                    fontSize: 16,
                    fontWeight: 600,
                  }}
                  marginTop={["10px", "10px", "40px"]}
                  width={["100%", "100%", "auto"]}
                  _hover={{
                    bg: "green.500",
                  }}
                  mt="6"
                  size="lg"
                  as="a"
                  href="/simuladores"
                >
                  Meus simulados
                </Button>

                <Button
                  type="submit"
                  bg="blue.500"
                  color="white"
                  onClick={() => {
                    const url = URL_WEB + "/simulador/novo/" + idSimulator;
                    navigator.clipboard.writeText(url);
                    setIsCopy(true);
                  }}
                  style={{
                    borderRadius: 53,
                    height: 44,
                    fontSize: 16,
                    fontWeight: 600,
                  }}
                  width={["100%", "100%", "auto"]}
                  marginTop={["10px", "10px", "40px"]}
                  _hover={{
                    bg: "blue.500",
                  }}
                  mt="6"
                  size="lg"
                >
                  Copiar link
                </Button>
              </Flex>

              {isCopy && (
                <Text
                  style={{
                    color: "#5F5F5F",
                    fontSize: 16,
                    fontFamily: "Kanit",
                    fontWeight: 300,
                    marginTop: 25,
                    textAlign: "center",
                  }}
                >
                  Link copiado.
                </Text>
              )}
            </ModalBody>
          )}
          {!isCreate && (
            <ModalBody pb={10}>
              <Flex style={{ alignItems: "center" }}>
                <Text
                  style={{
                    color: "#5F5F5F",
                    fontSize: 16,
                    fontFamily: "Kanit",
                    fontWeight: 300,
                  }}
                >
                  {quantityQuestions > 1
                    ? "Foram encontradas"
                    : "Foi encontrada"}
                </Text>
                <Text
                  style={{
                    color: "#5F5F5F",
                    fontSize: 24,
                    fontFamily: "Kanit",
                    fontWeight: 800,
                  }}
                  ml={1}
                  mr={1}
                >{`${quantityQuestions}`}</Text>
                <Text
                  style={{
                    color: "#5F5F5F",
                    fontSize: 16,
                    fontFamily: "Kanit",
                    fontWeight: 300,
                  }}
                >
                  {quantityQuestions > 1 ? "questões" : "questão"}
                </Text>
              </Flex>

              <Text
                style={{
                  color: "#5F5F5F",
                  fontSize: 16,
                  fontFamily: "Kanit",
                  fontWeight: 300,
                  marginTop: 25,
                }}
              >
                Informe a quantidade de questões por assunto.
              </Text>
              <Text
                style={{
                  color: "#5F5F5F",
                  fontSize: 16,
                  fontFamily: "Kanit",
                  fontWeight: 800,
                  marginTop: 25,
                }}
              >
                {`Nome do Simulado: ${title}`}
              </Text>
              {dataValues.map((item, index) => {
                return (
                  <Flex key={index} style={{ marginTop: 25 }}>
                    <BoxContentDiciplineAndSubject
                      data={item}
                      onChangeValue={(item) => {
                        const itemDataValues = dataValues.map((it) => {
                          return it.discipline === item.discipline ? item : it;
                        });
                        setDataValues(itemDataValues);
                        console.log(itemDataValues);
                      }}
                    />
                  </Flex>
                );
              })}

              <Flex style={{ alignItems: "center", marginTop: 20 }}>
                <Text
                  style={{
                    color: "#5F5F5F",
                    fontSize: 16,
                    fontFamily: "Kanit",
                    fontWeight: 300,
                  }}
                >
                  O seu simulado terá um total de
                </Text>
                <Text
                  style={{
                    color: "#5F5F5F",
                    fontSize: 24,
                    fontFamily: "Kanit",
                    fontWeight: 800,
                  }}
                  ml={1}
                  mr={1}
                >{`${totalQuestions}`}</Text>
                <Text
                  style={{
                    color: "#5F5F5F",
                    fontSize: 16,
                    fontFamily: "Kanit",
                    fontWeight: 300,
                  }}
                >
                  {totalQuestions > 1 ? "questões" : "questão"}
                </Text>
              </Flex>

              <Button
                type="submit"
                bg="green.500"
                color="white"
                onClick={CreateSimulator}
                isLoading={loading}
                style={{
                  borderRadius: 53,
                  height: 44,
                  fontSize: 16,
                  fontWeight: 600,
                  marginTop: 40,
                }}
                width={["100%", "100%", "193px"]}
                _hover={{
                  bg: "green.500",
                }}
                mt="6"
                size="lg"
                rightIcon={
                  <Icon fontSize="20px" as={RiSaveFill} color="#fff" />
                }
              >
                Gerar Simulado
              </Button>
            </ModalBody>
          )}
        </ModalContent>
      </Modal>
    </>
  );
}
