import {
  Button,
  Flex,
  Icon,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalHeader,
  ModalOverlay,
  Text,
} from "@chakra-ui/react";
import { RiPlayFill, RiThumbUpFill } from "react-icons/ri";

type Props = {
  title: string;
  info: string;
  is_open: boolean;
  onClose: () => void;
  onCloseSend: () => void;
  onNexPage: () => void;
};
export function ModalTip({
  is_open,
  onClose,
  onCloseSend,
  onNexPage,
  title,
  info,
  ...rest
}: Props) {
  return (
    <>
      <Modal
        motionPreset="slideInBottom"
        isOpen={is_open}
        onClose={onClose}
        isCentered
        {...rest}
        size="4xl"
      >
        <ModalOverlay />
        <ModalContent display="flex" flexDir="column">
          <ModalHeader
            textAlign="left"
            style={{
              color: "#03A679",
              fontSize: 16,
              fontFamily: "Kanit",
              fontWeight: 600,
            }}
          >
            {`DICA ${title}`}
          </ModalHeader>
          <ModalCloseButton />
          <ModalBody pb={10}>
            <Text>{info}</Text>
            <Flex
              style={{
                alignItems: "center",
                gap: 15,
              }}
            >
              <Button
                onClick={onClose}
                type="button"
                bg="green.600"
                color="white"
                alignSelf="flex-end"
                _hover={{
                  bg: "green.800",
                }}
                mt="6"
                style={{
                  fontSize: 14,
                  fontFamily: "Kanit",
                  fontWeight: 500,
                  borderRadius: 13,
                  width: 103,
                  height: 27,
                }}
                rightIcon={<Icon as={RiThumbUpFill} color="#fff" />}
              >
                Entendi!
              </Button>
              <Button
                onClick={onNexPage}
                type="button"
                color="green.800"
                alignSelf="flex-end"
                _hover={{
                  bg: "green.800",
                }}
                mt="6"
                style={{
                  fontSize: 14,
                  fontFamily: "Kanit",
                  fontWeight: 500,
                  borderRadius: 13,
                  width: 171,
                  height: 27,
                  backgroundColor: "#fff",
                  color: "#5F5F5F",
                  borderWidth: 1,
                  borderColor: "#03A679",
                }}
                rightIcon={<Icon as={RiPlayFill} color="#03A679" />}
              >
                {
                  <Text
                    style={{
                      fontSize: 14,
                      fontFamily: "Kanit",
                      fontWeight: 500,
                      borderRadius: 13,
                      color: "#03A679",
                      marginRight: 8,
                    }}
                  >
                    Próxima Dica
                  </Text>
                }
                {title}
              </Button>
            </Flex>
          </ModalBody>
        </ModalContent>
      </Modal>
    </>
  );
}
