import { Button, Flex, Icon, Text } from "@chakra-ui/react";
import { Title } from "../../../components/Title";
import ReactApexChart from "react-apexcharts";
import { ApexOptions } from "apexcharts";
import { useSearchParams } from "react-router-dom";
import { useState } from "react";
import { RiFilter2Line } from "react-icons/ri";
import { InputBox } from "../../../components/form/inputBox";
import { useSimulatorVolume } from "./hooks/useSimulatorVolume";
import { formatCurrency } from "../../../utils/utils";
import { AllLoading } from "../../../components/allLoading";

export function Dash() {
  const [searchParams, setSearchParams] = useSearchParams();
  const { dataList, loading } = useSimulatorVolume();

  const start_date = searchParams.get("start_date");
  const end_date = searchParams.get("end_date");

  const [startDate, setStartDate] = useState(start_date ?? "");
  const [endDate, setEndDate] = useState(end_date ?? "");

  function handleFilter() {
    setSearchParams((state) => {
      if (startDate) {
        state.set("start_date", startDate);
      } else {
        state.delete("start_date");
      }

      if (endDate) {
        state.set("end_date", endDate);
      } else {
        state.delete("end_date");
      }

      return state;
    });
  }

  return (
    <Flex w="100%" flexDirection="column" marginBottom="100px">
      <Flex style={{ marginTop: 48, flexDirection: "column" }}>
        <Title title="Dashboard" />

        {loading && <AllLoading />}

        <Flex
          mt="60px"
          gap={5}
          alignItems="center"
          flexDirection={["column", "column", "row"]}
        >
          <InputBox
            label="Data início"
            placeholder="Selecione a data início"
            type="date"
            name="start_date"
            autoComplete="off"
            value={startDate}
            onChange={(e) => {
              setStartDate(e.target.value);
            }}
          />
          <InputBox
            label="Data final"
            placeholder="Selecione a data final"
            type="date"
            name="end_date"
            autoComplete="off"
            value={endDate}
            onChange={(e) => {
              setEndDate(e.target.value);
            }}
          />
          <Button
            bg="green.500"
            color="white"
            style={{
              borderRadius: 53,
              height: 44,
              fontSize: 16,
              fontWeight: 600,
            }}
            width={["100%", "100%", "470px"]}
            _hover={{
              bg: "green.500",
            }}
            mt="6"
            size="lg"
            rightIcon={<Icon fontSize="20px" as={RiFilter2Line} color="#fff" />}
            onClick={() => {
              handleFilter();
            }}
          >
            Filtrar
          </Button>
        </Flex>

        {!loading && dataList.total_questions > 0 && (
          <Flex
            style={{
              flexDirection: "column",
            }}
          >
            <Flex
              marginTop={["50px", "50px", "20px"]}
              width="100%"
              height={["100%", "100%", "500px"]}
              flexDirection={["column", "column", "row"]}
              gap={[10, 10, 100]}
            >
              <Flex
                width={["100%", "100%", "250px"]}
                height={["100%", "100%", "500px"]}
                align="center"
                justify="center"
                style={{
                  borderColor: "#0577BE",
                  borderWidth: 5,
                  borderRadius: 10,
                }}
                flexDirection="column"
              >
                <Text
                  style={{
                    color: "#343434",
                    fontSize: 24,
                    fontWeight: 400,
                    fontFamily: "Kanit",
                  }}
                >
                  N de questões
                </Text>
                <Text
                  style={{
                    color: "#343434",
                    fontSize: 60,
                    fontWeight: 600,
                    fontFamily: "Kanit",
                  }}
                >
                  {dataList.total_questions}
                </Text>
              </Flex>
              <Flex
                width={["100%", "100%", "400px"]}
                height={["100%", "100%", "500px"]}
              >
                <Flex display={["none", "none", "flex"]}>
                  <ReactApexChart
                    options={getOptions()}
                    series={
                      dataSeries(
                        dataList.success,
                        dataList.error,
                        dataList.no_reply
                      ).series
                    }
                    type="bar"
                    height={"100%"}
                    width={"400px"}
                  />
                </Flex>
                <Flex display={["flex", "flex", "none"]}>
                  <ReactApexChart
                    options={getOptions()}
                    series={
                      dataSeries(
                        dataList.success,
                        dataList.error,
                        dataList.no_reply
                      ).series
                    }
                    type="bar"
                    height={"300px"}
                    width={"100%"}
                  />
                </Flex>
              </Flex>
              <Flex
                width={["100%", "100%", "250px"]}
                height={["100%", "100%", "500px"]}
                align="center"
                justify="center"
                style={{
                  borderColor: "#0577BE",
                  borderWidth: 5,
                  borderRadius: 10,
                }}
                flexDirection="column"
              >
                <Text
                  style={{
                    color: "#343434",
                    fontSize: 24,
                    fontWeight: 400,
                    fontFamily: "Kanit",
                  }}
                >
                  Percetual de acertos
                </Text>
                <Text
                  style={{
                    color: "#0577BE",
                    fontSize: 60,
                    fontWeight: 600,
                    fontFamily: "Kanit",
                  }}
                >
                  {formatCurrency(dataList.percentage)}%
                </Text>
              </Flex>
            </Flex>
          </Flex>
        )}
      </Flex>
    </Flex>
  );
}

function dataSeries(n1: number, n2: number, n3: number) {
  const data = {
    series: [
      {
        name: "Quantidade",
        data: [n1, n2, n3],
      },
    ],
  };

  return data;
}

function getOptions() {
  const options: ApexOptions = {
    chart: {
      type: "bar",
      toolbar: {
        show: false,
      },
      zoom: {
        enabled: false,
      },
    },
    colors: ["#0577BE"],
    plotOptions: {
      bar: {
        borderRadius: 10,
        dataLabels: {
          position: "top", // top, center, bottom
        },
      },
    },
    dataLabels: {
      enabled: true,
      offsetY: -20,
      style: {
        fontSize: "12px",
        colors: ["#304758"],
      },
    },

    xaxis: {
      categories: ["Acerto", "Erros", "Não iniciadas"],
      position: "bottom",
      axisBorder: {
        show: false,
      },
      axisTicks: {
        show: false,
      },
      crosshairs: {
        fill: {
          type: "gradient",
          gradient: {
            colorFrom: "#D8E3F0",
            colorTo: "#BED1E6",
            stops: [0, 100],
            opacityFrom: 0.4,
            opacityTo: 0.5,
          },
        },
      },
      tooltip: {
        enabled: false,
      },
    },
    yaxis: {
      axisBorder: {
        show: false,
      },
      axisTicks: {
        show: false,
      },
      labels: {
        show: false,
      },
    },
  };
  return options;
}
