import { Box, Flex } from "@chakra-ui/react";
import React from "react";
import { Header } from "../header";

type Props = {
  children: React.ReactNode;
  path?: string;
  subPath?: string;
  title?: string;
  subTitle?: string;
};

export function AuthTemplate({
  children,
  title,
  subTitle,
  path,
  subPath,
}: Props) {
  return (
    <Flex direction="column">
      <Header title={title} subTitle={subTitle} path={path} subPath={subPath} />
      <Flex
        width="100%"
        justifyContent={["center", "center", "flex-start"]}
        style={{ paddingLeft: 50, paddingRight: 50 }}
      >
        <Box>{children}</Box>
      </Flex>
    </Flex>
  );
}
