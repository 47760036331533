import {
  Box,
  Button,
  Flex,
  Image,
  Link,
  Stack,
  Switch,
  Text,
  useToast,
} from "@chakra-ui/react";

import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import { SubmitHandler, useForm } from "react-hook-form";

import { Input } from "../../../components/form/input";

import { Navigate, useNavigate } from "react-router-dom";
import { InputPassword } from "../../../components/form/inputPassword";
import { useAuth } from "../../../hooks/useAuth";
import { IMAGE_LOGIN, LOGO_BRANCA } from "../../../assets";
import { useState } from "react";

type SignInFormData = {
  email: string;
  password: string;
};

const signInFormSchema = yup.object().shape({
  email: yup
    .string()
    .required("Email é um campo obrigatório.")
    .email("Email não é valido."),
  password: yup.string().required("Senha é obrigatório."),
});

export function Login() {
  const toast = useToast();
  const navigate = useNavigate();

  const { signIn, isAuthenticated, isAtuhLoading, user } = useAuth();

  const [isCheckToken, setIsCheckToken] = useState(false);

  const {
    register,
    handleSubmit,
    formState: { errors, isSubmitting },
  } = useForm({ resolver: yupResolver(signInFormSchema) });

  if (isAtuhLoading) return;

  if (isAuthenticated && user.type === "admin") {
    return <Navigate to="/home" replace />;
  }

  if (isAuthenticated && user.type === "user" && user.is_pay === false) {
    return <Navigate to="/simuladores" replace />;
  }

  const handleSignIn: SubmitHandler<SignInFormData> = async (value) => {
    try {
      const authUser = await signIn({ ...value, isCreateToken: isCheckToken });

      if (authUser.is_pay && authUser.link_pay) {
        window.location.replace(authUser.link_pay);
      } else {
        if (authUser.type === "admin") {
          navigate("/home");
        } else {
          navigate("/simuladores");
        }
      }
    } catch (error) {
      toast({
        position: "top-right",
        title: "Login",
        description: "Não foi possível realizar o login.",
        status: "error",
        duration: 9000,
        isClosable: true,
      });
    }
  };

  return (
    <Flex w="100%" h="100vh" alignItems="center" justifyContent="center">
      <Flex
        style={{ height: "100vh" }}
        flexDirection="column"
        bg="blue.500"
        w={["100%", "100%", "45%"]}
        paddingLeft={["20px", "20px", "0px"]}
        paddingRight={["20px", "20px", "0px"]}
      >
        <Flex
          style={{ marginTop: "64px" }}
          margin={["auto", "auto", "auto"]}
          marginLeft={["auto", "0px", "70px"]}
        >
          <Image
            src={LOGO_BRANCA}
            style={{ width: "271px", height: "76.83px" }}
            alt="logo"
          />
        </Flex>

        <Flex
          flex={1}
          alignItems="center"
          justifyContent="center"
          as="form"
          onSubmit={handleSubmit(handleSignIn)}
        >
          <Flex
            flexDirection="column"
            style={{ width: "380px", height: "400px" }}
          >
            <Text style={{ fontWeight: 600, fontSize: 24 }} color="green.100">
              Entre no seu espaço
            </Text>
            <Flex flexDirection="column" style={{ marginTop: 26 }}>
              <Input
                placeholder="Usuário"
                type="email"
                error={errors.email}
                {...register("email")}
              />
              <InputPassword
                type="password"
                style={{ marginTop: 10, fontSize: 14 }}
                placeholder="Senha"
                {...register("password")}
                error={errors.password}
              />

              <Stack
                align="center"
                justifyContent="space-between"
                direction="row"
                style={{ marginTop: 21 }}
              >
                <Stack align="center" direction="row">
                  <Switch
                    size="sm"
                    variant="box"
                    onChange={(e) => {
                      setIsCheckToken(e.target.checked);
                    }}
                  />
                  <Text
                    style={{ fontWeight: 600, fontSize: 14, color: "#F2F2F2" }}
                  >
                    Lembrar de mim
                  </Text>
                </Stack>

                <Link
                  href="/recuperacao-senha"
                  color="green.100"
                  style={{ fontWeight: 600, fontSize: 14 }}
                >
                  Esqueci a senha
                </Link>
              </Stack>

              <Button
                isLoading={isSubmitting}
                type="submit"
                bg="green.500"
                color="white"
                style={{
                  borderRadius: 53,
                  height: 44,
                  fontSize: 16,
                  fontWeight: 600,
                }}
                _hover={{
                  bg: "green.500",
                }}
                mt="6"
                size="lg"
              >
                Entrar
              </Button>

              <Stack
                align="center"
                justifyContent="center"
                direction="row"
                style={{ marginTop: 21 }}
              >
                <Text
                  style={{ fontWeight: 600, fontSize: 12, color: "#F2F2F2" }}
                >
                  Ainda nao é nosso cliente?
                </Text>

                <Link
                  href="/cadastro"
                  color="green.100"
                  style={{ fontWeight: 600, fontSize: 12 }}
                >
                  Cadastre-se agora
                </Link>
              </Stack>

              <Box
                style={{
                  width: "100%",
                  height: 1,
                  backgroundColor: "#E8E8E8",
                  marginTop: 21,
                }}
              />
            </Flex>
          </Flex>
        </Flex>
      </Flex>
      <Flex
        style={{ height: "100vh", width: "55%" }}
        display={["none", "none", "flex"]}
        bg="blue.500"
      >
        <Image src={IMAGE_LOGIN} style={{ width: "100%" }} alt="imagem curso" />
      </Flex>
    </Flex>
  );
}
