import {
  Flex,
  Icon,
  IconButton,
  Image,
  Link,
  Menu,
  MenuButton,
  MenuItem,
  MenuList,
  Text,
} from "@chakra-ui/react";
import { LOGO } from "../../assets";
import { RiHome2Line, RiLogoutBoxLine, RiMenuLine } from "react-icons/ri";
import { useAuth } from "../../hooks/useAuth";

const linksMenu = [
  {
    icon: "icon",
    link: "/home",
    title: "Início",
    index: 0,
    type: "admin",
  },
  {
    icon: "icon",
    link: "/dash",
    title: "Dashboard",
    type: "admin|user",
    index: 1,
  },
  {
    icon: "icon",
    link: "/home",
    title: "Aulas",
    type: "admin",
    index: 2,
  },
  {
    icon: "icon",
    link: "/simuladores",
    title: "Simulados",
    type: "admin|user",
    index: 3,
  },
  {
    icon: "icon",
    link: "/lista-de-questoes",
    type: "admin",
    title: "Questões",
    index: 4,
  },
  {
    icon: "icon",
    link: "/home",
    type: "admin",
    title: "L. de tarefas",
    index: 5,
  },
  {
    icon: "icon",
    link: "/home",
    title: "C. Extra",
    type: "admin",
    index: 6,
  },
  {
    icon: "icon",
    link: "/ranking",
    title: "Ranking",
    type: "admin|user",
    index: 7,
  },
  {
    icon: "icon",
    link: "/configuracoes",
    title: "Config.",
    type: "admin",
    index: 8,
  },
];

type Props = {
  title?: string;
  subTitle?: string;
  subPath?: string;
  path?: string;
};

export function Header({ title, subTitle, path, subPath }: Props) {
  const { logout, user } = useAuth();

  return (
    <Flex flexDirection="column">
      <Flex
        style={{
          height: 70,
          borderBottomWidth: 1,
          borderBottomColor: "#EBEBEB",
          width: "100%",
          paddingLeft: 50,
          paddingRight: 50,
          alignItems: "center",
        }}
      >
        <Flex alignItems="center" justifyContent="space-between" width="100%">
          <Image
            src={LOGO}
            style={{ width: "176.37px", height: "50px" }}
            alt="logo"
          />

          <Flex
            display={["none", "none", "flex"]}
            style={{ marginLeft: 64, flex: 1 }}
            alignItems="center"
          >
            {linksMenu
              .filter((data) => data.type.includes(user.type))
              .map((item, index) => (
                <Link
                  href={item.link}
                  style={{
                    marginRight: 30,
                    color: "#6F6F6F",
                    fontFamily: "Kanit",
                  }}
                  key={index}
                >
                  {item.title}
                </Link>
              ))}
          </Flex>

          <Flex
            display={["none", "none", "flex"]}
            style={{ cursor: "pointer" }}
            alignItems="center"
            onClick={() => {
              logout();
            }}
          >
            <Icon fontSize="16px" as={RiLogoutBoxLine} color="#0577BE" />
            <Text
              style={{
                color: "#0577BE",
                fontWeight: 500,
                marginLeft: 2,
                fontFamily: "Kanit",
              }}
            >
              Sair
            </Text>
          </Flex>

          {/* <Flex display={["none", "none", "flex"]} alignItems="center">
            <Icon fontSize="16px" as={RiMenuLine} color="#0577BE" />
            <Text
              style={{
                color: "#0577BE",
                fontWeight: 500,
                marginLeft: 2,
                fontFamily: "Kanit",
              }}
            >
              Menu
            </Text>
          </Flex> */}

          <Flex display={["flex", "flex", "none"]}>
            <Menu>
              <MenuButton
                as={IconButton}
                aria-label="Options"
                icon={<Icon fontSize="16px" as={RiMenuLine} color="#0577BE" />}
                variant="outline"
              />
              <MenuList>
                {linksMenu
                  .filter((data) => data.type.includes(user.type))
                  .map((item, index) => (
                    <MenuItem key={index}>
                      <Link
                        href={item.link}
                        style={{
                          color: "#6F6F6F",
                          fontFamily: "Kanit",
                        }}
                      >
                        {item.title}
                      </Link>
                    </MenuItem>
                  ))}

                <MenuItem>
                  <Flex
                    style={{ cursor: "pointer" }}
                    alignItems="center"
                    onClick={() => {
                      logout();
                    }}
                  >
                    <Icon
                      fontSize="16px"
                      as={RiLogoutBoxLine}
                      color="#0577BE"
                    />
                    <Text
                      style={{
                        color: "#0577BE",
                        fontWeight: 500,
                        marginLeft: 2,
                        fontFamily: "Kanit",
                      }}
                    >
                      Sair
                    </Text>
                  </Flex>
                </MenuItem>
              </MenuList>
            </Menu>
          </Flex>
        </Flex>
      </Flex>
      <Flex
        style={{
          width: "100%",
          paddingLeft: 50,
          paddingRight: 50,
          marginTop: 26,
          alignItems: "center",
        }}
      >
        <Link href={path}>
          <Flex style={{ alignItems: "center" }}>
            <Icon fontSize="14px" as={RiHome2Line} color="#6F6F6F" />
            <Text
              style={{
                color: "#6F6F6F",
                fontSize: 12,
                fontFamily: "Kanit",
                marginLeft: 2,
              }}
            >
              {title}
            </Text>
          </Flex>
        </Link>

        {subTitle && (
          <>
            <Text
              style={{
                color: "#6F6F6F",
                fontSize: 12,
                fontFamily: "Kanit",
                marginLeft: 9,
                marginRight: 9,
              }}
            >
              {">"}
            </Text>
            <Link href={subPath}>
              <Text
                style={{
                  color: "#0577BE",
                  fontSize: 12,
                  fontFamily: "Kanit",
                }}
              >
                {subTitle}
              </Text>
            </Link>
          </>
        )}
      </Flex>
    </Flex>
  );
}
