import {
  FormControl,
  FormLabel,
  Input as ChakraInput,
  InputProps as ChakraInputProps,
  FormErrorMessage,
  Flex,
  Text,
} from "@chakra-ui/react";
import { forwardRef, ForwardRefRenderFunction } from "react";
import { FieldError } from "react-hook-form";

interface Props extends ChakraInputProps {
  name: string;
  label?: string;
  subLabel?: string;
  size?: "lg" | "md" | "sm";
  error?: FieldError;
}

const InputBase: ForwardRefRenderFunction<HTMLInputElement, Props> = (
  { name, label, subLabel, size = "lg", error, ...rest },
  ref
) => {
  return (
    <FormControl isInvalid={!!error}>
      {label && (
        <Flex>
          <FormLabel
            style={{
              color: "#0577BE",
              fontSize: 14,
              fontWeight: 500,
              fontFamily: "Kanit",
            }}
            htmlFor={name}
          >
            {label}
          </FormLabel>
          {subLabel && (
            <Text
              style={{
                color: "#818181",
                fontSize: 14,
                fontWeight: 500,
                fontFamily: "Kanit",
                marginLeft: -7,
              }}
            >
              {subLabel}
            </Text>
          )}
        </Flex>
      )}

      <ChakraInput
        {...rest}
        id={name}
        name={name}
        borderColor="#E8E8E8"
        borderWidth={1}
        focusBorderColor="#818181"
        bgColor="transparent"
        color="#818181"
        variant="filled"
        borderRadius={10}
        size={size}
        _hover={{
          bgColor: "transparent",
        }}
        style={{ fontSize: 16, fontFamily: "Kanit" }}
        _placeholder={{ color: "#818181" }}
        ref={ref}
      />
      {!!error && <FormErrorMessage>{error.message}</FormErrorMessage>}
    </FormControl>
  );
};

export const InputBox = forwardRef(InputBase);
