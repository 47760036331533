import { Flex, Icon, Image, Link, Text } from "@chakra-ui/react";
import { CRONOGRAMA_BLUE, QUESTION_BLUE } from "../../../assets";
import { RiPlayFill } from "react-icons/ri";
import { Title } from "../../../components/Title";

const linksList = [
  {
    icon: CRONOGRAMA_BLUE,
    link: "/registrar-questoes/cadastro-em-massa",
    title: "Cadastro de questões em Massa",
  },
  // {
  //   icon: CRONOGRAMA_BLUE,
  //   link: "/registrar-questoes/ia",
  //   title: "Gerar Questões com AI",
  // },
  {
    icon: QUESTION_BLUE,
    link: "/registrar-questoes/cadastro-manual",
    title: "Cadastro Manual",
  },
];

export function RegistrationQuestions() {
  return (
    <Flex w="100%" flexDirection="column">
      <Flex style={{ marginTop: 48 }}>
        <Title title="Cadastrar Questões" />
      </Flex>

      <Flex style={{ marginTop: 50, gap: 35, flexWrap: "wrap" }}>
        {linksList.map((item, index) => {
          return (
            <Flex
              key={index}
              style={{
                width: "247.88px",
                height: "142px",
                borderRadius: 10,
                borderWidth: 2,
                borderColor: "#0577BE",
                flexDirection: "column",
                padding: 20,
                justifyContent: "space-between",
              }}
            >
              <Flex
                style={{
                  alignItems: "flex-start",
                  justifyContent: "space-between",
                }}
              >
                <Text
                  style={{
                    color: "#0577BE",
                    fontSize: 18,
                    fontFamily: "Kanit",
                    fontWeight: 600,
                    width: 137,
                    lineHeight: 1,
                  }}
                >
                  {item.title}
                </Text>

                <Image
                  src={item.icon}
                  alt="logo"
                  style={{ width: "25.88", height: "20px" }}
                />
              </Flex>

              <Flex>
                <Link href={item.link}>
                  <Flex
                    style={{
                      width: "86px",
                      height: "27px",
                      backgroundColor: "#0577BE",
                      borderRadius: 13,
                      alignItems: "center",
                      justifyContent: "center",
                      alignContent: "center",
                    }}
                  >
                    <Text
                      style={{
                        color: "#F5F5F5",
                        fontSize: 14,
                        fontFamily: "Kanit",
                        fontWeight: 500,
                        marginRight: 5,
                      }}
                    >
                      Iniciar
                    </Text>
                    <Icon fontSize="14px" as={RiPlayFill} color="#F5F5F5" />
                  </Flex>
                </Link>
              </Flex>
            </Flex>
          );
        })}
      </Flex>
    </Flex>
  );
}
