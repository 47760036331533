import {
  Accordion,
  AccordionButton,
  AccordionIcon,
  AccordionItem,
  AccordionPanel,
  Box,
  Flex,
  Icon,
  Link,
  List,
  ListIcon,
  ListItem,
  Text,
  useToast,
} from "@chakra-ui/react";
import { useDropzone } from "react-dropzone";
import { Title } from "../../../../../components/Title";
import {
  RiCheckboxCircleFill,
  RiDownload2Fill,
  RiUploadCloud2Fill,
} from "react-icons/ri";
import { ReportFormatters } from "../../../../../utils/getDataExcel";
import { api } from "../../../../../service/api";
import { useState } from "react";
import { FullLoading } from "../../../../../components/fullLoading";
import { useAuth } from "../../../../../hooks/useAuth";

export function CreateManyQuestion() {
  const toast = useToast();
  const { user } = useAuth();

  const {
    getRootProps,
    getInputProps,
    isFocused,
    isDragAccept,
    isDragReject,
    open,
  } = useDropzone({
    noKeyboard: true,
    maxFiles: 0,
    noClick: true,
    multiple: false,
    accept: {
      ".xlsx": [],
      ".xls": [],
    },
    onDrop: (files) => sendDocument(files[0]),
  });

  const [dataQuestions, setDataQuestions] = useState([]);

  const [loading, setLoading] = useState(false);

  async function sendDocument(file: File) {
    // // console.log(file);
    // const data = await ReportFormatters.questions(file);
    // console.log(data);

    try {
      const data = await ReportFormatters.questions(file);
      setDataQuestions(data);
    } catch (error) {
      toast({
        position: "top-right",
        title: "Cadastro em massa",
        description: "Não foi possível processar o arquivo.",
        status: "error",
        duration: 9000,
        isClosable: true,
      });
    }
  }

  // acceptedFiles.map(async (file: any) => {
  //   try {
  //     const data = await ReportFormatters.questions(file);
  //     setDataQuestions(data);
  //   } catch (error) {
  //     toast({
  //       position: "top-right",
  //       title: "Cadastro em massa",
  //       description: "Não foi possível processar o arquivo.",
  //       status: "error",
  //       duration: 9000,
  //       isClosable: true,
  //     });
  //   }
  // });

  async function sendQuestions() {
    try {
      setLoading(true);

      const dataValue: any[] = [];

      if (dataQuestions.length > 0) {
        await dataQuestions.forEach((item, _) => {
          dataValue.push(item);
        });

        await api.post("/question/many/create", { all_data: dataValue });

        toast({
          position: "top-right",
          title: "Questão",
          description: "Questão cadastrado com sucesso.",
          status: "success",
          duration: 9000,
          isClosable: true,
        });

        window.location.reload();
      } else {
        toast({
          position: "top-right",
          title: "Questão",
          description: "Por informe uma lista de questōes",
          status: "warning",
          duration: 9000,
          isClosable: true,
        });
      }
    } catch (error) {
      toast({
        position: "top-right",
        title: "Questão",
        description: "Não foi possível cadastrar o questão.",
        status: "error",
        duration: 9000,
        isClosable: true,
      });
    } finally {
      setLoading(false);
    }
  }

  return (
    <Flex w="100%" flexDirection="column">
      {loading && <FullLoading />}
      <Flex
        style={{ flexDirection: "column" }}
        marginTop={["20px", "20px", "48px"]}
      >
        <Title title="Cadastrar Questões em massa" />

        <Flex marginTop={["20px", "20px", "60px"]}>
          <Text
            style={{
              fontFamily: "Kanit",
              color: "#5F5F5F",
              fontWeight: 300,
            }}
            width={["100%", "100%", "819px"]}
            fontSize={["15px", "15px", "16px"]}
          >
            {` Olá ${user.name}, você poderá cadastrar múltiplas questões ao mesmo
            tempo, basta criar o arquivo .csv com as perguntas e respostas.
            Baixe o template e preencha com as questões. Em seguida, realizar o
            upload.`}
          </Text>
        </Flex>

        <Flex style={{ marginTop: 30 }}>
          <Link
            style={{ textDecoration: "none" }}
            href="/documents/template.xlsx"
            download="template"
          >
            <Flex
              style={{
                backgroundColor: "#0577BE",
                width: 190,
                height: 40,
                borderRadius: 60,
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              <Text
                style={{
                  color: "#F5F5F5",
                  fontFamily: "Kanit",
                  fontWeight: 600,
                  fontSize: 16,
                  marginRight: 10,
                }}
              >
                Baixar template
              </Text>
              <Icon fontSize="16px" as={RiDownload2Fill} color="#F5F5F5" />
            </Flex>
          </Link>
        </Flex>

        <Flex
          style={{
            marginTop: 45,
            width: "100%",
            alignItems: "flex-start",
            justifyContent: "flex-start",
          }}
          flexDirection={["column", "column", "row"]}
        >
          <Flex
            style={{
              borderWidth: 1,
              borderColor: "#D9D9D9",
              height: 34,
              borderRadius: 20,
              paddingLeft: 14,
              paddingRight: 14,
              alignItems: "center",
              cursor: "pointer",
            }}
            width={["100%", "100%", "414px"]}
            onClick={open}
          >
            <Text
              style={{
                color: "#808080",
                fontSize: 16,
                fontFamily: "Kanit",
                fontWeight: 300,
              }}
            >
              Selecione seu arquivo
            </Text>
          </Flex>

          <Flex
            style={{
              backgroundColor: "#03A679",
              height: 34,
              borderRadius: 60,
              alignItems: "center",
              justifyContent: "center",
              cursor: "pointer",
            }}
            onClick={open}
            width={["100%", "100%", "182px"]}
            marginLeft={["0px", "0px", "10px"]}
            marginTop={["10px", "10px", "0px"]}
          >
            <Text
              style={{
                color: "#F5F5F5",
                fontFamily: "Kanit",
                fontWeight: 600,
                fontSize: 16,
                marginRight: 10,
              }}
            >
              Selecionar Arquivo
            </Text>
          </Flex>
        </Flex>

        <Flex
          style={{ marginTop: 20, flexDirection: "column" }}
          width={["100%", "100%", "553px"]}
        >
          <section
            style={{
              backgroundColor: "#F0F0F0",
              borderWidth: 1,
              borderColor: isFocused || isDragAccept ? "#03A679" : "#D9D9D9",
              borderRadius: 20,
              width: "100%",
              height: 120,
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <div
              {...getRootProps({
                isFocused,
                isDragAccept,
                isDragReject,
              })}
              style={{
                display: "flex",
                width: "100%",
                height: 120,
                alignItems: "center",
                justifyContent: "center",
                cursor: "pointer",
              }}
            >
              <input {...getInputProps()} />
              <p
                style={{
                  color: "#808080",
                  fontFamily: "Kanit",
                  fontSize: 16,
                }}
              >
                ou arraste e solte seu arquivo aqui
              </p>
            </div>
          </section>

          <Box>
            {dataQuestions.length > 0 && (
              <Text
                style={{
                  marginTop: 10,
                  color: "#808080",
                  fontFamily: "Kanit",
                  fontSize: 16,
                }}
              >
                Planilha carregada com sceusso.
              </Text>
            )}
            <ul>
              {dataQuestions.length === 0 &&
                dataQuestions.map((item, index) => {
                  if (index > 1) {
                    return (
                      <Box
                        key={index}
                        style={{
                          padding: 20,
                          borderWidth: 1,
                          borderColor: "#F0F0F0",
                          marginTop: 10,
                          borderRadius: 20,
                        }}
                      >
                        <Accordion defaultIndex={[index]} allowMultiple>
                          <AccordionItem border="none">
                            <AccordionButton>
                              <Box
                                as="span"
                                style={{
                                  color: "#808080",
                                  fontFamily: "Kanit",
                                  fontSize: 17,
                                }}
                                flex="1"
                                textAlign="left"
                              >
                                {`${dataQuestions[1]["enunciado"]}: ${item["enunciado"]}`}
                              </Box>
                              <AccordionIcon />
                            </AccordionButton>

                            <AccordionPanel pb={4}>
                              <List spacing={3}>
                                <ListItem
                                  style={{
                                    color: "#808080",
                                    fontFamily: "Kanit",
                                    fontSize: 16,
                                  }}
                                >
                                  <ListIcon
                                    as={RiCheckboxCircleFill}
                                    color="green.500"
                                  />
                                  Tipo: {item["type"]}
                                </ListItem>
                                <ListItem
                                  style={{
                                    color: "#808080",
                                    fontFamily: "Kanit",
                                    fontSize: 16,
                                  }}
                                >
                                  <ListIcon
                                    as={RiCheckboxCircleFill}
                                    color="green.500"
                                  />
                                  {`${dataQuestions[1]["texto"]}: ${item["texto"]}`}
                                </ListItem>
                                <ListItem
                                  style={{
                                    color: "#808080",
                                    fontFamily: "Kanit",
                                    fontSize: 16,
                                  }}
                                >
                                  <ListIcon
                                    as={RiCheckboxCircleFill}
                                    color="green.500"
                                  />
                                  {`${dataQuestions[1]["dica_01"]}: ${item["dica_01"]}`}
                                </ListItem>
                                <ListItem
                                  style={{
                                    color: "#808080",
                                    fontFamily: "Kanit",
                                    fontSize: 16,
                                  }}
                                >
                                  <ListIcon
                                    as={RiCheckboxCircleFill}
                                    color="green.500"
                                  />
                                  {`${dataQuestions[1]["dica_02"]}: ${item["dica_02"]}`}
                                </ListItem>
                                <ListItem
                                  style={{
                                    color: "#808080",
                                    fontFamily: "Kanit",
                                    fontSize: 16,
                                  }}
                                >
                                  <ListIcon
                                    as={RiCheckboxCircleFill}
                                    color="green.500"
                                  />
                                  {`${dataQuestions[1]["dica_03"]}: ${item["dica_03"]}`}
                                </ListItem>
                                <ListItem
                                  style={{
                                    color: "#808080",
                                    fontFamily: "Kanit",
                                    fontSize: 16,
                                  }}
                                >
                                  <ListIcon
                                    as={RiCheckboxCircleFill}
                                    color="green.500"
                                  />
                                  {`${dataQuestions[1]["tipo_da_questao"]}: ${item["tipo_da_questao"]}`}
                                </ListItem>
                                <ListItem
                                  style={{
                                    color: "#808080",
                                    fontFamily: "Kanit",
                                    fontSize: 16,
                                  }}
                                >
                                  <ListIcon
                                    as={RiCheckboxCircleFill}
                                    color="green.500"
                                  />
                                  {`${dataQuestions[1]["modalidade"]}: ${item["modalidade"]}`}
                                </ListItem>
                                <ListItem
                                  style={{
                                    color: "#808080",
                                    fontFamily: "Kanit",
                                    fontSize: 16,
                                  }}
                                >
                                  <ListIcon
                                    as={RiCheckboxCircleFill}
                                    color="green.500"
                                  />
                                  {`${dataQuestions[1]["gabarito"]}: ${item["gabarito"]}`}
                                </ListItem>
                                <ListItem
                                  style={{
                                    color: "#808080",
                                    fontFamily: "Kanit",
                                    fontSize: 16,
                                  }}
                                >
                                  <ListIcon
                                    as={RiCheckboxCircleFill}
                                    color="green.500"
                                  />
                                  {`${dataQuestions[1]["link_de_resposta_mais_aprofundada"]}: ${item["link_de_resposta_mais_aprofundada"]}`}
                                </ListItem>
                                <ListItem
                                  style={{
                                    color: "#808080",
                                    fontFamily: "Kanit",
                                    fontSize: 16,
                                  }}
                                >
                                  <ListIcon
                                    as={RiCheckboxCircleFill}
                                    color="green.500"
                                  />
                                  {`${dataQuestions[1]["disciplina"]}: ${item["disciplina"]}`}
                                </ListItem>
                                <ListItem
                                  style={{
                                    color: "#808080",
                                    fontFamily: "Kanit",
                                    fontSize: 16,
                                  }}
                                >
                                  <ListIcon
                                    as={RiCheckboxCircleFill}
                                    color="green.500"
                                  />
                                  {`${dataQuestions[1]["assunto"]}: ${item["assunto"]}`}
                                </ListItem>
                                <ListItem
                                  style={{
                                    color: "#808080",
                                    fontFamily: "Kanit",
                                    fontSize: 16,
                                  }}
                                >
                                  <ListIcon
                                    as={RiCheckboxCircleFill}
                                    color="green.500"
                                  />
                                  {`${dataQuestions[1]["banca"]}: ${item["banca"]}`}
                                </ListItem>
                                <ListItem
                                  style={{
                                    color: "#808080",
                                    fontFamily: "Kanit",
                                    fontSize: 16,
                                  }}
                                >
                                  <ListIcon
                                    as={RiCheckboxCircleFill}
                                    color="green.500"
                                  />
                                  {`${dataQuestions[1]["ano"]}: ${item["ano"]}`}
                                </ListItem>
                                <ListItem
                                  style={{
                                    color: "#808080",
                                    fontFamily: "Kanit",
                                    fontSize: 16,
                                  }}
                                >
                                  <ListIcon
                                    as={RiCheckboxCircleFill}
                                    color="green.500"
                                  />
                                  {`${dataQuestions[1]["tag"]}: ${item["tag"]}`}
                                </ListItem>
                                <ListItem
                                  style={{
                                    color: "#808080",
                                    fontFamily: "Kanit",
                                    fontSize: 16,
                                  }}
                                >
                                  <ListIcon
                                    as={RiCheckboxCircleFill}
                                    color="green.500"
                                  />
                                  {`${dataQuestions[1]["tempo_esperado"]}: ${item["tempo_esperado"]}`}
                                </ListItem>
                              </List>
                            </AccordionPanel>
                          </AccordionItem>
                        </Accordion>
                      </Box>
                    );
                  }

                  return <Box />;
                })}
            </ul>
          </Box>

          {dataQuestions.length > 0 && (
            <Flex
              style={{
                backgroundColor: "#03A679",
                width: 190,
                height: 40,
                borderRadius: 60,
                alignItems: "center",
                justifyContent: "center",
                alignSelf: "flex-end",
                marginTop: 25,
                marginBottom: 25,
                cursor: "pointer",
              }}
              onClick={() => {
                sendQuestions();
              }}
            >
              <Text
                style={{
                  color: "#F5F5F5",
                  fontFamily: "Kanit",
                  fontWeight: 600,
                  fontSize: 16,
                  marginRight: 10,
                }}
              >
                Enviar arquivo
              </Text>
              <Icon fontSize="16px" as={RiUploadCloud2Fill} color="#F5F5F5" />
            </Flex>
          )}
        </Flex>
      </Flex>
    </Flex>
  );
}
