import { Navigate } from "react-router-dom";
import { AuthTemplate } from "../components/authTemplate/AuthTemplate";
import { useAuth } from "../hooks/useAuth";
import { FullLoading } from "../components/fullLoading";

interface AuthRouteProps {
  children: JSX.Element;
  path?: string;
  subPath?: string;
  title?: string;
  subTitle?: string;
}

export function AuthRoute({
  children,
  title,
  subTitle,
  path,
  subPath,
}: AuthRouteProps) {
  const { isAuthenticated, isAtuhLoading } = useAuth();

  if (isAtuhLoading) {
    return <FullLoading />;
  }

  if (!isAuthenticated) {
    return <Navigate to="/login" replace />;
  }

  return (
    <AuthTemplate
      path={path}
      subPath={subPath}
      title={title}
      subTitle={subTitle}
    >
      {children}
    </AuthTemplate>
  );
}
