import { Flex, Text, useToast } from "@chakra-ui/react";
import reactStringReplace from "react-string-replace";

import { simulatorQuestionDTO } from "../../../../dtos/simulatorQuestionDTO";
import { AllLoading } from "../../../../components/allLoading";
import { ButtonTip } from "../../components/buttonTip";
import { Pagination } from "../../components/pagination";
import { useForm } from "react-hook-form";

import "./colorsRequired.css";
import { api } from "../../../../service/api";

type Props = {
  simulatorQuestion: simulatorQuestionDTO;
  onUpdate: (reply: "success" | "error" | "no_reply") => void;
};

export function QuestionBlankSpace({ simulatorQuestion, onUpdate }: Props) {
  const {
    register,
    handleSubmit,
    formState: { isSubmitting },
  } = useForm();

  const toast = useToast();

  const onSubmit = async (data: any) => {
    if (
      simulatorQuestion.simulatorQuestion.reply_blank_space &&
      simulatorQuestion.simulatorQuestion.reply_blank_space.length > 0
    ) {
      return;
    }

    var result = Object.keys(data).map((key) => [key, data[key]]);
    try {
      await api.post("/simulator/reply/blankspace", {
        all_data: result,
        simulator_question_id: simulatorQuestion.simulatorQuestion.id,
      });

      const data = {
        id: simulatorQuestion.simulatorQuestion.id,
        reply: null,
        page_question: simulatorQuestion.paginations.current,
      };
      const response = await api.post("/simulators/reply", data);

      const resultReply = response.data.reply as
        | "success"
        | "error"
        | "no_reply";

      toast({
        position: "top-right",
        title: "Simulador",
        description: "Questão enviada com sucesso.",
        status: "success",
        duration: 9000,
        isClosable: true,
      });

      onUpdate(resultReply);
    } catch (error) {
      toast({
        position: "top-right",
        title: "Questão",
        description: "Não foi possível enviar questão.",
        status: "error",
        duration: 9000,
        isClosable: true,
      });
    }
  };

  function formatNumber(quantity: number | null) {
    if (quantity && quantity > 0) {
      let newQuantity = quantity > 9 ? quantity : `0${quantity}`;
      return newQuantity;
    } else {
      return 0;
    }
  }

  return (
    <Flex
      style={{ flexDirection: "column" }}
      as="form"
      onSubmit={handleSubmit(onSubmit)}
    >
      {isSubmitting && <AllLoading />}
      <Flex style={{ flexDirection: "row" }}>
        <Text
          style={{
            fontSize: 16,
            fontWeight: 600,
            color: "#0577BE",
            fontFamily: "Kanit",
          }}
        >
          {formatNumber(simulatorQuestion.simulatorQuestion.numer_question)}
        </Text>

        <Text
          style={{
            fontSize: 16,
            fontWeight: 300,
            fontFamily: "Kanit",
            marginLeft: 16,
          }}
          width={["100%", "100%", "1071px"]}
        >
          {simulatorQuestion.simulatorQuestion.question.enunciation}
        </Text>
      </Flex>

      <Flex
        style={{
          fontSize: 16,
          fontWeight: 300,
          fontFamily: "Kanit",
          marginLeft: 16,
          color: "#343434",
          marginTop: 30,
          alignItems: "center",
        }}
        width={["100%", "100%", "1071px"]}
      >
        {simulatorQuestion.simulatorQuestion.reply_blank_space.length === 0 && (
          <Text style={{ lineHeight: "40px" }}>
            {reactStringReplace(
              simulatorQuestion.simulatorQuestion.question.text,
              "{{resposta}}",
              (match, i) => (
                <input
                  key={`name-${i}`}
                  style={{
                    width: "auto",
                    height: 35,
                    borderColor: "#0577BE",
                    borderWidth: 1,
                    borderRadius: 5,
                    padding: 5,
                    marginLeft: 2,
                    marginRight: 2,
                  }}
                  autoComplete="off"
                  {...register(`name-${i}`, { required: true })}
                />
              )
            )}
          </Text>
        )}

        {simulatorQuestion.simulatorQuestion.reply_blank_space &&
          simulatorQuestion.simulatorQuestion.reply_blank_space.length > 0 && (
            <Text>
              {reactStringReplace(
                simulatorQuestion.simulatorQuestion.question.text,
                "{{resposta}}",
                (match, i) => (
                  <input
                    key={`name-${i}`}
                    style={{
                      width: "auto",
                      height: 35,
                      borderColor: "#0577BE",
                      borderWidth: 1,
                      borderRadius: 5,
                      padding: 5,
                      marginLeft: 2,
                      marginRight: 2,
                      textAlign: "center",
                    }}
                    disabled
                    value={
                      simulatorQuestion.simulatorQuestion.reply_blank_space?.find(
                        (item) => item.name === `name-${i}`
                      )?.description
                    }
                  />
                )
              )}
            </Text>
          )}
      </Flex>

      <Flex
        style={{
          marginTop: 25,
          flexDirection: "column",
          alignItems: "flex-start",
          justifyContent: "flex-start",
        }}
      >
        <Flex
          style={{
            marginTop: 30,
            alignItems: "center",
            justifyContent: "space-between",
          }}
          width={["100%", "100%", "1250px"]}
          flexDirection={["column", "column", "row"]}
        >
          <Flex
            style={{ gap: 20 }}
            width={["100%", "100%", "auto"]}
            flexDirection={["column", "column", "row"]}
          >
            <Flex width={["100%", "100%", "134px"]}>
              <input
                style={{
                  height: "49px",
                  cursor: "pointer",
                  backgroundColor: "#0577BE",
                  borderRadius: 10,
                  alignItems: "center",
                  justifyContent: "center",
                  color: "#fff",
                  fontSize: 16,
                  fontFamily: "Kanit",
                  fontWeight: 500,
                  width: "100%",
                }}
                type="submit"
                value="Responder"
              />
            </Flex>

            {simulatorQuestion.simulatorQuestion.question.answer_tips.length >
              0 && (
              <ButtonTip
                answerTips={
                  simulatorQuestion.simulatorQuestion.question.answer_tips
                }
                onClick={() => {}}
              />
            )}
          </Flex>

          <Flex marginTop={["20px", "20px", "0px"]}>
            <Pagination
              next={simulatorQuestion.paginations.next}
              previous={simulatorQuestion.paginations.previous}
              total={simulatorQuestion.paginations.total}
              current={simulatorQuestion.paginations.current}
            />
          </Flex>
        </Flex>
      </Flex>
    </Flex>
  );
}
