import {
  Button,
  Divider,
  Flex,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalHeader,
  ModalOverlay,
  Spinner,
  Text,
} from "@chakra-ui/react";
import { simulatorQuestionDTO } from "../../../../../dtos/simulatorQuestionDTO";
import { differenceBetweenHours } from "../../../../../utils/utils";
import { useSimulatorUser } from "../../../hooks/useSimulatorUser";
import { useEffect, useState } from "react";
import { SimulatorUserDTO } from "../../../../../dtos/SimulatorUserDTO";
import { api } from "../../../../../service/api";
import { DashSimulator } from "../../../components/dashSimulator";

const typesReplication: {
  title: string;
  type:
    | "duplicate"
    | "duplicate_errors"
    | "duplicate_favorite"
    | "duplicate_errors_favorite";
}[] = [
  {
    title: "Refazer simulado",
    type: "duplicate",
  },
  {
    title: "Refazer Erradas",
    type: "duplicate_errors",
  },
  {
    title: "Refazer Marcadas",
    type: "duplicate_favorite",
  },
  {
    title: "Refazer Erradas e Marcadas",
    type: "duplicate_errors_favorite",
  },
];

type Props = {
  id: string;
  data: simulatorQuestionDTO;
  is_open: boolean;
  isDash: boolean;
  onClose: () => void;
};
export function FinishSimulatorModal({
  id,
  data,
  is_open,
  onClose,
  isDash,
  ...rest
}: Props) {
  const { dataList, loading, refetch } = useSimulatorUser();
  const [loadingDuplicate, setLoadingDuplicate] = useState(false);

  useEffect(() => {
    if (data.simulator.progress === "finished") {
      refetch(data.simulator.id);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data]);

  function openHerf() {
    if (data.simulator.status === "finished") {
      window.location.href = "/simuladores";
    } else {
      onClose();
    }
  }

  function activeButton(
    item: SimulatorUserDTO[],
    type:
      | "duplicate"
      | "duplicate_errors"
      | "duplicate_favorite"
      | "duplicate_errors_favorite"
  ) {
    if (type === "duplicate") return true;

    if (
      type === "duplicate_errors" &&
      item.find((p) => p.reply === "error")?.id
    )
      return true;
    if (
      type === "duplicate_favorite" &&
      item.find((p) => p.favorite === true)?.id
    )
      return true;
    if (
      type === "duplicate_errors_favorite" &&
      item.find((p) => p.favorite === true && p.reply === "error")?.id
    )
      return true;

    return false;
  }

  async function sendDuplication(id: string, type: string) {
    try {
      setLoadingDuplicate(true);
      const response = await api.post(`simulator/duplication/user/${id}`, {
        type,
      });
      window.location.href = `/simuladores/questao/${response.data.id}?page_question=1`;
    } catch (error) {
    } finally {
      setLoadingDuplicate(false);
    }
  }

  return (
    <>
      <Modal
        motionPreset="slideInBottom"
        isOpen={is_open}
        onClose={() => {
          openHerf();
        }}
        isCentered
        closeOnOverlayClick={false}
        {...rest}
        size="xl"
      >
        <ModalOverlay />
        <ModalContent display="flex" flexDir="column">
          <ModalHeader
            textAlign="center"
            style={{
              color: "#03A679",
              fontSize: 16,
              fontFamily: "Kanit",
              fontWeight: 600,
            }}
          >
            Resultado do simulado
          </ModalHeader>
          <ModalCloseButton />

          <ModalBody pb={10}>
            {loading && (
              <Flex
                style={{
                  height: 200,
                  alignItems: "center",
                  justifyContent: "center",
                }}
                width={["100%", "100%", "400px"]}
              >
                <Spinner
                  color="green.600"
                  emptyColor="white"
                  thickness="4px"
                  size="xl"
                  h={100}
                  w={100}
                />
              </Flex>
            )}
            {!loading && dataList && dataList.length > 0 && (
              <Flex
                style={{
                  alignItems: "center",
                  justifyContent: "center",
                  gap: 10,
                }}
                flexDirection={["column", "column", "column"]}
              >
                <Flex>
                  <Text
                    style={{
                      color: "#03A679",
                      fontSize: 16,
                      fontFamily: "Kanit",
                      fontWeight: 400,
                      marginLeft: 5,
                    }}
                  >
                    Tempo do simulado:
                  </Text>
                  <Text
                    style={{
                      color: "#232323",
                      fontSize: 16,
                      fontFamily: "Kanit",
                      fontWeight: 600,
                      marginLeft: 5,
                    }}
                  >
                    {differenceBetweenHours(
                      data.simulator.date_started,
                      data.simulator.date_finished
                    )}
                  </Text>
                </Flex>
                <Flex mt={2}>
                  <Text
                    style={{
                      color: "#03A679",
                      fontSize: 16,
                      fontFamily: "Kanit",
                      fontWeight: 400,
                      marginLeft: 5,
                    }}
                  >
                    Quantidade de acertos:
                  </Text>
                  <Text
                    style={{
                      color: "#232323",
                      fontSize: 16,
                      fontFamily: "Kanit",
                      fontWeight: 600,
                      marginLeft: 5,
                    }}
                  >
                    {dataList.filter((item) => item.reply === "success").length}
                  </Text>
                </Flex>
                <Flex mt={2}>
                  <Text
                    style={{
                      color: "#03A679",
                      fontSize: 16,
                      fontFamily: "Kanit",
                      fontWeight: 400,
                      marginLeft: 5,
                    }}
                  >
                    Quantidade de erros:
                  </Text>
                  <Text
                    style={{
                      color: "#232323",
                      fontSize: 16,
                      fontFamily: "Kanit",
                      fontWeight: 600,
                      marginLeft: 5,
                    }}
                  >
                    {dataList.filter((item) => item.reply === "error").length}
                  </Text>
                </Flex>
                <Flex mt={2}>
                  <Text
                    style={{
                      color: "#03A679",
                      fontSize: 16,
                      fontFamily: "Kanit",
                      fontWeight: 400,
                      marginLeft: 5,
                    }}
                  >
                    Não respondidas:
                  </Text>
                  <Text
                    style={{
                      color: "#232323",
                      fontSize: 16,
                      fontFamily: "Kanit",
                      fontWeight: 600,
                      marginLeft: 5,
                    }}
                  >
                    {
                      dataList.filter((item) => item.reply === "no_reply")
                        .length
                    }
                  </Text>
                </Flex>

                <Flex mt={2}>
                  <Text
                    style={{
                      color: "#03A679",
                      fontSize: 16,
                      fontFamily: "Kanit",
                      fontWeight: 400,
                      marginLeft: 5,
                    }}
                  >
                    Quantidade Marcadas:
                  </Text>
                  <Text
                    style={{
                      color: "#232323",
                      fontSize: 16,
                      fontFamily: "Kanit",
                      fontWeight: 600,
                      marginLeft: 5,
                    }}
                  >
                    {dataList.filter((item) => item.favorite === true).length}
                  </Text>
                </Flex>

                {isDash && (
                  <>
                    <Divider />

                    <DashSimulator
                      totalSuccess={
                        dataList.filter((item) => item.reply === "success")
                          .length
                      }
                      totalError={
                        dataList.filter((item) => item.reply === "error").length
                      }
                      totalNoReply={
                        dataList.filter((item) => item.reply === "no_reply")
                          .length
                      }
                    />
                    <Divider />
                  </>
                )}

                <Button
                  type="submit"
                  bg="green.500"
                  color="white"
                  style={{
                    borderRadius: 53,
                    height: 44,
                    fontSize: 16,
                    fontWeight: 600,
                  }}
                  _hover={{
                    bg: "green.500",
                  }}
                  mt="6"
                  size="lg"
                  onClick={() => {
                    openHerf();
                  }}
                >
                  Sair do simulado
                </Button>

                {!isDash && (
                  <>
                    <Divider />

                    <Flex flexDirection="column" mt="5px" gap="5px">
                      {typesReplication.map((item, _) => {
                        return (
                          <Button
                            isDisabled={!activeButton(dataList, item.type)}
                            key={item.type}
                            type="button"
                            variant="outline"
                            colorScheme="teal"
                            style={{
                              borderRadius: 53,
                              height: 44,
                              fontSize: 16,
                              fontWeight: 600,
                            }}
                            size="lg"
                            isLoading={loadingDuplicate}
                            onClick={() => {
                              sendDuplication(id, item.type);
                            }}
                          >
                            {item.title}
                          </Button>
                        );
                      })}
                    </Flex>
                  </>
                )}
              </Flex>
            )}
          </ModalBody>
        </ModalContent>
      </Modal>
    </>
  );
}
