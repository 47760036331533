import { ReactNode, createContext, useEffect, useState } from "react";
import { Cookies } from "react-cookie-consent";
import { api } from "../service/api";

type UserDto = {
  id: string;
  name: string;
  email: string;
  type: string;
  image: string;
  is_pay: boolean;
  link_pay: string;
};

type SignInCredentias = {
  email: string;
  password: string;
  isCreateToken?: boolean;
};

type AuthContextProviderProps = {
  children: ReactNode;
};

type AuthContextData = {
  signIn(credentias: SignInCredentias): Promise<UserDto>;
  logout(): void;
  user: UserDto;
  isAuthenticated: boolean;
  isAtuhLoading: boolean;
};

export const AuthContext = createContext({} as AuthContextData);

export function AuthProvider({ children }: AuthContextProviderProps) {
  const [user, setUser] = useState<UserDto>({} as UserDto);
  const [isAtuhLoading, setIsAtuhLoading] = useState(true);

  const isAuthenticated = !!user.id;

  async function signIn({
    email,
    password,
    isCreateToken,
  }: SignInCredentias): Promise<UserDto> {
    try {
      const response = await api.post("login", {
        email: email,
        password: password,
        device_name: "tm-" + email,
      });

      const token = response.data.token;

      if (isCreateToken) {
        Cookies.set("tm.token", token, {
          expires: 7,
        });
      } else {
        Cookies.set("tm.token", token);
      }

      api.defaults.headers["Authorization"] = `Bearer ${token}`;

      setUser(response.data);
      return response.data;
    } catch (error) {
      throw error;
    }
  }

  function logout() {
    Cookies.remove("tm.token");
    Cookies.remove("tm.token.active");
    setUser({} as UserDto);
  }

  async function getUser() {
    const token = Cookies.get("tm.token");
    // const tokenActive = Cookies.get("tm.token.active");
    if (token && !user.id) {
      setIsAtuhLoading(true);
      const response = await api.get("user/show");
      setUser(response.data);
    }
    setIsAtuhLoading(false);
  }

  useEffect(() => {
    getUser();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <AuthContext.Provider
      value={{ signIn, isAuthenticated, user, isAtuhLoading, logout }}
    >
      {children}
    </AuthContext.Provider>
  );
}
