import { useState } from "react";
import { SelectTypeDTO } from "../../../dtos/SelectTypeDTO";
import { IDisciplineData } from "..";
import { mapDiciplineObject } from "../../../utils/mapDiciplineObject";
import { api } from "../../../service/api";
import { typeQuestionBr } from "../../../utils/utils";

interface IContentType {
  diciplineSelected: IDisciplineData[];
  jury: string;
  modality: string;
  year: string;
  timer: string;
}

export function useListContentType() {
  const [loadingContentType, setLoadingContentType] = useState(false);
  const [error, setError] = useState<boolean | null>(null);
  const [dataLisContentType, setDataLisContentType] = useState<SelectTypeDTO[]>(
    []
  );

  const fetchData = async ({
    diciplineSelected,
    jury,
    modality,
    year,
    timer,
  }: IContentType) => {
    try {
      const filterDicipline = mapDiciplineObject(diciplineSelected);
      setDataLisContentType([]);
      setLoadingContentType(true);
      const path = `/simulator/list/filter/contenttype?disciplines_subjects=${filterDicipline}&jury=${jury}&modality=${modality}&year=${year}&timer=${timer}`;
      const response = await api.get(path);

      const data: SelectTypeDTO[] = [];

      response.data.forEach((element: any) => {
        data.push({
          label: typeQuestionBr(element.content_type),
          value: element.content_type,
        });
      });

      setDataLisContentType(data);
    } catch (error) {
      setError(true);
    } finally {
      setLoadingContentType(false);
    }
  };

  return {
    dataLisContentType,
    loadingContentType,
    error,
    refetchContentType: fetchData,
  };
}
