import { Button, Flex, Text, useToast } from "@chakra-ui/react";
import { Title } from "../../../components/Title";
import { HeaderQuestions } from "../components/header";
import { QuestionTrueOrFalse } from "../typeQuestions/questionTrueOrFalse";
import { QuestionMultipleChoice } from "../typeQuestions/questionMultipleChoice";
import { useParams, useSearchParams } from "react-router-dom";
import { useShowSimulator } from "../hooks/useShowSimulator";
import { FullLoading } from "../../../components/fullLoading";
import { Comments } from "../comments";
import { QuestionBlankSpace } from "../typeQuestions/questionBlankSpace";
import { FinishSimulatorModal } from "./modal/finishSimulatorModal";
import { useState } from "react";
import { api } from "../../../service/api";
import { AnswerToQuestion } from "./modal/answerToQuestion";
import { MessageQuestion } from "./modal/messageQuestion";

export function SimulatorQuestion() {
  let { id } = useParams();

  const toast = useToast();

  const [, setSearchParams] = useSearchParams();

  const [loadingFinash, setLoadingFinash] = useState(false);
  const [isModalQuestion, setIsModalQuestion] = useState(false);
  const [isDash, setIsDash] = useState(false);
  const [finishSimulatorModalOpen, setFinishSimulatorModalOpen] =
    useState(false);

  const [isOpenMessageQuestion, setIsOpenMessageQuestion] = useState(false);
  const [messageQuestionReply, setMessageQuestionReply] = useState<
    "success" | "error" | "no_reply"
  >("no_reply");

  const { data, loading, error, refetch } = useShowSimulator(id as string);

  function formatNumer(quantity: number | null) {
    if (quantity && quantity > 0) {
      let newQuantity = quantity > 9 ? quantity : `0${quantity}`;
      return newQuantity;
    } else {
      return 0;
    }
  }

  function handleFilter(pageQuestion: string) {
    setSearchParams((state) => {
      if (pageQuestion) {
        state.set("page_question", pageQuestion);
      } else {
        state.delete("page_question");
      }

      return state;
    });
  }

  async function finashSimulator() {
    try {
      setLoadingFinash(true);
      await api.post("simulator/finished", {
        Simulator_id: id,
        type: "status",
      });
      toast({
        position: "top-right",
        title: "Simulador",
        description: "Simulador finalizado com sucesso.",
        status: "success",
        duration: 9000,
        isClosable: true,
      });
      refetch();
    } catch (error) {
      toast({
        position: "top-right",
        title: "Simulador",
        description: "Não foi finalizar o simulador.",
        status: "error",
        duration: 9000,
        isClosable: true,
      });
    } finally {
      setLoadingFinash(false);
    }
  }

  return (
    <Flex w="100%" flexDirection="column">
      {(loading || loadingFinash) && <FullLoading />}

      {!loading &&
        !error &&
        data &&
        data.simulator &&
        data.simulatorQuestion.question &&
        data.simulator.id && (
          <>
            <MessageQuestion
              is_open={isOpenMessageQuestion}
              onClose={() => {
                setIsOpenMessageQuestion(false);
                setMessageQuestionReply("no_reply");
                setIsModalQuestion(true);
              }}
              reply={messageQuestionReply}
            />

            <FinishSimulatorModal
              isDash={isDash}
              id={data.simulator.id}
              data={data}
              is_open={
                data.simulator.status === "finished" || finishSimulatorModalOpen
              }
              onClose={() => {
                setFinishSimulatorModalOpen(false);
                setIsDash(false);
              }}
            />

            <AnswerToQuestion
              data={data}
              is_open={isModalQuestion}
              onClose={() => {
                setIsModalQuestion(false);
              }}
            />

            <Flex style={{ marginTop: 48, flexDirection: "column" }}>
              <Title title={data.simulator.title} />
            </Flex>
            <Flex style={{ marginTop: 17 }} width={["100%", "100%", "auto"]}>
              <HeaderQuestions
                onCancel={() => {
                  finashSimulator();
                }}
                progress={data.simulator.progress}
                questionCurrent={formatNumer(data.paginations.total).toString()}
                questionActual={formatNumer(
                  data.paginations.current
                ).toString()}
                timerQuestion={data.simulatorQuestion.question.time_in_seconds}
                id={data.simulator.id}
                simulatorQuestionId={data.simulatorQuestion.id}
                favorite={data.simulatorQuestion.favorite}
                onOpen={(item) => {
                  if (item === "detail") {
                    setFinishSimulatorModalOpen(true);
                  }

                  if (item === "performance") {
                    setIsDash(true);
                    setFinishSimulatorModalOpen(true);
                  }
                }}
                onUpdate={() => {
                  refetch();
                }}
              />
            </Flex>
            <Flex style={{ marginTop: 30 }}>
              {data.simulatorQuestion.question.content_type ===
                "true_or_false" && (
                <QuestionTrueOrFalse
                  simulatorQuestion={data}
                  onUpdate={(reply) => {
                    setIsOpenMessageQuestion(true);
                    setMessageQuestionReply(reply);
                    refetch();
                  }}
                />
              )}
              {data.simulatorQuestion.question.content_type ===
                "multiple_choice" && (
                <QuestionMultipleChoice
                  simulatorQuestion={data}
                  onUpdate={(reply) => {
                    setIsOpenMessageQuestion(true);
                    setMessageQuestionReply(reply);
                    refetch();
                  }}
                />
              )}

              {data.simulatorQuestion.question.content_type ===
                "blank_space" && (
                <QuestionBlankSpace
                  simulatorQuestion={data}
                  onUpdate={(reply) => {
                    setIsOpenMessageQuestion(true);
                    setMessageQuestionReply(reply);
                    refetch();
                  }}
                />
              )}
            </Flex>

            <Flex
              style={{
                marginTop: 30,
                width: "100%",
                borderTopWidth: 5,
                borderColor: "#D9D9D9",
              }}
              maxWidth={["100%", "100%", "1300px"]}
            >
              <Flex style={{ marginTop: 30 }} width={["100%", "100%", "auto"]}>
                <Comments
                  question_id={data.simulatorQuestion.question_id}
                  onOpen={() => {
                    setIsModalQuestion(true);
                  }}
                />
              </Flex>
            </Flex>
          </>
        )}

      {!loading &&
        !error &&
        data &&
        (!data.simulator ||
          !data.simulatorQuestion.question ||
          !data.simulator.id) && (
          <Flex gap="10px" marginTop="10px" flexDirection="column">
            <Text>Questão não encontrada</Text>
            <Button
              colorScheme="teal"
              variant="outline"
              onClick={() => {
                if (data && data.paginations && data.paginations.next) {
                  handleFilter(data.paginations.next.toString());
                }
              }}
            >
              Tentar novamente
            </Button>
          </Flex>
        )}
    </Flex>
  );
}
