import { Flex } from "@chakra-ui/react";
import ReactApexChart from "react-apexcharts";
import { ApexOptions } from "apexcharts";

type Props = {
  totalSuccess: number;
  totalError: number;
  totalNoReply: number;
};

export function DashSimulator({
  totalSuccess,
  totalNoReply,
  totalError,
}: Props) {
  return (
    <Flex w="100%" flexDirection="column" marginBottom="100px">
      <Flex style={{ flexDirection: "column" }}>
        <Flex
          style={{
            flexDirection: "column",
            margin: "auto",
          }}
        >
          <Flex
            marginTop={["50px", "50px", "20px"]}
            width="100%"
            height={["100%", "100%", "100%"]}
            flexDirection={["column", "column", "row"]}
            gap={[10, 10, 100]}
          >
            <Flex
              width={["100%", "100%", "100%"]}
              height={["100%", "100%", "100%"]}
            >
              <Flex display={["none", "none", "flex"]}>
                <ReactApexChart
                  options={getOptions()}
                  series={
                    dataSeries(totalSuccess, totalError, totalNoReply).series
                  }
                  type="bar"
                  height={"100%"}
                  width={"100%"}
                />
              </Flex>
              <Flex display={["flex", "flex", "none"]}>
                <ReactApexChart
                  options={getOptions()}
                  series={
                    dataSeries(totalSuccess, totalError, totalNoReply).series
                  }
                  type="bar"
                  height={"100%"}
                  width={"100%"}
                />
              </Flex>
            </Flex>
          </Flex>
        </Flex>
      </Flex>
    </Flex>
  );
}

function dataSeries(n1: number, n2: number, n3: number) {
  const data = {
    series: [
      {
        name: "Quantidade",
        data: [n1, n2, n3],
      },
    ],
  };

  return data;
}

function getOptions() {
  const options: ApexOptions = {
    chart: {
      type: "bar",
      toolbar: {
        show: false,
      },
      zoom: {
        enabled: false,
      },
    },
    colors: ["#0577BE"],
    plotOptions: {
      bar: {
        borderRadius: 10,
        dataLabels: {
          position: "top", // top, center, bottom
        },
      },
    },
    dataLabels: {
      enabled: true,
      offsetY: -20,
      style: {
        fontSize: "12px",
        colors: ["#304758"],
      },
    },

    xaxis: {
      categories: ["Acerto", "Erros", "Não iniciadas"],
      position: "bottom",
      axisBorder: {
        show: false,
      },
      axisTicks: {
        show: false,
      },
      crosshairs: {
        fill: {
          type: "gradient",
          gradient: {
            colorFrom: "#D8E3F0",
            colorTo: "#BED1E6",
            stops: [0, 100],
            opacityFrom: 0.4,
            opacityTo: 0.5,
          },
        },
      },
      tooltip: {
        enabled: false,
      },
    },
    yaxis: {
      axisBorder: {
        show: false,
      },
      axisTicks: {
        show: false,
      },
      labels: {
        show: false,
      },
    },
  };
  return options;
}
