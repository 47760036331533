import { useState } from "react";
import { SelectTypeDTO } from "../../../dtos/SelectTypeDTO";
import { IDisciplineData } from "..";
import { mapDiciplineObject } from "../../../utils/mapDiciplineObject";
import { api } from "../../../service/api";

interface IModality {
  diciplineSelected: IDisciplineData[];
  jury: string;
}

export function useListModality() {
  const [loadingModality, setLoadingModality] = useState(false);
  const [error, setError] = useState<boolean | null>(null);
  const [dataLisModality, setDataLisModality] = useState<SelectTypeDTO[]>([]);

  const fetchData = async ({ diciplineSelected, jury }: IModality) => {
    try {
      const filterDicipline = mapDiciplineObject(diciplineSelected);
      setDataLisModality([]);
      setLoadingModality(true);
      const path = `/simulator/list/filter/modality?disciplines_subjects=${filterDicipline}&jury=${jury}`;
      const response = await api.get(path);

      const data: SelectTypeDTO[] = [];

      response.data.forEach((element: any) => {
        data.push({
          label: element.modality,
          value: element.modality,
        });
      });

      setDataLisModality(data);
    } catch (error) {
      setError(true);
    } finally {
      setLoadingModality(false);
    }
  };

  return {
    dataLisModality,
    loadingModality,
    error,
    refetchModality: fetchData,
  };
}
