import axios from "axios";
import { Cookies } from "react-cookie-consent";

const token = Cookies.get("tm.token");
//http://0.0.0.0:8000
//https://api.truementory.com.br/api
export const api = axios.create({
  baseURL: "https://api.truementory.com.br/api",
  headers: {
    Authorization: `Bearer ${token}`,
  },
});
