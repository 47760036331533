import { IDisciplineData } from "..";
import { mapDiciplineObject } from "../../../utils/mapDiciplineObject";
import { api } from "../../../service/api";

interface IQuestions {
  diciplineSelected: IDisciplineData[];
  jury: string;
  modality: string;
  year: string;
  timer: string;
  content_type?: string;
}

export function useListQuestions() {
  const fetchData = async ({
    diciplineSelected,
    jury,
    modality,
    year,
    timer,
    content_type,
  }: IQuestions) => {
    try {
      const filterDicipline = mapDiciplineObject(diciplineSelected);
      const path = `/simulator/list/questions?disciplines_subjects=${filterDicipline}&jury=${jury}&modality=${modality}&year=${year}&timer=${timer}&content_type=${content_type}`;
      const response = await api.get(path);
      return response.data;
    } catch (error) {
      throw error;
    } finally {
    }
  };

  return {
    refetchQuestions: fetchData,
  };
}
