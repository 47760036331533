import {
  Button,
  Icon,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalHeader,
  ModalOverlay,
  useToast,
} from "@chakra-ui/react";
import { InputBox } from "../../../../components/form/inputBox";
import { useState } from "react";
import { RiSaveLine } from "react-icons/ri";
import { api } from "../../../../service/api";

type Props = {
  id: string;
  is_open: boolean;
  onClose: () => void;
  onCloseSend: () => void;
};
export function ModalCreateComment({
  is_open,
  onClose,
  onCloseSend,
  id,
  ...rest
}: Props) {
  const [value, setValue] = useState("");
  const [loading, setLoading] = useState(false);
  const toast = useToast();

  async function createComment() {
    try {
      if (!value) {
        throw new Error("");
      }

      const data = {
        question_id: id,
        description: value,
      };
      setLoading(true);
      await api.post("/comment/create", data);
      toast({
        position: "top-right",
        title: "Simulador",
        description: "Comentário criado com sucesso.",
        status: "success",
        duration: 9000,
        isClosable: true,
      });
      setValue("");
      onCloseSend();
    } catch (error) {
      toast({
        position: "top-right",
        title: "Simulador",
        description: "Não foi possivel criar o comentário.",
        status: "error",
        duration: 9000,
        isClosable: true,
      });
    } finally {
      setLoading(false);
    }
  }

  return (
    <>
      <Modal
        motionPreset="slideInBottom"
        isOpen={is_open}
        onClose={onClose}
        isCentered
        {...rest}
        size="4xl"
      >
        <ModalOverlay />
        <ModalContent display="flex" flexDir="column">
          <ModalHeader
            textAlign="left"
            style={{
              fontSize: 16,
              fontFamily: "Kanit",
              fontWeight: 600,
            }}
          >
            Adicionar comentário
          </ModalHeader>
          <ModalCloseButton />
          <ModalBody pb={10}>
            <InputBox
              label="Informe o comentário"
              placeholder="comentário..."
              name="comment"
              value={value}
              onChange={(e) => {
                setValue(e.target.value);
              }}
            />
            <Button
              type="button"
              bg="green.500"
              color="white"
              isLoading={loading}
              style={{
                borderRadius: 53,
                height: 44,
                fontSize: 16,
                fontWeight: 600,
              }}
              _hover={{
                bg: "green.500",
              }}
              mt="6"
              size="lg"
              onClick={() => {
                createComment();
              }}
              rightIcon={<Icon fontSize="20px" as={RiSaveLine} color="#fff" />}
            >
              Salvar
            </Button>
          </ModalBody>
        </ModalContent>
      </Modal>
    </>
  );
}
