import { useEffect, useState } from "react";
import { api } from "../../../service/api";
import { useSearchParams } from "react-router-dom";
import { ListQuestionsDTO } from "../../../dtos/ListQuestionsDTO";

export type DataPageTypes = {
  current_page: number;
  per_page: number;
  last_page: number;
  to: number;
  total: number;
};

export function useListQuestions() {
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState<boolean | null>(null);
  const [dataList, setDataList] = useState<ListQuestionsDTO[]>([]);
  const [dataPage, setDataPage] = useState<DataPageTypes>({} as DataPageTypes);

  const [searchParams] = useSearchParams();
  const pagePuestion = searchParams.get("page_question") ?? "";
  const search = searchParams.get("search") ?? "";

  const fetchData = async () => {
    try {
      setLoading(true);
      let newPagePuestion = search ? 1 : pagePuestion;
      const response = await api.get(
        `/question/list?page=${newPagePuestion}&search=${search}`
      );
      setDataList(response.data.data);
      setDataPage(response.data);
    } catch (error) {
      setError(true);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pagePuestion, search]);

  return {
    dataList,
    dataPage,
    loading,
    error,
    refetch: fetchData,
  };
}
