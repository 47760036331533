import { Flex, Icon, Text } from "@chakra-ui/react";
import { RiCheckboxCircleFill, RiCloseCircleLine } from "react-icons/ri";

type Props = {
  type: "success" | "error";
  correct_alternative: string;
  content_type: "multiple_choice" | "true_or_false" | "blank_space";
};

export function BoxResult({ type, content_type, correct_alternative }: Props) {
  return (
    <Flex
      style={{
        height: "49px",
        minWidth: "300px",
        cursor: "pointer",
        backgroundColor: "#FFFFFF",
        borderRadius: 10,
        alignItems: "center",
        justifyContent: "center",
        borderWidth: 1,
        borderColor: "#D9D9D9",
        paddingLeft: 15,
        paddingRight: 15,
      }}
    >
      <Text
        style={{
          color: type === "success" ? "#03A679" : "#FF7C7C",
          fontSize: 16,
          fontFamily: "Kanit",
          fontWeight: 500,
          marginRight: 30,
        }}
      >
        {type === "success" ? "Correta!" : "Errada!"}
      </Text>
      <Text
        style={{
          color: "#232323",
          fontSize: 16,
          fontFamily: "Kanit",
          fontWeight: 500,
          marginRight: 5,
        }}
      >
        Opção correta:
      </Text>

      {content_type === "true_or_false" && (
        <Flex
          style={{
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <Text
            style={{
              color: "#818181",
              fontSize: 16,
              fontFamily: "Kanit",
              fontWeight: 500,
              marginRight: 5,
            }}
          >
            {correct_alternative}
          </Text>
          <Icon
            fontSize="16px"
            as={type === "success" ? RiCheckboxCircleFill : RiCloseCircleLine}
            color={type === "success" ? "#03A679" : "#FF7C7C"}
          />
        </Flex>
      )}

      {content_type === "multiple_choice" && (
        <Flex
          style={{
            alignItems: "center",
            alignContent: "center",
            justifyContent: "center",
            width: 20,
            height: 20,
            borderRadius: 10,
            borderWidth: 1,
            borderColor: "#03A679",
          }}
        >
          <Text
            style={{
              color: "#03A679",
              fontSize: 16,
              fontFamily: "Kanit",
              fontWeight: 500,
              textAlign: "center",
            }}
          >
            {correct_alternative}
          </Text>
        </Flex>
      )}
    </Flex>
  );
}
