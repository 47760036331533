import { useState } from "react";
import { api } from "../../../../service/api";
import { CategoriesQuestionDTO } from "../../../../dtos/CategoriesQuestionDTO";

export function useListCategories() {
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState<boolean | null>(null);
  const [dataList, setDataList] = useState<CategoriesQuestionDTO[]>([]);

  const fetchData = async (
    type: string,
    subCategory?: string
  ): Promise<CategoriesQuestionDTO[] | null> => {
    try {
      setLoading(true);
      const response = await api.get(
        `category/question/list/${type}?sub_category=${subCategory ?? ""}`
      );
      setDataList(response.data);
      return response.data;
    } catch (error) {
      setError(true);
      return null;
    } finally {
      setLoading(false);
    }
  };

  return {
    dataList,
    loading,
    error,
    refetch: fetchData,
  };
}
