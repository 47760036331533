import { useState } from "react";
import { api } from "../../../service/api";
import { SelectTypeDTO } from "../../../dtos/SelectTypeDTO";

export function useListSubjects() {
  const [loadingSubject, setLoadingSubject] = useState(false);
  const [error, setError] = useState<boolean | null>(null);
  const [dataListSubject, setDataListSubject] = useState<SelectTypeDTO[]>([]);

  const fetchData = async (diciplineSelected: string) => {
    try {
      setDataListSubject([]);
      setLoadingSubject(true);
      const path = `/simulator/list/filter/subject/${diciplineSelected}`;
      const response = await api.get(path);

      const data: SelectTypeDTO[] = [];

      response.data.forEach((element: any) => {
        data.push({
          label: element.subject,
          value: element.subject,
        });
      });

      setDataListSubject(data);
    } catch (error) {
      setError(true);
    } finally {
      setLoadingSubject(false);
    }
  };

  return {
    dataListSubject,
    loadingSubject,
    error,
    refetchSubject: fetchData,
  };
}
