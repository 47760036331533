import {
  AlertDialog,
  AlertDialogBody,
  AlertDialogCloseButton,
  AlertDialogContent,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogOverlay,
  Button,
  Checkbox,
  Flex,
  Icon,
  IconButton,
  Menu,
  MenuButton,
  MenuItem,
  MenuList,
  Table,
  TableContainer,
  Tbody,
  Td,
  Th,
  Thead,
  Tr,
  useDisclosure,
  useToast,
} from "@chakra-ui/react";
import { Title } from "../../../components/Title";
import { Pagination } from "../../simulators/components/pagination";
import {
  RiArrowRightFill,
  RiDeleteBin2Line,
  RiEdit2Fill,
  RiFilter2Line,
  RiSettings3Line,
} from "react-icons/ri";
import { useListQuestions } from "../hooks/useListQuestions";
import { AllLoading } from "../../../components/allLoading";
import { lmWord, typeQuestionBr } from "../../../utils/utils";
import { useRef, useState } from "react";
import { api } from "../../../service/api";
import { InputBox } from "../../../components/form/inputBox";
import { useSearchParams } from "react-router-dom";

export function ListQuestion() {
  const toast = useToast();

  const [searchParams, setSearchParams] = useSearchParams();
  const s = searchParams.get("search");

  const [search, setSearch] = useState(s ?? "");

  function handleFilter() {
    setSearchParams((state) => {
      if (search) {
        state.set("search", search);
      } else {
        state.delete("search");
      }

      return state;
    });
  }

  const { dataList, dataPage, loading } = useListQuestions();
  const [idQuestion, setIdQuestion] = useState("");
  const [loadingDelete, setLoadingDelete] = useState(false);
  const [itemSelected, setItemSelected] = useState<string[]>([]);

  const { isOpen, onOpen, onClose } = useDisclosure();
  const cancelRef = useRef<any>();

  async function deleteQuestion(id: string, type: "one" | "select" | "all") {
    try {
      setLoadingDelete(true);

      await api.delete(
        `question/delete/${id}?type=${type}&search=${s}&data_all=${itemSelected}`
      );
      toast({
        position: "top-right",
        title: "Questão",
        description: "Questão removido com sucesso.",
        status: "success",
        duration: 9000,
        isClosable: true,
      });
      setIdQuestion("");
      onClose();
      window.location.reload();
    } catch (error) {
      toast({
        position: "top-right",
        title: "Questão",
        description: "Não foi possível remover questão.",
        status: "error",
        duration: 9000,
        isClosable: true,
      });
    } finally {
      setLoadingDelete(false);
    }
  }

  function addItemSelected(itemId: string) {
    setItemSelected([...itemSelected, itemId]);
  }

  function removeItemSelected(itemId: string) {
    const item: string[] = itemSelected;
    const newItem = item.filter((vl) => vl !== itemId);
    setItemSelected(newItem);
  }

  function existItem(itemId: string) {
    const item: string[] = itemSelected;
    const existValue = item.find((vl) => vl === itemId);

    if (existValue) return true;

    return false;
  }

  console.log(itemSelected);

  return (
    <Flex w="100%" flexDirection="column" marginBottom="100px">
      <AlertDialog
        motionPreset="slideInBottom"
        leastDestructiveRef={cancelRef}
        onClose={onClose}
        isOpen={isOpen}
        isCentered
      >
        <AlertDialogOverlay />

        <AlertDialogContent>
          <AlertDialogHeader>
            {s ? "Excluir questões" : "Excluir questão"}
          </AlertDialogHeader>
          <AlertDialogCloseButton />
          <AlertDialogBody>
            {s ? "Deseja excluir questões?" : "Deseja excluir questão?"}
          </AlertDialogBody>
          <AlertDialogFooter>
            <Button ref={cancelRef} onClick={onClose}>
              Não
            </Button>
            <Button
              isLoading={loadingDelete}
              colorScheme="red"
              ml={3}
              onClick={() => {
                if (s && itemSelected.length === 0) {
                  deleteQuestion("idQuestion", "all");
                } else if (s && itemSelected.length > 0) {
                  deleteQuestion("idQuestion", "select");
                } else {
                  deleteQuestion(idQuestion, "one");
                }
              }}
            >
              Sim
            </Button>
          </AlertDialogFooter>
        </AlertDialogContent>
      </AlertDialog>

      <Flex style={{ marginTop: 48, flexDirection: "column" }}>
        <Title title="Questões" />

        <Button
          as="a"
          bg="green.500"
          color="white"
          style={{
            borderRadius: 53,
            height: 44,
            fontSize: 16,
            fontWeight: 600,
          }}
          width={["100%", "100%", "193px"]}
          _hover={{
            bg: "green.500",
          }}
          mt="6"
          size="lg"
          rightIcon={
            <Icon fontSize="20px" as={RiArrowRightFill} color="#fff" />
          }
          href="/registrar-questoes"
        >
          Nova questão
        </Button>

        {loading && <AllLoading />}

        <Flex
          gap={["5px", "5px", "10px"]}
          mt="60px"
          alignItems="center"
          alignContent="center"
          justifyContent="center"
          width={["100%", "100%", "800px"]}
          flexDirection={["column", "column", "row"]}
        >
          <InputBox
            label="Pesquisar por Enunciado"
            placeholder="Pesquisa..."
            name="search"
            autoComplete="off"
            value={search}
            onChange={(e) => {
              setSearch(e.target.value);
            }}
          />

          <Button
            bg="green.500"
            color="white"
            style={{
              borderRadius: 53,
              height: 44,
              fontSize: 16,
              fontWeight: 600,
            }}
            width={["100%", "100%", "270px"]}
            _hover={{
              bg: "green.500",
            }}
            mt={["0px", "0px", "25px"]}
            size="lg"
            rightIcon={<Icon fontSize="20px" as={RiFilter2Line} color="#fff" />}
            onClick={() => {
              handleFilter();
            }}
          >
            Filtrar Questões
          </Button>
        </Flex>

        {!loading && dataList.length > 0 && dataPage.total > 0 && (
          <Flex
            style={{
              marginTop: 10,
              flexDirection: "column",
            }}
          >
            <Flex>
              <Flex></Flex>
            </Flex>
            <TableContainer
              width={["350px", "350px", "100%"]}
              overflowX="scroll"
            >
              <Table>
                <Thead>
                  <Tr>
                    <Th style={{ fontSize: "12px" }}>Tipo de contéudo</Th>
                    <Th style={{ fontSize: "12px" }}>Enunciado</Th>
                    <Th style={{ fontSize: "12px" }}>Texto</Th>
                    <Th style={{ fontSize: "12px" }}>Gabarito</Th>
                    <Th style={{ fontSize: "12px" }}>discipline</Th>
                    <Th style={{ fontSize: "12px" }}>Assunto</Th>
                    <Th style={{ fontSize: "12px" }}>Banca</Th>
                    <Th style={{ fontSize: "12px" }}>Ano</Th>
                    <Th style={{ fontSize: "12px" }}>Tempo</Th>
                    <Th style={{ fontSize: "12px" }}>Ações</Th>
                    {s && (
                      <Th style={{ fontSize: "12px" }}>
                        <Button
                          colorScheme="red"
                          size="xs"
                          onClick={() => {
                            onOpen();
                          }}
                        >
                          {itemSelected.length > 0
                            ? "Exluir Selecionados"
                            : "Exluir todos"}
                        </Button>
                      </Th>
                    )}
                  </Tr>
                </Thead>
                <Tbody>
                  {dataList.map((item, index) => {
                    return (
                      <Tr
                        key={index}
                        borderBottomWidth={1.5}
                        borderBottomColor="#069"
                      >
                        <Td style={{ fontSize: "12px" }}>
                          {typeQuestionBr(item.content_type)}
                        </Td>
                        <Td style={{ fontSize: "12px" }}>
                          {lmWord(item.enunciation, 25)}
                        </Td>
                        <Td style={{ fontSize: "12px" }}>
                          {lmWord(item.text ?? "", 20)}
                        </Td>
                        <Td style={{ fontSize: "12px" }}>
                          {lmWord(item.answer_to_the_question ?? "", 20)}
                        </Td>
                        <Td style={{ fontSize: "12px" }}>
                          {lmWord(item.discipline, 20)}
                        </Td>
                        <Td style={{ fontSize: "12px" }}>
                          {lmWord(item.subject, 25)}
                        </Td>
                        <Td style={{ fontSize: "12px" }}>{item.jury}</Td>
                        <Td style={{ fontSize: "12px" }}>{item.year}</Td>
                        <Td style={{ fontSize: "12px" }}>
                          {item.time_in_seconds}
                        </Td>
                        <Td>
                          <Menu>
                            <MenuButton
                              as={IconButton}
                              aria-label="Options"
                              icon={
                                <Icon
                                  fontSize="16px"
                                  as={RiSettings3Line}
                                  color="#0577BE"
                                />
                              }
                              variant="outline"
                            />
                            <MenuList>
                              <MenuItem
                                onClick={() => {
                                  setIdQuestion(item.id);
                                  onOpen();
                                }}
                                icon={
                                  <Icon
                                    fontSize="16px"
                                    as={RiDeleteBin2Line}
                                    color="#0577BE"
                                  />
                                }
                              >
                                Excluir
                              </MenuItem>
                              <MenuItem
                                as="a"
                                href={`/questao/update/${item.id}`}
                                icon={
                                  <Icon
                                    fontSize="16px"
                                    as={RiEdit2Fill}
                                    color="#0577BE"
                                  />
                                }
                              >
                                Editar
                              </MenuItem>
                            </MenuList>
                          </Menu>
                        </Td>

                        {search && (
                          <Td style={{ fontSize: "12px", textAlign: "center" }}>
                            <Checkbox
                              isChecked={existItem(item.id)}
                              size="md"
                              colorScheme="green"
                              onChange={(it) => {
                                if (!it.target.checked) {
                                  removeItemSelected(item.id);
                                } else {
                                  addItemSelected(item.id);
                                }
                              }}
                            />
                          </Td>
                        )}
                      </Tr>
                    );
                  })}
                </Tbody>
              </Table>
            </TableContainer>
            <Flex
              justifyContent="center"
              alignItems="center"
              justifyItems="center"
              width="100%"
              marginTop="10px"
            >
              <Pagination
                type="table"
                current={dataPage.current_page}
                next={
                  dataPage.last_page === dataPage.current_page
                    ? null
                    : dataPage.current_page + 1
                }
                previous={dataPage.current_page - 1}
                total={dataPage.last_page}
              />
            </Flex>
          </Flex>
        )}
      </Flex>
    </Flex>
  );
}
