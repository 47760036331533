import { Flex, Icon, Spinner, Text } from "@chakra-ui/react";
import { ListItem } from "./listItem";
import { RiChat4Fill, RiChatNewFill } from "react-icons/ri";
import { ModalCreateComment } from "./create";
import { useEffect, useState } from "react";
import { useListComment } from "../hooks/useListComment";

type Props = {
  question_id: string;
  onOpen: () => void;
};
export function Comments({ question_id, onOpen }: Props) {
  const [isModal, setIsModal] = useState(false);

  const { dataList, loading, refetch } = useListComment();

  useEffect(() => {
    refetch(question_id);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [question_id]);

  return (
    <>
      <ModalCreateComment
        is_open={isModal}
        id={question_id}
        onClose={() => {
          setIsModal(false);
        }}
        onCloseSend={() => {
          setIsModal(false);
          refetch(question_id);
        }}
      />

      <Flex
        style={{ flexDirection: "column" }}
        width={["100%", "100%", "auto"]}
      >
        <Flex
          width={["100%", "100%", "auto"]}
          flexDirection={["column", "column", "row"]}
        >
          <Flex
            style={{
              height: "49px",
              borderWidth: 1,
              borderColor: "#8D8D8D",
              borderRadius: 10,
              paddingRight: 15,
              paddingLeft: 15,
              paddingTop: 10,
              paddingBottom: 10,
              alignItems: "center",
              justifyContent: "center",
              backgroundColor: "#8D8D8D",
            }}
            width={["100%", "100%", "auto"]}
          >
            <Text
              style={{
                color: "#F5F5F5",
                fontSize: 16,
                fontFamily: "Kanit",
                fontWeight: 600,
              }}
            >
              Comentários
            </Text>
            <Text
              style={{
                color: "#FFE500",
                fontSize: 16,
                fontFamily: "Kanit",
                fontWeight: 600,
                marginLeft: 5,
                marginRight: 5,
              }}
            >
              {dataList.length}
            </Text>
            <Icon fontSize="16px" as={RiChat4Fill} color="#F5F5F5" />
          </Flex>

          <Flex
            style={{
              height: "49px",
              borderWidth: 1,
              borderColor: "#03A679",
              borderRadius: 10,
              paddingRight: 15,
              paddingLeft: 15,
              paddingTop: 10,
              paddingBottom: 10,
              alignItems: "center",
              justifyContent: "center",
              cursor: "pointer",
              backgroundColor: "#03A679",
            }}
            marginLeft={["0px", "0px", "10px"]}
            marginTop={["10px", "10px", "0px"]}
            onClick={() => {
              setIsModal(true);
            }}
          >
            <Text
              style={{
                color: "#F5F5F5",
                fontSize: 16,
                fontFamily: "Kanit",
                fontWeight: 600,
              }}
            >
              Adicionar comentário
            </Text>

            <Icon ml={2} fontSize="16px" as={RiChatNewFill} color="#F5F5F5" />
          </Flex>

          <Flex
            style={{
              height: "49px",
              borderWidth: 1,
              borderColor: "#03A679",
              borderRadius: 10,
              paddingRight: 15,
              paddingLeft: 15,
              paddingTop: 10,
              paddingBottom: 10,
              alignItems: "center",
              justifyContent: "center",
              cursor: "pointer",
              backgroundColor: "#03A679",
            }}
            marginLeft={["0px", "0px", "10px"]}
            marginTop={["10px", "10px", "0px"]}
            onClick={() => {
              onOpen();
            }}
          >
            <Text
              style={{
                color: "#F5F5F5",
                fontSize: 16,
                fontFamily: "Kanit",
                fontWeight: 600,
              }}
            >
              Resposta comentada
            </Text>

            <Icon ml={2} fontSize="16px" as={RiChat4Fill} color="#F5F5F5" />
          </Flex>
        </Flex>
        <Flex style={{ marginTop: 30, flexDirection: "column" }}>
          {dataList.length === 0 && loading && (
            <Flex
              style={{
                height: 200,
                alignItems: "center",
                justifyContent: "center",
              }}
              width={["100%", "100%", "400px"]}
            >
              <Spinner
                color="green.600"
                emptyColor="white"
                thickness="4px"
                size="xl"
                h={100}
                w={100}
              />
            </Flex>
          )}
          {!loading &&
            dataList &&
            dataList.map((item, index) => {
              return (
                <ListItem
                  key={index}
                  date={item.created_at}
                  userName={item.user.name}
                  description={item.description}
                />
              );
            })}
        </Flex>
      </Flex>
    </>
  );
}
