import Excel from "exceljs";

class ReportFormatters {
  public static async questions(file: any): Promise<any> {
    try {
      const questions: any = [];

      const workbook = new Excel.Workbook();
      const reader = new FileReader();
      reader.readAsArrayBuffer(file);

      let positionIndex = 0;

      return new Promise((resolve) => {
        reader.onload = () => {
          const buffer = reader.result as Buffer;

          workbook.xlsx.load(buffer).then((workbook) => {
            workbook.eachSheet((sheet) => {
              // console.log("==========================");
              // console.log(sheet);
              sheet.eachRow((row, rowIndex) => {
                const line = row.values as Array<any>;

                try {
                  if (questions[positionIndex] === undefined && rowIndex > 1) {
                    if (row.worksheet.name === "Espaço em branco") {
                      const user = {
                        type: "Dissertativa Espaço em branco",
                        enunciado: validateAndFormatVaues(line[1]),
                        texto: validateAndFormatVaues(line[2]),
                        dica_01: validateAndFormatVaues(line[3]),
                        dica_02: validateAndFormatVaues(line[4]),
                        dica_03: validateAndFormatVaues(line[5]),
                        tipo_da_questao: validateAndFormatVaues(line[6]),
                        modalidade: validateAndFormatVaues(line[7]),
                        gabarito: validateAndFormatVaues(line[8]),
                        link_de_resposta_mais_aprofundada:
                          validateAndFormatVaues(line[9]),
                        disciplina: validateAndFormatVaues(line[10]),
                        assunto: validateAndFormatVaues(line[11]),
                        banca: validateAndFormatVaues(line[12]),
                        ano: validateAndFormatVaues(line[13]),
                        tag: validateAndFormatVaues(line[14]),
                        tempo_esperado: validateAndFormatVaues(line[15]),
                      };
                      questions[positionIndex] = user;
                    } else if (row.worksheet.name === "Multipa Escolha") {
                      let resposta_correta = validateAndFormatVaues(line[6]);
                      const user = {
                        type: row.worksheet.name,
                        enunciado: validateAndFormatVaues(line[1]),
                        dica_01: validateAndFormatVaues(line[2]),
                        dica_02: validateAndFormatVaues(line[3]),
                        dica_03: validateAndFormatVaues(line[4]),
                        tipo_da_questao: validateAndFormatVaues(line[5]),
                        modalidade: validateAndFormatVaues(line[6]),
                        alternatives: [
                          {
                            title: "A",
                            description: validateAndFormatVaues(line[7]),
                            is_correct: resposta_correta === "Alternativa 01",
                          },
                          {
                            title: "B",
                            description: validateAndFormatVaues(line[8]),
                            is_correct: resposta_correta === "Alternativa 02",
                          },
                          {
                            title: "C",
                            description: validateAndFormatVaues(line[9]),
                            is_correct: resposta_correta === "Alternativa 03",
                          },
                          {
                            title: "D",
                            description: validateAndFormatVaues(line[10]),
                            is_correct: resposta_correta === "Alternativa 04",
                          },
                          {
                            title: "E",
                            description: validateAndFormatVaues(line[11]),
                            is_correct: resposta_correta === "Alternativa 05",
                          },
                        ],
                        gabarito: validateAndFormatVaues(line[12]),
                        resposta: validateAndFormatVaues(line[13]),
                        link_de_resposta_mais_aprofundada:
                          validateAndFormatVaues(line[14]),
                        disciplina: validateAndFormatVaues(line[15]),
                        assunto: validateAndFormatVaues(line[16]),
                        banca: validateAndFormatVaues(line[17]),
                        ano: validateAndFormatVaues(line[18]),
                        tag: validateAndFormatVaues(line[19]),
                        tempo_esperado: validateAndFormatVaues(line[20]),
                      };
                      questions[positionIndex] = user;
                    } else if (row.worksheet.name === "Verdadeiro ou Falso") {
                      const user = {
                        type: row.worksheet.name,
                        enunciado: validateAndFormatVaues(line[1]),
                        dica_01: validateAndFormatVaues(line[2]),
                        dica_02: validateAndFormatVaues(line[3]),
                        dica_03: validateAndFormatVaues(line[4]),
                        tipo_da_questao: validateAndFormatVaues(line[5]),
                        modalidade: validateAndFormatVaues(line[6]),
                        gabarito: validateAndFormatVaues(line[7]),
                        resposta: validateAndFormatVaues(line[8]),
                        link_de_resposta_mais_aprofundada:
                          validateAndFormatVaues(line[9]),
                        disciplina: validateAndFormatVaues(line[10]),
                        assunto: validateAndFormatVaues(line[11]),
                        banca: validateAndFormatVaues(line[12]),
                        ano: validateAndFormatVaues(line[13]),
                        tag: validateAndFormatVaues(line[14]),
                        tempo_esperado: validateAndFormatVaues(line[15]),
                      };
                      questions[positionIndex] = user;
                    }

                    positionIndex = positionIndex + 1;
                  }
                } catch (error) {
                  throw error;
                }
              });
            });

            resolve(questions);
          });
        };
      });
    } catch (error) {
      throw error;
    }
  }
}

function validateAndFormatVaues(value: any) {
  try {
    if (!value.toString()) return "";

    if (typeof value === "object") {
      let text = "";
      for (let index = 0; index < value.richText.length; index++) {
        const element = value.richText[index];
        text += `${element.text} `;
      }

      return text;
    } else {
      return value.toString();
    }
  } catch (error) {
    return "";
  }
}

export { ReportFormatters };
