import { Box, Button, Flex, Image, Link, Stack, Text } from "@chakra-ui/react";

import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";

import { SubmitHandler, useForm } from "react-hook-form";

import { Input } from "../../../components/form/input";
import { Navigate, useNavigate } from "react-router-dom";
import { useAuth } from "../../../hooks/useAuth";
import { api } from "../../../service/api";
import { IMAGE_LOGIN, LOGO_BRANCA } from "../../../assets";

type authFormData = {
  email: string;
};

const authFormDataSchema = yup.object().shape({
  email: yup
    .string()
    .required("Email é um campo obrigatório.")
    .email("Email não é valido."),
});

export function RecoverPassword() {
  const navigate = useNavigate();

  const { isAuthenticated, isAtuhLoading } = useAuth();

  const {
    register,
    handleSubmit,
    formState: { errors, isSubmitting },
  } = useForm({ resolver: yupResolver(authFormDataSchema) });

  if (isAtuhLoading) return;

  if (isAuthenticated) {
    return <Navigate to="/home" replace />;
  }

  const handleSend: SubmitHandler<authFormData> = async (value) => {
    try {
      await api.post("/recoverpassword", {
        email: value.email,
      });

      navigate(`/atualizar-senha/${value.email}`);
    } catch (error) {
      navigate(`/atualizar-senha/${value.email}`);
    }
  };

  return (
    <Flex w="100%" h="100vh" alignItems="center" justifyContent="center">
      <Flex
        style={{ height: "100vh" }}
        flexDirection="column"
        bg="blue.500"
        w={["100%", "100%", "45%"]}
        paddingLeft={["20px", "20px", "0px"]}
        paddingRight={["20px", "20px", "0px"]}
      >
        <Flex
          style={{ marginTop: "64px" }}
          margin={["auto", "auto", "auto"]}
          marginLeft={["auto", "0px", "70px"]}
        >
          <Image
            src={LOGO_BRANCA}
            style={{ width: "271px", height: "76.83px" }}
            alt="logo"
          />
        </Flex>

        <Flex
          flex={1}
          alignItems="center"
          justifyContent="center"
          as="form"
          onSubmit={handleSubmit(handleSend)}
        >
          <Flex
            flexDirection="column"
            style={{ width: "380px", height: "400px" }}
          >
            <Text style={{ fontWeight: 600, fontSize: 24 }} color="green.100">
              Recuperação de senha
            </Text>
            <Flex flexDirection="column" style={{ marginTop: 26 }}>
              <Input
                label="Entre com seu e-mail cadastrado na plataforma"
                placeholder="Email"
                type="email"
                error={errors.email}
                {...register("email")}
              />

              <Button
                isLoading={isSubmitting}
                type="submit"
                bg="green.500"
                color="white"
                style={{
                  borderRadius: 53,
                  height: 44,
                  fontSize: 16,
                  fontWeight: 600,
                }}
                _hover={{
                  bg: "green.500",
                }}
                mt="6"
                size="lg"
              >
                Recuperar senha
              </Button>

              <Stack
                align="center"
                justifyContent="center"
                direction="row"
                style={{ marginTop: 21 }}
              >
                <Link
                  href="/Login"
                  color="green.100"
                  style={{ fontWeight: 600, fontSize: 12 }}
                >
                  Voltar
                </Link>
              </Stack>

              <Box
                style={{
                  width: "100%",
                  height: 1,
                  backgroundColor: "#E8E8E8",
                  marginTop: 21,
                }}
              />
            </Flex>
          </Flex>
        </Flex>
      </Flex>
      <Flex
        style={{ height: "100vh", width: "55%" }}
        display={["none", "none", "flex"]}
        bg="blue.500"
      >
        <Image src={IMAGE_LOGIN} style={{ width: "100%" }} alt="imagem curso" />
      </Flex>
    </Flex>
  );
}
