import { Button, Flex, Icon, Text } from "@chakra-ui/react";
import { RiPlayFill } from "react-icons/ri";
import { AiFillCaretLeft } from "react-icons/ai";
import { useSearchParams } from "react-router-dom";

type Props = {
  next: number | null;
  previous: number | null;
  current: number;
  total: number;
  type?: "default" | "table";
};

export function Pagination({
  next,
  current,
  previous,
  total,
  type = "default",
}: Props) {
  const [, setSearchParams] = useSearchParams();

  function handleFilter(pageQuestion: string) {
    setSearchParams((state) => {
      if (pageQuestion) {
        state.set("page_question", pageQuestion);
      } else {
        state.delete("page_question");
      }

      return state;
    });
  }

  return (
    <Flex
      style={{
        gap: 20,
        width: type === "default" ? "310px" : "310px",
        height: 27,
        alignItems: "center",
      }}
    >
      <Button
        onClick={
          previous
            ? () => {
                handleFilter(previous.toString());
              }
            : undefined
        }
        type="button"
        color="green.800"
        alignSelf="flex-end"
        mt="6"
        style={{
          fontSize: 14,
          fontFamily: "Kanit",
          fontWeight: 500,
          borderRadius: 13,
          width: 171,
          height: 27,
          color: "#0577BE",
          borderWidth: 1,
          borderColor: "#0577BE",
          cursor: previous ? "pointer" : "no-drop",
        }}
        leftIcon={<Icon as={AiFillCaretLeft} color="#0577BE" />}
      >
        Anterior
      </Button>
      <Flex
        style={{
          borderWidth: 1,
          borderColor: "#5F5F5F",
          borderRadius: 13,
          width: type === "table" ? 130 : 100,
          height: 27,
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <Text
          style={{
            fontSize: 14,
            fontFamily: "Kanit",
            fontWeight: 500,
            color: "#5F5F5F",
          }}
        >
          {`${current ?? 0}/${total}`}
        </Text>
      </Flex>

      <Button
        onClick={
          next
            ? () => {
                handleFilter(next.toString());
              }
            : undefined
        }
        type="button"
        color="green.800"
        alignSelf="flex-end"
        mt="6"
        style={{
          fontSize: 14,
          fontFamily: "Kanit",
          fontWeight: 500,
          borderRadius: 13,
          width: 171,
          height: 27,
          color: "#0577BE",
          borderWidth: 1,
          borderColor: "#0577BE",
          cursor: next ? "pointer" : "no-drop",
        }}
        rightIcon={<Icon as={RiPlayFill} color="#0577BE" />}
      >
        Próxima
      </Button>
    </Flex>
  );
}
