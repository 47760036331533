export const dataDiscipline = ["Direito administrativo", "Direito Financeiro"];

export const dataSubject = [
  {
    discipline: "Direito administrativo",
    subject:
      "Conceitos iniciais de Direito Administrativo - Histórico, Funções de Estado e Fontes",
  },
  {
    discipline: "Direito administrativo	",
    subject:
      "Conceitos iniciais de Direito Administrativo - Histórico, Funções de Estado e Fontes",
  },
  {
    discipline: "Direito administrativo	",
    subject: "Regime jurídico administrativo",
  },
  {
    discipline: "Direito administrativo	",
    subject: "Organização da Administração Pública",
  },
  {
    discipline: "Direito administrativo",
    subject: "Poderes da Adminstração",
  },
  {
    discipline: "Direito administrativo",
    subject: "Atos Administrativos",
  },
  {
    discipline: "Direito administrativo",
    subject:
      "Processo Administrativo - Lei n°9.784 de 1999 e Lei n° 14.210 de 2021",
  },
  {
    discipline: "Direito administrativo",
    subject: "7 Licitações e Lei 8.666 de 1993.",
  },
  {
    discipline: "Direito administrativo",
    subject: "Contratos Administrativos",
  },
  {
    discipline: "Direito administrativo",
    subject: "9 Licitações e Lei n° 14.133 de 2021",
  },
  {
    discipline: "Direito administrativo",
    subject: "10 Contratos Administrativos - Lei n°14.133 de 2021",
  },

  {
    discipline: "Direito Financeiro",
    subject:
      "Atividade Financeira do Estado e Sistema Financeiro Nacional - SFN",
  },
  {
    discipline: "Direito Financeiro",
    subject: "Principios gerais do Direito Financeiro",
  },
  { discipline: "Direito Financeiro", subject: "Orçamento: Aspectos Gerais" },
  { discipline: "Direito Financeiro", subject: "Receita Pública" },
  { discipline: "Direito Financeiro", subject: "Despesa Pública" },
  {
    discipline: "Direito Financeiro",
    subject:
      "Fiscalização, Controle Interno e Externo da Execução Orçamentária e Tribunais de Contas",
  },
  { discipline: "Direito Financeiro", subject: "Crédito público" },
  { discipline: "Direito Financeiro", subject: "Precatório" },
  {
    discipline: "Direito Financeiro",
    subject: "9 Lei de Responsabilidade Fiscal - LC n° 101 de 2000",
  },
];

export const year = [
  {
    label: "2017",
    value: "2017",
  },
  {
    label: "2018",
    value: "2018",
  },
  {
    label: "2019",
    value: "2019",
  },
  {
    label: "2020",
    value: "2020",
  },
  {
    label: "2021",
    value: "2021",
  },
  {
    label: "2022",
    value: "2022",
  },
  {
    label: "2023",
    value: "2023",
  },
  {
    label: "2024",
    value: "2024",
  },
];

export const jury = [
  {
    label: "Cebraspe",
    value: "Cebraspe",
  },
  {
    label: "FGV",
    value: "FGV",
  },
  {
    label: "FCC",
    value: "FCC",
  },
  {
    label: "Instituto AOCP",
    value: "Instituto AOCP",
  },
  {
    label: "IBFC",
    value: "IBFC",
  },
  {
    label: "IADES",
    value: "IADES",
  },
  {
    label: "Selecon",
    value: "Selecon",
  },
  {
    label: "Vunesp",
    value: "Vunesp",
  },
  {
    label: "Consulplan",
    value: "Consulplan",
  },
  {
    label: "Fundação Universa",
    value: "Fundação Universa",
  },
  {
    label: "Outra",
    value: "Outra",
  },
];
