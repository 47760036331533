import { Box, Button, Flex, Icon, Text } from "@chakra-ui/react";
import { Title } from "../../components/Title";
import { InputSelectBox } from "../../components/form/inputSelectBox";
import { InputBox } from "../../components/form/inputBox";
import {
  RiAddCircleFill,
  RiCloseCircleFill,
  RiFilter2Fill,
} from "react-icons/ri";
import { useEffect, useState } from "react";
import { SelectTypeDTO } from "../../dtos/SelectTypeDTO";
import { dataDiscipline } from "../../data/data";
import { useListDicipline } from "./hooks/useListDicipline";
import { useListSubjects } from "./hooks/useListSubjects";
import { useListJury } from "./hooks/useListJury";
import { useListModality } from "./hooks/useListModality";
import { useListYear } from "./hooks/useListYear";
import { useListTimerSeconds } from "./hooks/useListTimerSeconds";
import { useListContentType } from "./hooks/useListContentType";
import { useListQuestions } from "./hooks/useListQuestions";
import { QuestionsSimulatorInfoDTO } from "../../dtos/QuestionsSimulatorInfoDTO";
import { AllLoading } from "../../components/allLoading";
import { SimulatorResultModal } from "./modal/simulatorResultModal";

export interface IDisciplineData {
  discipline: string;
  subject: string[];
}

export function CreateSimulated() {
  //Hooks porject
  const { dataListDicipline, loadingDicipline, refetchDicipline } =
    useListDicipline();
  const { dataListSubject, loadingSubject, refetchSubject } = useListSubjects();
  const { refetchJury, dataLisJury, loadingJury } = useListJury();
  const { refetchModality, dataLisModality, loadingModality } =
    useListModality();
  const { refetchYear, dataLisYear, loadingYear } = useListYear();
  const { refetchTimerSeconds, loadingTimerSeconds, dataLisTimerSeconds } =
    useListTimerSeconds();
  const { refetchContentType, dataLisContentType, loadingContentType } =
    useListContentType();

  const { refetchQuestions } = useListQuestions();

  //Hooks
  const [disciplineData, setDisciplineData] = useState<IDisciplineData[]>([]);
  const [, setDisciplineType] = useState<SelectTypeDTO[]>([]);
  // const [, setSubjectType] = useState<SelectTypeDTO[]>([]);
  const [disciplineValue, setDisciplineValue] = useState("");
  const [subjectValue, setSubjectValue] = useState<string[]>([]);

  const [valueSubjectType, setValueSubjectType] = useState<string[]>([]);
  const [valueDisciplineType, setValueDisciplineType] =
    useState<SelectTypeDTO>();

  const [juryData, setJuryData] = useState("");
  const [modalityData, setModalityData] = useState("");
  const [yearData, setYearData] = useState("");
  const [timerSecondsData, setTimerSecondsData] = useState("");
  const [contentTypeData, setContentTypeData] = useState("");
  const [title, setTitle] = useState("");

  const [isLoadingFilter, setIsLoadingFilter] = useState(false);

  useEffect(() => {
    const newSelectTypeDTO: SelectTypeDTO[] = [];

    for (let index = 0; index < dataDiscipline.length; index++) {
      const element = dataDiscipline[index];
      newSelectTypeDTO.push({
        label: element,
        value: element,
      });
    }
    setDisciplineType(newSelectTypeDTO);
  }, []);

  function getSubjectType(discipline: string) {
    refetchSubject(discipline);

    // const newSelectTypeDTO: SelectTypeDTO[] = [];

    // const values = dataSubject.filter((item) => item.discipline === discipline);

    // for (let index = 0; index < values.length; index++) {
    //   const element = values[index];
    //   newSelectTypeDTO.push({
    //     label: element.subject,
    //     value: element.subject,
    //   });
    // }

    // setSubjectType(newSelectTypeDTO);
  }

  function setSubject(values: any) {
    const dataValue = [];

    for (let index = 0; index < values.length; index++) {
      const element = values[index];
      dataValue.push(element.value);
    }

    setSubjectValue(dataValue);
  }

  function addDicipline() {
    setDisciplineData([
      ...disciplineData,
      {
        discipline: disciplineValue,
        subject: subjectValue,
      },
    ]);

    setValueDisciplineType({} as SelectTypeDTO);
    setValueSubjectType([]);
  }

  useEffect(() => {
    let disciplineText = "";
    for (let index = 0; index < disciplineData.length; index++) {
      const element = disciplineData[index];
      disciplineText =
        disciplineText.length > 0
          ? disciplineText + "|" + element.discipline
          : element.discipline;
    }
    refetchDicipline(disciplineText);
    refetchSubject("");

    refetchJury(disciplineData);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [disciplineData]);

  useEffect(() => {
    let disciplineText = "";
    for (let index = 0; index < disciplineData.length; index++) {
      const element = disciplineData[index];
      disciplineText =
        disciplineText.length > 0
          ? disciplineText + "|" + element.discipline
          : element.discipline;
    }

    if (juryData) {
      refetchModality({
        jury: juryData,
        diciplineSelected: disciplineData,
      });
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [disciplineData, juryData]);

  useEffect(() => {
    let disciplineText = "";
    for (let index = 0; index < disciplineData.length; index++) {
      const element = disciplineData[index];
      disciplineText =
        disciplineText.length > 0
          ? disciplineText + "|" + element.discipline
          : element.discipline;
    }

    if (juryData && modalityData) {
      refetchYear({
        jury: juryData,
        diciplineSelected: disciplineData,
        modality: modalityData,
      });
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [disciplineData, juryData, modalityData]);

  useEffect(() => {
    let disciplineText = "";
    for (let index = 0; index < disciplineData.length; index++) {
      const element = disciplineData[index];
      disciplineText =
        disciplineText.length > 0
          ? disciplineText + "|" + element.discipline
          : element.discipline;
    }

    if (juryData && modalityData && yearData) {
      refetchTimerSeconds({
        jury: juryData,
        diciplineSelected: disciplineData,
        modality: modalityData,
        year: yearData,
      });
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [disciplineData, juryData, modalityData, yearData]);

  useEffect(() => {
    let disciplineText = "";
    for (let index = 0; index < disciplineData.length; index++) {
      const element = disciplineData[index];
      disciplineText =
        disciplineText.length > 0
          ? disciplineText + "|" + element.discipline
          : element.discipline;
    }

    if (juryData && modalityData && yearData && timerSecondsData) {
      refetchContentType({
        jury: juryData,
        diciplineSelected: disciplineData,
        modality: modalityData,
        year: yearData,
        timer: timerSecondsData,
      });
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [disciplineData, juryData, modalityData, yearData, timerSecondsData]);

  const [questionsSimulatorData, setQuestionsSimulatorData] = useState<
    QuestionsSimulatorInfoDTO[]
  >([]);

  const [isOpenModal, setIsOpenModal] = useState(false);
  const [quantityQuestions, setQuantityQuestions] = useState(0);

  async function getQuestions() {
    try {
      setIsLoadingFilter(true);
      const resultRequest = await refetchQuestions({
        jury: juryData,
        diciplineSelected: disciplineData,
        modality: modalityData,
        year: yearData,
        timer: timerSecondsData,
        content_type: contentTypeData,
      });

      setQuantityQuestions(resultRequest.quantity);
      const response: QuestionsSimulatorInfoDTO[] = resultRequest.list;
      setQuestionsSimulatorData(response);
      setIsOpenModal(true);
    } catch (error) {
      alert("Não foi possível finalizar o filtro");
    } finally {
      setIsLoadingFilter(false);
    }
  }

  useEffect(() => {
    let newTitleValue = `TM${Math.round(Math.random() * (100000 - 10)) + 1}`;
    setTitle(newTitleValue.toString());
  }, []);

  return (
    <Flex w="100%" flexDirection="column">
      {isLoadingFilter && <AllLoading />}
      <SimulatorResultModal
        quantityQuestions={quantityQuestions}
        content_type={contentTypeData}
        title={title}
        data={questionsSimulatorData}
        is_open={isOpenModal}
        onClose={() => {
          setIsOpenModal(false);
        }}
        onCloseSend={() => {}}
      />
      <Flex
        style={{ flexDirection: "column" }}
        marginTop={["20px", "20px", "48px"]}
      >
        <Title title="Criar simulado" />

        <Flex
          style={{
            flexDirection: "column",
          }}
          marginTop={["20px", "20px", "32px"]}
          width={["100%", "100%", "675px"]}
          maxWidth={["310px", "310px", "100%"]}
        >
          <Text
            style={{
              fontSize: 16,
              fontWeight: 500,
              color: "#A4A4A4",
              fontFamily: "Kanit",
            }}
          >
            Para gerar questões, fazer resumos com a ajuda do Agente de AI
            especializado em concursos, você precisa fazer o upload do documento
            (artigo, aula, capítulos de livros, material de estudo...). O
            formato deve ser em .PDF
          </Text>

          {disciplineData.map((item, index) => {
            return (
              <Box key={index}>
                <Box style={{ marginTop: 25 }}>
                  <InputBox
                    name="teste"
                    label="Matéria"
                    value={item.discipline}
                    isReadOnly
                  />
                </Box>
                <Box style={{ marginTop: 25 }}>
                  <InputBox
                    name="teste"
                    label="Tópicos"
                    value={arrayToString(item.subject)}
                    isReadOnly
                  />
                </Box>

                <Button
                  type="button"
                  color="green.500"
                  style={{
                    borderRadius: 53,
                    height: 44,
                    fontSize: 16,
                    fontWeight: 600,
                    marginBottom: 30,
                    borderWidth: 1,
                    borderColor: "#03A679",
                  }}
                  width={["100%", "100%", "150px"]}
                  mt="6"
                  size="lg"
                  rightIcon={
                    <Icon
                      fontSize="20px"
                      as={RiCloseCircleFill}
                      color="#03A679"
                    />
                  }
                  onClick={() => {
                    const data = disciplineData.filter(
                      // eslint-disable-next-line no-self-compare
                      (item) => item.discipline !== item.discipline
                    );
                    setDisciplineData(data);
                  }}
                >
                  Remover
                </Button>
              </Box>
            );
          })}

          <Box style={{ marginTop: 25 }}>
            <InputSelectBox
              width="100%"
              label="Matéria"
              name="teste"
              isLoading={loadingDicipline}
              options={dataListDicipline}
              value={valueDisciplineType}
              onChange={(value) => {
                getSubjectType(value.value);
                setDisciplineValue(value.value);
                setValueDisciplineType(value);
                setValueSubjectType([]);
              }}
              placeholder="Administração geral; Direito Constitucional..."
            />
          </Box>
          <Box style={{ marginTop: 25 }}>
            <InputSelectBox
              width="100%"
              label="Tópicos"
              name="teste"
              isMult
              isLoading={loadingSubject}
              options={dataListSubject}
              value={valueSubjectType}
              onChange={(value) => {
                setSubject(value);
                setValueSubjectType(value);
              }}
              placeholder="Assunto 01; Assunto 02; Assunto 03; Assunto 04"
            />
          </Box>

          <Button
            type="button"
            bg="green.500"
            color="white"
            isDisabled={disciplineValue === "" || subjectValue.length === 0}
            style={{
              borderRadius: 53,
              height: 44,
              fontSize: 16,
              fontWeight: 600,
            }}
            width={["100%", "100%", "226px"]}
            _hover={{
              bg: "green.500",
            }}
            mt="6"
            size="lg"
            rightIcon={
              <Icon fontSize="20px" as={RiAddCircleFill} color="#fff" />
            }
            onClick={() => {
              addDicipline();
            }}
          >
            Adicionar Matéria
          </Button>

          <Box style={{ marginTop: 35 }}>
            <InputBox
              name="teste"
              label="Nome do Simulado"
              placeholder="Aulão de Teste"
              onChange={(e) => {
                setTitle(e.target.value);
              }}
              value={title}
            />
          </Box>
          <Box style={{ marginTop: 25 }}>
            <InputSelectBox
              width="100%"
              label="Banca"
              name="teste"
              isLoading={loadingJury}
              options={dataLisJury}
              onChange={(value) => {
                setJuryData(value.value);
              }}
              placeholder="Filtro opcional"
            />
          </Box>
          <Box style={{ marginTop: 25 }}>
            <InputSelectBox
              width="100%"
              label="Modalidade"
              name="teste"
              isLoading={loadingModality}
              options={dataLisModality}
              onChange={(value) => {
                setModalityData(value.value);
              }}
              placeholder="Filtro opcional"
            />
          </Box>
          <Box style={{ marginTop: 25 }}>
            <InputSelectBox
              width="100%"
              label="Ano"
              name="teste"
              isLoading={loadingYear}
              options={dataLisYear}
              onChange={(value) => {
                setYearData(value.value);
              }}
              placeholder="Filtro opcional"
            />
          </Box>
          <Box style={{ marginTop: 25 }}>
            <InputSelectBox
              width="100%"
              label="Tempo esperado de resposta (segundos)"
              name="teste"
              isDisabled={loadingTimerSeconds}
              options={dataLisTimerSeconds}
              onChange={(value) => {
                setTimerSecondsData(value.value);
              }}
              placeholder="Filtro opcional"
            />
          </Box>
          <Box style={{ marginTop: 25 }}>
            <InputSelectBox
              width="100%"
              label="Qual tipo de questão você quer gerar?"
              name="teste"
              isLoading={loadingContentType}
              options={dataLisContentType}
              onChange={(value) => {
                setContentTypeData(value.value);
              }}
              placeholder="Filtro opcional"
            />
          </Box>

          <Flex
            style={{
              marginTop: 25,
              marginBottom: 80,
              alignItems: "flex-end",
              justifyContent: "flex-end",
              width: "100%",
              flexDirection: "column",
            }}
          >
            <Button
              type="submit"
              bg="green.500"
              color="white"
              isDisabled={disciplineData.length === 0 || title === ""}
              onClick={getQuestions}
              style={{
                borderRadius: 53,
                height: 44,
                fontSize: 16,
                fontWeight: 600,
                width: 193,
              }}
              _hover={{
                bg: "green.500",
              }}
              mt="6"
              size="lg"
              rightIcon={
                <Icon fontSize="20px" as={RiFilter2Fill} color="#fff" />
              }
            >
              Filtrar questões
            </Button>
          </Flex>
        </Flex>
      </Flex>
    </Flex>
  );
}

function arrayToString(subject: string[]) {
  return subject.join(", ");
}
