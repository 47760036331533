import { Flex, Text } from "@chakra-ui/react";

type Props = {
  title: string;
  info: string;
  actualInfo?: string;
};

export function QuestionInfo({ title, info, actualInfo }: Props) {
  return (
    <Flex
      style={{
        height: "44px",
        borderWidth: 1,
        borderColor: "#EBEBEB",
        borderRadius: 10,
        paddingRight: 15,
        paddingLeft: 15,
        paddingTop: 10,
        paddingBottom: 10,
        alignItems: "center",
        justifyContent: "center",
      }}
      width={[
        actualInfo ? "auto" : "100%",
        actualInfo ? "auto" : "100%",
        actualInfo ? "auto" : "210px",
      ]}
    >
      <Text
        style={{
          color: "#0577BE",
          fontSize: 12,
          fontFamily: "Kanit",
          fontWeight: 600,
        }}
      >
        {title}
      </Text>

      <Text
        style={{
          color: "#818181",
          fontSize: 16,
          fontFamily: "Kanit",
          fontWeight: 300,
          marginLeft: 5,
        }}
        width={[
          actualInfo ? "auto" : "auto",
          actualInfo ? "auto" : "auto",
          actualInfo ? "auto" : "45px",
        ]}
      >
        {info}
        {actualInfo && (
          <Text
            style={{
              color: "#232323",
              fontSize: 16,
              fontFamily: "Kanit",
              fontWeight: 300,
              marginLeft: 5,
              float: "left",
            }}
          >
            {actualInfo}
          </Text>
        )}
      </Text>
    </Flex>
  );
}
