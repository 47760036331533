import {
  Button,
  Flex,
  Icon,
  Table,
  TableContainer,
  Tbody,
  Td,
  Th,
  Thead,
  Tr,
} from "@chakra-ui/react";
import { Title } from "../../../components/Title";
import { useListRanking } from "./hooks/useListRanking";
import { AllLoading } from "../../../components/allLoading";
import { differenceBetweenHours, statusSimulator } from "../../../utils/utils";
import { useEffect, useState } from "react";
import { InputBox } from "../../../components/form/inputBox";
import { RiFilter2Line } from "react-icons/ri";
import { useSearchParams } from "react-router-dom";

export function ListRanking() {
  const [searchParams, setSearchParams] = useSearchParams();
  const { dataList, loading, refetch } = useListRanking();

  const start_date = searchParams.get("start_date");
  const end_date = searchParams.get("end_date");

  const [startDate, setStartDate] = useState(start_date ?? "");
  const [endDate, setEndDate] = useState(end_date ?? "");

  function handleFilter() {
    setSearchParams((state) => {
      if (startDate) {
        state.set("start_date", startDate);
      } else {
        state.delete("start_date");
      }

      if (endDate) {
        state.set("end_date", endDate);
      } else {
        state.delete("end_date");
      }

      return state;
    });
  }

  useEffect(() => {
    const intervalId = setInterval(() => {
      refetch(false);
    }, 15000);

    return () => clearInterval(intervalId);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Flex w="100%" flexDirection="column" marginBottom="100px">
      <Flex style={{ marginTop: 48, flexDirection: "column" }}>
        <Title title="Ranking simuladores" />
      </Flex>

      {loading && <AllLoading />}

      <Flex
        marginTop="60px"
        gap={5}
        alignItems="center"
        flexDirection={["column", "column", "row"]}
      >
        <InputBox
          label="Data início"
          placeholder="Selecione a data início"
          type="date"
          name="start_date"
          autoComplete="off"
          value={startDate}
          onChange={(e) => {
            setStartDate(e.target.value);
          }}
        />
        <InputBox
          label="Data final"
          placeholder="Selecione a data final"
          type="date"
          name="end_date"
          autoComplete="off"
          value={endDate}
          onChange={(e) => {
            setEndDate(e.target.value);
          }}
        />
        <Button
          bg="green.500"
          color="white"
          style={{
            borderRadius: 53,
            height: 44,
            fontSize: 16,
            fontWeight: 600,
          }}
          width={["100%", "100%", "470px"]}
          _hover={{
            bg: "green.500",
          }}
          mt="6"
          size="lg"
          rightIcon={<Icon fontSize="20px" as={RiFilter2Line} color="#fff" />}
          onClick={() => {
            handleFilter();
          }}
        >
          Filtrar simuladores
        </Button>
      </Flex>

      {!loading && dataList.length > 0 && (
        <Flex
          style={{
            marginTop: 60,
            flexDirection: "column",
          }}
        >
          <TableContainer width={["350px", "350px", "100%"]} overflowX="scroll">
            <Table>
              <Thead>
                <Tr>
                  <Th style={{ fontSize: "12px" }}>Status</Th>
                  <Th style={{ fontSize: "12px" }}>Usuário</Th>
                  <Th style={{ fontSize: "12px" }}>Simulador</Th>
                  <Th style={{ fontSize: "12px" }}>Quantidade de acertos</Th>
                  <Th style={{ fontSize: "12px" }}>Quantidade de erros</Th>
                  <Th style={{ fontSize: "12px" }}>Não respondidas</Th>
                  <Th style={{ fontSize: "12px" }}>Tempo do simulado</Th>
                </Tr>
              </Thead>
              <Tbody>
                {dataList.map((item, index) => {
                  return (
                    <Tr
                      key={index}
                      borderBottomWidth={1.5}
                      borderBottomColor="#069"
                    >
                      <Td style={{ fontSize: "12px" }}>
                        {statusSimulator(item.progress)}
                      </Td>
                      <Td style={{ fontSize: "12px" }}>{item.user_name}</Td>
                      <Td style={{ fontSize: "12px" }}>{item.title}</Td>
                      <Td style={{ fontSize: "12px", textAlign: "center" }}>
                        {item.success}
                      </Td>
                      <Td style={{ fontSize: "12px", textAlign: "center" }}>
                        {item.error}
                      </Td>
                      <Td style={{ fontSize: "12px", textAlign: "center" }}>
                        {item.no_reply}
                      </Td>
                      <Td style={{ fontSize: "12px", textAlign: "center" }}>
                        {differenceBetweenHours(
                          item.date_started,
                          item.date_finished
                        )}
                      </Td>
                    </Tr>
                  );
                })}
              </Tbody>
            </Table>
          </TableContainer>
        </Flex>
      )}
    </Flex>
  );
}
