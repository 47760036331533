import {
  Flex,
  Icon,
  IconButton,
  Menu,
  MenuButton,
  MenuItem,
  MenuList,
} from "@chakra-ui/react";
import {
  RiAspectRatioLine,
  RiBarChart2Fill,
  RiSettings3Line,
} from "react-icons/ri";

type Props = { id: string; onOpen: (type: "detail" | "performance") => void };
export function ButtonOptions({ id, onOpen }: Props) {
  return (
    <Flex>
      <Menu>
        <MenuButton
          height="44px"
          as={IconButton}
          aria-label="Options"
          icon={<Icon fontSize="16px" as={RiSettings3Line} color="#0577BE" />}
          variant="outline"
        />
        <MenuList>
          <MenuItem
            onClick={() => {
              onOpen("detail");
            }}
            icon={
              <Icon fontSize="16px" as={RiAspectRatioLine} color="#0577BE" />
            }
          >
            Detalhes
          </MenuItem>
          <MenuItem
            onClick={() => {
              onOpen("performance");
            }}
            icon={<Icon fontSize="16px" as={RiBarChart2Fill} color="#0577BE" />}
          >
            Performance
          </MenuItem>
        </MenuList>
      </Menu>
    </Flex>
  );
}
