import {
  FormControl,
  FormLabel,
  Input as ChakraInput,
  InputProps as ChakraInputProps,
  FormErrorMessage,
  HStack,
  Link,
} from "@chakra-ui/react";
import { forwardRef, ForwardRefRenderFunction } from "react";
import { FieldError } from "react-hook-form";

interface Props extends ChakraInputProps {
  name: string;
  label?: string;
  size?: "lg" | "md" | "sm";
  linkText?: string;
  error?: FieldError;
}

const InputBase: ForwardRefRenderFunction<HTMLInputElement, Props> = (
  { name, label, size = "lg", linkText, error, ...rest },
  ref
) => {
  return (
    <FormControl isInvalid={!!error}>
      {label && (
        <HStack alignItems="center" justifyContent="space-between">
          <FormLabel color="gray.800" fontWeight="bold" htmlFor="password">
            {label}
          </FormLabel>
          {linkText && (
            <Link href="/recuperacao-senha" color="green.600">
              {linkText}
            </Link>
          )}
        </HStack>
      )}

      <ChakraInput
        {...rest}
        id={name}
        name={name}
        borderColor="gray.50"
        borderWidth={1}
        focusBorderColor="gray.100"
        bgColor="transparent"
        color="white"
        variant="filled"
        borderRadius={90}
        height={51}
        size={size}
        _hover={{
          bgColor: "transparent",
        }}
        _placeholder={{ color: "gray.50" }}
        ref={ref}
      />
      {!!error && <FormErrorMessage>{error.message}</FormErrorMessage>}
    </FormControl>
  );
};

export const InputPassword = forwardRef(InputBase);
