import { useEffect, useState } from "react";
import { api } from "../../../../service/api";
import { RankingDTO } from "../../../../dtos/RankingDTO";
import { useSearchParams } from "react-router-dom";

export function useListRanking() {
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState<boolean | null>(null);
  const [dataList, setDataList] = useState<RankingDTO[]>([]);

  const [searchParams] = useSearchParams();

  const start_date = searchParams.get("start_date") ?? "";
  const end_date = searchParams.get("end_date") ?? "";

  const fetchData = async (isLoading: boolean) => {
    try {
      setLoading(isLoading);
      const response = await api.get(
        `ranking/simulator/list?start_date=${start_date}&end_date=${end_date}`
      );
      setDataList(response.data);
    } catch (error) {
      setError(true);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchData(true);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [start_date, end_date]);

  return {
    dataList,
    loading,
    error,
    refetch: fetchData,
  };
}
