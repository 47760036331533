import {
  Box,
  Button,
  Flex,
  Image,
  Link,
  Stack,
  Text,
  useToast,
} from "@chakra-ui/react";

import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import { SubmitHandler, useForm } from "react-hook-form";

import { Input } from "../../../components/form/input";
import { InputPassword } from "../../../components/form/inputPassword";

import { api } from "../../../service/api";
import { Navigate, useNavigate } from "react-router-dom";
import { useAuth } from "../../../hooks/useAuth";
import { IMAGE_LOGIN, LOGO_BRANCA } from "../../../assets";
import { InputMask } from "../../../components/form/inputMask";

type createFormData = {
  name: string;
  email: string;
  document: string;
  cellphone: string;
  password: string;
};

const createFormDataSchema = yup.object().shape({
  name: yup.string().required("Campo obrigatório."),
  email: yup
    .string()
    .required("Email é um campo obrigatório.")
    .email("Email não é valido"),
  password: yup
    .string()
    .required("Campo obrigatório.")
    .min(6, "Campo obrigatório"),
  cellphone: yup
    .string()
    .required("Campo obrigatório")
    .min(14, "Campo obrigatório")
    .max(14, "Campo obrigatório"),
  document: yup
    .string()
    .required("Campo obrigatório.")
    .min(14, "Campo obrigatório")
    .max(14, "Campo obrigatório"),
});

export function RegisterAccount() {
  const navigate = useNavigate();
  const toast = useToast();

  const { isAuthenticated, isAtuhLoading } = useAuth();

  const {
    register,
    handleSubmit,
    formState: { errors, isSubmitting },
  } = useForm({ resolver: yupResolver(createFormDataSchema) });

  if (isAtuhLoading) return <Box />;

  if (isAuthenticated) {
    return <Navigate to="/home" replace />;
  }

  const handleSend: SubmitHandler<createFormData> = async (value) => {
    try {
      const response = await api.post("account/create", value);

      const { link_pay, is_pay } = response.data;

      if (is_pay) {
        window.location.replace(link_pay);
      } else {
        navigate("/cadastro/sucesso");
      }

      toast({
        position: "top-right",
        title: "Criar conta",
        description: "Conta cadastrada com sucesso.",
        status: "success",
        duration: 9000,
        isClosable: true,
      });
    } catch (error) {
      toast({
        position: "top-right",
        title: "Criar conta",
        description: "Não foi possível criar conta.",
        status: "error",
        duration: 9000,
        isClosable: true,
      });
    }
  };

  return (
    <Flex w="100%" h="100vh" alignItems="center" justifyContent="center">
      <Flex
        style={{ height: "100vh" }}
        flexDirection="column"
        bg="blue.500"
        w={["100%", "100%", "45%"]}
        paddingLeft={["20px", "20px", "0px"]}
        paddingRight={["20px", "20px", "0px"]}
      >
        <Flex
          style={{ marginTop: "64px" }}
          margin={["auto", "auto", "auto"]}
          marginLeft={["auto", "0px", "70px"]}
        >
          <Image
            src={LOGO_BRANCA}
            style={{ width: "271px", height: "76.83px" }}
            alt="logo"
          />
        </Flex>

        <Flex
          flex={1}
          alignItems="center"
          justifyContent="center"
          as="form"
          onSubmit={handleSubmit(handleSend)}
        >
          <Flex flexDirection="column" style={{ width: "380px" }}>
            <Text style={{ fontWeight: 600, fontSize: 24 }} color="green.100">
              Criat conta
            </Text>
            <Flex flexDirection="column" style={{ marginTop: 26 }}>
              <Input
                placeholder="Nome completo"
                error={errors.name}
                {...register("name")}
              />
              <Box style={{ marginTop: 10 }}>
                <Input
                  placeholder="Email"
                  type="email"
                  error={errors.email}
                  {...register("email")}
                />
              </Box>
              <Box style={{ marginTop: 10 }}>
                <InputMask
                  placeholder="CPF"
                  error={errors.document}
                  {...register("document")}
                  mask="999.999.999-99"
                />
              </Box>
              <Box style={{ marginTop: 10 }}>
                <InputMask
                  placeholder="Celular"
                  error={errors.cellphone}
                  {...register("cellphone")}
                  mask="(99)99999-9999"
                />
              </Box>
              <InputPassword
                type="password"
                style={{ marginTop: 10, fontSize: 14 }}
                placeholder="Senha"
                {...register("password")}
                error={errors.password}
              />

              <Button
                isLoading={isSubmitting}
                type="submit"
                bg="green.500"
                color="white"
                style={{
                  borderRadius: 53,
                  height: 44,
                  fontSize: 16,
                  fontWeight: 600,
                }}
                _hover={{
                  bg: "green.500",
                }}
                mt="6"
                size="lg"
              >
                Criar conta
              </Button>

              <Stack
                align="center"
                justifyContent="center"
                direction="row"
                style={{ marginTop: 21 }}
              >
                <Link
                  href="/Login"
                  color="green.100"
                  style={{ fontWeight: 600, fontSize: 12 }}
                >
                  Voltar
                </Link>
              </Stack>

              <Box
                style={{
                  width: "100%",
                  height: 1,
                  backgroundColor: "#E8E8E8",
                  marginTop: 21,
                }}
              />
            </Flex>
          </Flex>
        </Flex>
      </Flex>
      <Flex
        style={{ height: "100vh", width: "55%" }}
        display={["none", "none", "flex"]}
        bg="blue.500"
      >
        <Image src={IMAGE_LOGIN} style={{ width: "100%" }} alt="imagem curso" />
      </Flex>
    </Flex>
  );
}
