import { Flex, Icon, Text } from "@chakra-ui/react";
import { RiCloseCircleLine } from "react-icons/ri";

type Props = {
  onCancel: () => void;
};

export function ButtonCancel({ onCancel }: Props) {
  return (
    <Flex
      style={{
        height: "44px",
        borderWidth: 1,
        borderColor: "#FF7C7C",
        borderRadius: 10,
        paddingRight: 15,
        paddingLeft: 15,
        paddingTop: 10,
        paddingBottom: 10,
        alignItems: "center",
        justifyContent: "center",
        cursor: "pointer",
        backgroundColor: "#FFDCDC",
      }}
      width={["100%", "100%", "auto"]}
      onClick={onCancel}
    >
      <Text
        style={{
          color: "#FF7C7C",
          fontSize: 12,
          fontFamily: "Kanit",
          fontWeight: 600,
        }}
      >
        Finalizar Simulado
      </Text>
      <Icon ml={2} fontSize="16px" as={RiCloseCircleLine} color="#FF7C7C" />
    </Flex>
  );
}
