import { Box, Button, Flex, Icon, Text } from "@chakra-ui/react";
import { Title } from "../../../../../components/Title";
import { InputSelectBox } from "../../../../../components/form/inputSelectBox";
import { InputBox } from "../../../../../components/form/inputBox";
import { useDropzone } from "react-dropzone";
import { SelectTypeDTO } from "../../../../../dtos/SelectTypeDTO";
import { useEffect, useState } from "react";
import { dataDiscipline } from "../../../../../data/data";
import { RiSaveLine } from "react-icons/ri";

export function CreateIaQuestion() {
  const [disciplineType, setDisciplineType] = useState<SelectTypeDTO[]>([]);
  const [subjectType, setSubjectType] = useState<SelectTypeDTO[]>([]);

  useEffect(() => {
    const newSelectTypeDTO: SelectTypeDTO[] = [];
    setSubjectType([]);
    for (let index = 0; index < dataDiscipline.length; index++) {
      const element = dataDiscipline[index];
      newSelectTypeDTO.push({
        label: element,
        value: element,
      });
    }
    setDisciplineType(newSelectTypeDTO);
  }, []);

  const {
    getRootProps,
    getInputProps,
    acceptedFiles,
    isFocused,
    isDragAccept,
    isDragReject,
    open,
  } = useDropzone({
    noKeyboard: true,
    maxFiles: 1,
    accept: {
      ".*": [],
    },
  });

  acceptedFiles.map(async (file: any) => {});

  return (
    <Flex w="100%" flexDirection="column" marginBottom="100px">
      <Flex style={{ marginTop: 48, flexDirection: "column" }}>
        <Title title="Cadastrar Questões com Inteligência Artificial" />

        <Flex
          style={{
            marginTop: 60,
          }}
        >
          <Text
            style={{
              fontSize: 16,
              fontFamily: "Kanit",
              width: "819px",
              color: "#5F5F5F",
              fontWeight: 300,
            }}
          >
            Para gerar questões, fazer resumos com a ajuda do Agente de AI
            especializado em concursos, você precisa fazer o upload do documento
            (artigo, aula, capítulos de livros, material de estudo...). O
            formato deve ser em .PDF
          </Text>
        </Flex>

        <Flex
          style={{
            marginTop: 60,
            flexDirection: "column",
            width: "675px",
          }}
        >
          <Text
            style={{
              fontSize: 16,
              fontWeight: 600,
              color: "#5F5F5F",
              fontFamily: "Kanit",
            }}
          >
            Vamos Começar?
          </Text>
          <Text
            style={{
              fontSize: 16,
              fontWeight: 500,
              color: "#A4A4A4",
              fontFamily: "Kanit",
              marginTop: 20,
            }}
          >
            Responda as perguntas para criar as questões / Resumos
          </Text>
        </Flex>
      </Flex>

      <Flex maxWidth="700px" width="100%" flexDirection="column">
        <Box style={{ marginTop: 25, width: "100%" }}>
          <InputSelectBox
            width="100%"
            label="Qual tipo de questão você quer gerar?"
            name="content_type"
            options={[
              {
                label: "Questões de concurso",
                value: "Questões de concurso",
              },
              {
                label: "Resumos",
                value: "Resumos",
              },
            ]}
            onChange={(value) => {
              // onChange(value.value);
            }}
            placeholder="Questões de concurso; Resumos ----> Aqui  o user escolhe ou uma ou  outra"
            // error={errors.content_type}
          />
        </Box>
        <Box style={{ marginTop: 25, width: "100%" }}>
          <InputBox
            name="1"
            label="Qual a quantidade de questões?"
            placeholder="Digite o número de questões ---> Se for Tipo = Questoes de concurso"
            // {...register("enunciation")}
            // error={errors.enunciation}
          />
        </Box>

        <Box style={{ marginTop: 25, width: "100%" }}>
          <InputBox
            type="number"
            name="1"
            label="Tamanho do resumo"
            subLabel=" - Especificar em número de palavras"
            placeholder="10.000 ---> Se for tipo Resumo"
            // {...register("enunciation")}
            // error={errors.enunciation}
          />
        </Box>

        <Box style={{ width: "100%" }}>
          <Flex
            style={{
              marginTop: 25,
              width: "100%",
              alignItems: "flex-start",
              justifyContent: "flex-start",
            }}
          >
            <Flex
              style={{
                borderWidth: 1,
                borderColor: "#D9D9D9",
                width: "100%",
                height: 47,
                borderRadius: 10,
                paddingLeft: 14,
                paddingRight: 14,
                alignItems: "center",
                cursor: "pointer",
              }}
              onClick={open}
            >
              <Text
                style={{
                  color: "#808080",
                  fontSize: 16,
                  fontFamily: "Kanit",
                  fontWeight: 300,
                }}
              >
                Selecione seu arquivo
              </Text>
            </Flex>

            <Flex
              style={{
                marginLeft: 10,
                backgroundColor: "#0577BE",
                width: 250,
                height: 45,
                borderRadius: 20,
                alignItems: "center",
                justifyContent: "center",
                cursor: "pointer",
              }}
              onClick={open}
            >
              <Text
                style={{
                  color: "#F5F5F5",
                  fontFamily: "Kanit",
                  fontWeight: 600,
                  fontSize: 16,
                  marginRight: 10,
                }}
              >
                Selecionar Arquivo
              </Text>
            </Flex>
          </Flex>
          <Flex
            style={{ marginTop: 20, flexDirection: "column", width: "100%" }}
          >
            <section
              style={{
                backgroundColor: "#FFFFFF",
                borderWidth: 1,
                borderColor: isFocused || isDragAccept ? "#03A679" : "#D9D9D9",
                borderRadius: 20,
                width: "100%",
                height: 120,
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              <div
                {...getRootProps({
                  isFocused,
                  isDragAccept,
                  isDragReject,
                })}
                style={{
                  display: "flex",
                  width: "100%",
                  height: 120,
                  alignItems: "center",
                  justifyContent: "center",
                  cursor: "pointer",
                }}
              >
                <input {...getInputProps()} />
                <p
                  style={{
                    color: "#808080",
                    fontFamily: "Kanit",
                    fontSize: 16,
                  }}
                >
                  ou arraste e solte seu arquivo aqui
                </p>
              </div>
            </section>
          </Flex>
        </Box>

        <Box style={{ width: "100%", marginTop: 25 }}>
          <Text
            style={{
              color: "#0577BE",
              fontSize: 14,
              fontWeight: 500,
              fontFamily: "Kanit",
            }}
          >
            Escolha o percentual dos tipos de questões:
          </Text>
          <Flex
            style={{
              marginTop: "10px",
              flexDirection: "row",
              flexWrap: "wrap",
              justifyContent: "space-between",
              gap: 20,
            }}
          >
            <Flex
              style={{
                flexDirection: "row",
                alignItems: "center",
              }}
            >
              <Text
                style={{
                  color: "#5F5F5F",
                  fontSize: 16,
                  fontFamily: "Kanit",
                  fontWeight: 400,
                }}
              >
                Verdadeiro ou falso:
              </Text>
              <Flex
                style={{
                  marginLeft: 5,
                  alignItems: "center",
                  justifyContent: "center",
                }}
              >
                <InputBox
                  name="teste"
                  width="150px"
                  textAlign="center"
                  placeholder=""
                  autoComplete="off"
                />
              </Flex>
            </Flex>

            <Flex
              style={{
                flexDirection: "row",
                alignItems: "center",
              }}
            >
              <Text
                style={{
                  color: "#5F5F5F",
                  fontSize: 16,
                  fontFamily: "Kanit",
                  fontWeight: 400,
                }}
              >
                Múltipla escolha:
              </Text>
              <Flex
                style={{
                  marginLeft: 5,
                  alignItems: "center",
                  justifyContent: "center",
                }}
              >
                <InputBox
                  name="teste"
                  width="150px"
                  textAlign="center"
                  placeholder=""
                  autoComplete="off"
                />
              </Flex>
            </Flex>

            <Flex
              style={{
                flexDirection: "row",
                alignItems: "center",
              }}
            >
              <Text
                style={{
                  color: "#5F5F5F",
                  fontSize: 16,
                  fontFamily: "Kanit",
                  fontWeight: 400,
                }}
              >
                Completar (Empty):
              </Text>
              <Flex
                style={{
                  marginLeft: 5,
                  alignItems: "center",
                  justifyContent: "center",
                }}
              >
                <InputBox
                  name="teste"
                  width="150px"
                  textAlign="center"
                  placeholder=""
                  autoComplete="off"
                />
              </Flex>
            </Flex>

            <Flex
              style={{
                flexDirection: "row",
                alignItems: "center",
              }}
            >
              <Text
                style={{
                  color: "#5F5F5F",
                  fontSize: 16,
                  fontFamily: "Kanit",
                  fontWeight: 400,
                }}
              >
                Discursiva
              </Text>
              <Flex
                style={{
                  marginLeft: 5,
                  alignItems: "center",
                  justifyContent: "center",
                }}
              >
                <InputBox
                  name="teste"
                  width="150px"
                  textAlign="center"
                  placeholder=""
                  autoComplete="off"
                />
              </Flex>
            </Flex>
          </Flex>
        </Box>

        <Box style={{ width: "100%", marginTop: 40 }}>
          <Text
            style={{
              color: "#5F5F5F",
              fontSize: 16,
              fontWeight: 500,
              fontFamily: "Kanit",
            }}
          >
            Agora, vamos classificar a questão, essa etapa é muito importante,
            faça com atenção!
          </Text>
        </Box>

        <Box style={{ width: "100%", marginTop: 25 }}>
          <InputSelectBox
            width="100%"
            label="Disciplinas"
            name="discipline"
            options={disciplineType}
            onChange={(value) => {}}
            placeholder="Administração geral; Direito Constitucional..."
            // error={errors.discipline}
          />
        </Box>
        <Box style={{ width: "100%", marginTop: 25 }}>
          <InputSelectBox
            width="100%"
            label="Assuntos"
            name="subject"
            options={subjectType}
            onChange={(value) => {
              // onChange(value.value);
            }}
            placeholder="Assunto"
            // error={errors.subject}
          />
        </Box>

        <Box style={{ width: "100%", marginTop: 25 }}>
          <InputSelectBox
            width="100%"
            label="Banca"
            name="jury"
            options={[
              {
                label: "teste",
                value: "teste",
              },
            ]}
            onChange={(value) => {
              // onChange(value.value);
            }}
            placeholder="FGV"
            // error={errors.jury}
          />
        </Box>

        <Box style={{ width: "100%", marginTop: 25 }}>
          <InputSelectBox
            width="100%"
            label="Modalidade"
            name="modality"
            options={[
              {
                label: "TM",
                value: "TM",
              },
              {
                label: "Especialista",
                value: "Especialista",
              },
              {
                label: "Inteligência Artificial",
                value: "Inteligência Artificial",
              },
              {
                label: "Elaboração Própria",
                value: "Elaboração Própria",
              },
            ]}
            onChange={(value) => {
              // onChange(value.value);
            }}
            placeholder="TM / Especialista / Inteligência Artificial / Elaboração Própria"
            // error={errors.modality}
          />
        </Box>

        <Box style={{ width: "100%", marginTop: 25 }}>
          <InputSelectBox
            width="100%"
            label="Ano"
            name="teste"
            options={[
              {
                label: "2022",
                value: "2022",
              },
              {
                label: "2023",
                value: "2023",
              },
              {
                label: "2024",
                value: "2024",
              },
            ]}
            onChange={(value) => {
              // onChange(value.value);
            }}
            placeholder="Selecione os anos de interesse"
            // error={errors.year}
          />
        </Box>

        <Box style={{ width: "100%", marginTop: 25 }}>
          <InputBox
            name="1"
            label="Tags"
            placeholder="tags; tags 2; "
            // {...register("tags")}
            // error={errors.tags}
          />
        </Box>

        <Box style={{ width: "100%", marginTop: 25 }}>
          <InputSelectBox
            width="100%"
            label="Tempo esperado de resposta (segundos)"
            name="time_in_seconds"
            options={[
              {
                label: "10 segundos",
                value: "10",
              },
              {
                label: "30 segundos",
                value: "30",
              },
              {
                label: "60 segundos",
                value: "60",
              },
              {
                label: "120 segundos",
                value: "120",
              },
            ]}
            onChange={(value) => {
              // onChange(value.value);
            }}
            placeholder="10 segundos / 30 segundos / 60 segundos / 120 segundos"
            // error={errors.time_in_seconds}
          />
        </Box>

        <Flex
          style={{
            marginTop: 25,
            marginBottom: 80,
            alignItems: "flex-end",
            justifyContent: "flex-end",
            width: "100%",
            flexDirection: "column",
          }}
        >
          <Button
            type="submit"
            bg="green.500"
            color="white"
            // isLoading={isSubmitting}
            style={{
              borderRadius: 53,
              height: 44,
              fontSize: 16,
              fontWeight: 600,
              width: 193,
              marginBottom: 50,
            }}
            _hover={{
              bg: "green.500",
            }}
            mt="6"
            size="lg"
            rightIcon={<Icon fontSize="20px" as={RiSaveLine} color="#fff" />}
          >
            Gerar questões
          </Button>
        </Flex>
      </Flex>
    </Flex>
  );
}
