import { switchAnatomy } from "@chakra-ui/anatomy";
import { createMultiStyleConfigHelpers, defineStyle } from "@chakra-ui/react";

const { definePartsStyle, defineMultiStyleConfig } =
  createMultiStyleConfigHelpers(switchAnatomy.keys);

const baseStyleTrack = defineStyle((props) => {
  const { colorScheme: c } = props;

  return {
    bg: "transparent",
    borderColor: "#ccc",
    borderWidth: 1,
    _checked: {
      bg: "transparent",
    },
    _dark: {
      bg: `${c}.900`,
      _checked: {
        bg: `${c}.900`,
      },
    },
  };
});

const baseStyle = definePartsStyle((props) => ({
  // define the part you're going to style
  container: {
    // ...
  },
  thumb: {
    bg: "#C3C3C3",
    // let's also provide dark mode alternatives
    _checked: {
      bg: "#26FFFF",
    },
    _dark: {
      bg: "gray.900",
    },
  },
  track: baseStyleTrack(props),
}));

const boxy = definePartsStyle({
  track: {
    borderRadius: "sm",
    p: 1,
    borderColor: "#ccc",
    borderWidth: 1,
  },
});

export const switchTheme = defineMultiStyleConfig({
  baseStyle,
  variants: { boxy },
});
