import {
  Flex,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalHeader,
  ModalOverlay,
  Text,
} from "@chakra-ui/react";
import Confetti from "react-confetti";

type Props = {
  reply: "success" | "error" | "no_reply";
  is_open: boolean;
  onClose: () => void;
};
export function MessageQuestion({ reply, is_open, onClose, ...rest }: Props) {
  return (
    <>
      <Modal
        motionPreset="slideInBottom"
        isOpen={is_open}
        onClose={() => {
          onClose();
        }}
        isCentered
        closeOnOverlayClick={false}
        {...rest}
        size="lg"
      >
        {reply === "success" && <Confetti numberOfPieces={400} />}
        <ModalOverlay />
        <ModalContent display="flex" flexDir="column">
          <ModalHeader
            textAlign="center"
            style={{
              color: reply === "success" ? "#03A679" : "rgb(255, 124, 124)",
              fontSize: 22,
              fontFamily: "Kanit",
              fontWeight: 600,
            }}
          >
            {reply === "success" ? "Correta" : "Errada!"}
          </ModalHeader>
          <ModalCloseButton />

          <ModalBody pb={10}>
            <Flex
              style={{
                alignItems: "center",
                justifyContent: "center",
                gap: 10,
              }}
              flexDirection={["column", "column", "column"]}
            >
              <Flex style={{ marginBottom: "30px" }}>
                <Text
                  style={{
                    fontSize: 16,
                    fontFamily: "Kanit",
                    fontWeight: 400,
                    marginLeft: 5,
                  }}
                >
                  {reply === "success"
                    ? "Sua resposta esta correta."
                    : "Sua resposta esta errada."}
                </Text>
              </Flex>
            </Flex>
          </ModalBody>
        </ModalContent>
      </Modal>
    </>
  );
}
