import { Button, Flex, useToast } from "@chakra-ui/react";

import { useNavigate, useParams } from "react-router-dom";
import { useState } from "react";
import { api } from "../../service/api";
import { AllLoading } from "../../components/allLoading";

export function DuplicationSimulator() {
  let { id } = useParams();
  const navigate = useNavigate();
  const toast = useToast();
  const [isLoading, setIsLoading] = useState(false);

  async function duplication() {
    try {
      setIsLoading(true);
      await api.post(`/simulator/duplication/${id}`, {});
      toast({
        position: "top-right",
        title: "Simulador",
        description: "Simulador criado com sucesso.",
        status: "success",
        duration: 9000,
        isClosable: true,
      });

      navigate("/simuladores");
    } catch (error) {
      toast({
        position: "top-right",
        title: "Simulador",
        description: "Não foi criar a simulador.",
        status: "error",
        duration: 9000,
        isClosable: true,
      });
    } finally {
      setIsLoading(false);
    }
  }

  return (
    <Flex>
      {isLoading && <AllLoading />}
      <Flex>
        <Button
          type="submit"
          bg="green.500"
          color="white"
          onClick={duplication}
          style={{
            borderRadius: 53,
            height: 44,
            fontSize: 16,
            fontWeight: 600,
            marginTop: 40,
            cursor: "pointer",
          }}
          _hover={{
            bg: "green.500",
          }}
          mt="6"
          size="lg"
          as="a"
        >
          Copiar simulado
        </Button>
      </Flex>
    </Flex>
  );
}
