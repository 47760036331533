import { Flex, Text } from "@chakra-ui/react";

type Props = {
  isSelected: boolean;
  isBlock?: boolean;
  type?: "error" | "success" | "default";
  title: string;
  description: string;
  onClick: () => void;
};

export function SelectMultipleChoice({
  title,
  type = "default",
  isSelected,
  description,
  onClick,
  isBlock,
}: Props) {
  return (
    <Flex
      style={{
        marginBottom: 10,
        cursor: !isBlock ? "pointer" : "not-allowed",
      }}
      onClick={!isBlock ? onClick : undefined}
    >
      <Flex
        style={{
          marginTop: 5,
          borderWidth: 1,
          borderColor:
            type === "error"
              ? "#FF0000"
              : type === "success"
              ? "#03A679"
              : "#0577BE",
          alignItems: "center",
          justifyContent: "center",
          backgroundColor:
            isSelected && type === "default" ? "#0577BE" : "#fff",
        }}
        width={["30px", "30px", "20px"]}
        height={["28px", "28px", "20px"]}
        borderRadius={["15px", "15px", "10px"]}
      >
        <Text
          style={{
            fontSize: 16,
            fontFamily: "Kanit",
            fontWeight: 300,
            color:
              type === "error"
                ? "#FF0000"
                : type === "success"
                ? "#03A679"
                : isSelected && type === "default"
                ? "#fff"
                : "#0577BE",
          }}
        >
          {title}
        </Text>
      </Flex>

      <Text
        style={{
          marginLeft: 8,
          color:
            type === "error"
              ? "#FF0000"
              : type === "success"
              ? "#03A679"
              : "#5F5F5F",
          fontSize: 16,
          fontFamily: "Kanit",
          fontWeight: 300,
        }}
        width={["100%", "100%", "1071px"]}
      >
        {description}
      </Text>
    </Flex>
  );
}
