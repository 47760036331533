import { extendTheme } from "@chakra-ui/react";
import { switchTheme } from "./switch";

export const themeChakra = extendTheme({
  colors: {
    green: {
      100: "#26FFFF",
      500: "#03A679",
    },
    blue: {
      500: "#0577BE",
    },
    gray: {
      50: "#F2F2F2",
      100: "#F7F7F7",
    },
  },
  fonts: {
    heading: "Poppins",
    body: "Poppins",
  },
  styles: {
    global: {
      body: {
        bg: "gray.100",
      },
    },
  },

  components: {
    Switch: switchTheme,
  },
});
